import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

// ----------------------------------------------------------------------

export default function Input({ palette, spacing, typography }: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        adornedStart: {
          '& > svg': {
            fontSize: 18,
            marginRight: 6,
          },
        },
        adornedEnd: {
          '& > svg': {
            fontSize: 18,
            marginLeft: 6,
          },
        },
        input: {
          padding: spacing(1, 0),
        },
        inputSizeSmall: {
          '&.MuiOutlinedInput-input': {
            padding: spacing(1, 2.5),
            height: `calc(48px - ${spacing(2)})`,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          '& svg': {
            fontSize: spacing(2.5),
          },
        },
        positionEnd: {
          marginLeft: 8,
          marginRight: 2,
        },
        positionStart: {
          marginLeft: 2,
          marginRight: 8,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontWeight: typography.fontWeightRegular,
          fontSize: typography.body2.fontSize,
        },
        formControl: {
          'label + &': {
            marginTop: '1rem',
          },
        },
      },
    },
    MuiFilledInput: {
      /* styleOverrides: {
        root: {
          backgroundColor: palette.grey[500_12],
          '&:hover': {
            backgroundColor: palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: palette.grey[500_56],
          },
        },
      }, */
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          borderColor: palette.grey[300],
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey[300],
            '& legend': {
              width: 0,
            },
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey[400],
          },
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.grey[300],
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.main,
              borderWidth: 1,
              boxShadow: `0 0 0 2px ${alpha(palette.primary.main, palette.tonalOffset as number)}`,
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(palette.divider, 0.7),
            },
          },
          '&.MuiInputBase-adornedStart': {
            paddingLeft: spacing(2.5),
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: spacing(2.5),
          },
        },
        input: {
          padding: spacing(1.5, 2.5),
          height: `calc(48px - ${spacing(3)})`,
          fontSize: typography.body2.fontSize,
        },
        notchedOutline: {
          padding: '0 14px',
        },
        inputMultiline: {
          padding: '0 6px',
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
        inputAdornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transformOrigin: 'top left',
        },
        formControl: {
          transform: `translate(0, 24px) scale(1)`,
        },
        marginDense: {
          transform: 'translate(0, 21px) scale(1)',
        },
        filled: {
          '&.MuiInputLabel-shrink': {
            transform: `translate(${spacing(1.2)}, 3px) scale(0.9)`,
            transformOrigin: 'top left',
          },
        },
        outlined: {
          fontSize: typography.body2.fontSize,
          transform: `translate(${spacing(2.5)}, 14px)`,
          '&$marginDense': {
            transform: `translate(${spacing(2.5)}, 14px)`,
          },
          '&.MuiInputLabel-shrink': {
            transform: `translate(${spacing(2.5)}, -20px) scale(0.85)`,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
        InputProps: {
          rows: 4,
        },
        InputLabelProps: {
          /* disableAnimation: true,
          shrink: true, */
        },
      },

      styleOverrides: {
        root: {
          margin: `${spacing(1.5, 0)} !important`,
        },
      },
    },
  };
}
