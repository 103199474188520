import { alpha } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { spacing, direction, palette } = theme;
  return {
    '&': {
      display: 'block',
      position: 'relative',
      textAlign: direction === 'rtl' ? 'right' : 'left',
      padding: spacing(1),
      margin: spacing(0, -1, 4, -1),
      width: `calc(100% + ${spacing(2)})`,
      borderRadius: spacing(1),
      color: alpha(palette.primary.main, (palette.tonalOffset as number) * 2),
      '&:hover, &:focus, &.Mui-focusVisible': {
        background: palette.action.hover,
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: spacing(-2),
        left: spacing(1),
        right: spacing(1),
        height: 1,
        backgroundColor: palette.divider,
      },
      '&:first-of-type': {
        marginTop: spacing(-1),
      },
      '&:last-child': {
        marginBottom: spacing(-1),
        '&:after': {
          display: 'none',
        },
      },
      '&:hover, &:focus': {
        [`& .${classes.title}`]: {
          color: palette.primary.main,
        },
      },
    },
  };
};

export const stylesContent = (theme: Theme, classes: any) => {
  const { spacing, transitions, palette, typography } = theme;
  return {
    '&': {
      marginTop: 0,
      marginBottom: 0,

      [`& .${classes.authorImage}`]: {
        width: 20,
        height: 20,

        '& > *:first-of-type': {
          width: '20px !important',
          height: '20px !important',
        },
      },
      [`& .${classes.title}`]: {
        color: palette.text.primary,
        fontWeight: typography.fontWeightMedium,
        transition: transitions.create(['color'], {
          duration: transitions.duration.shorter,
        }),
      },
      [`& .${classes.footer}`]: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingTop: spacing(1.5),
        '& > *:first-of-type': {
          flex: 1,
          paddingRight: spacing(0.5),
        },
      },
    },
  };
};

export const stylesDate = (theme: Theme) => {
  const { typography } = theme;
  return {
    '&': {
      fontFamily: typography.h1.fontFamily,
    },
  };
};
