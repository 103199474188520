import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ControlLabel({ spacing, typography }: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
        label: {
          lineHeight: 1.3,
          fontWeight: typography.fontWeightMedium,
          fontSize: typography.body2.fontSize,
        },
        labelPlacementStart: {
          marginLeft: 12,
          marginRight: 0,
          '& .MuiSwitch-root': {
            marginLeft: spacing(1),
          },
        },
        labelPlacementEnd: {
          marginLeft: 0,
          marginRight: 12,
          '& .MuiRadio-root, & .MuiCheckbox-root': {
            marginLeft: -6,
          },
          '& .MuiSwitch-root': {
            marginRight: spacing(1),
          },
        },
        labelPlacementTop: {
          marginLeft: 0,
          marginRight: 0,
        },
        labelPlacementBottom: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: spacing(0.25),
        },
        contained: {
          marginLeft: spacing(2.5),
        },
      },
    },
    MuiFormLabel: {},
  };
}
