import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Box, Container, Divider, Typography, Grid } from '@mui/material';
// components
import { ContentBox, Hero } from 'components';
// types
import { SxProps } from '@mui/system';
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
import { ContentBoxProps } from 'components/ContentBox/ContentBox';
import { ImageType } from 'types/image';
//
import {
  stylesColumnsWrapper,
  stylesTextColumn,
  stylesImageColumn,
  stylesItemsSpace,
  stylesImage,
  stylesImageAddon,
} from './styles';

export type ExtendedGridAlignContent =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around';
export type ExtendedGridAlignItems = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
export type ExtendedGridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type ExtendedGridJustifyContent =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export interface TextAndItemSectionProps {
  className?: string;
  classesChildren?: any;
  children?: ReactNode;
  additionalChildren?: ReactNode;
  background?: 'default' | 'paper' | 'primary' | 'secondary';
  sx?: SxProps<Theme>;
  revertColumns?: boolean;
  alignItems?: ExtendedGridAlignItems;
  alignContent?: ExtendedGridAlignContent;
  justifyContent?: ExtendedGridJustifyContent;
  header?: BoxWithHeaderProps['header'];
  headerProps?: BoxWithHeaderProps['headerProps'];
  subheader?: BoxWithHeaderProps['subheader'];
  subheaderProps?: BoxWithHeaderProps['subheaderProps'];
  invertHeader?: BoxWithHeaderProps['invertHeader'];
  itemsSpace?: BoxWithHeaderProps['spaceHeader'];
  spaceSize: ContentBoxProps['spaceSize'];
  spaceTop: ContentBoxProps['spaceTop'];
  spaceBottom: ContentBoxProps['spaceBottom'];
  image?: ImageType;
  imageAddon?: ReactNode;
  divider?: boolean;
}

const PREFIX = 'TextAndItemSection';
const classes = {
  column: `${PREFIX}-column`,
  itemSpace: `${PREFIX}-itemSpace`,
  subheader: `${PREFIX}-subheader`,
};

const StyledColumnsWrapper = styled(Grid, { name: PREFIX, slot: 'columnsWrapper' })(({ theme }) =>
  stylesColumnsWrapper(theme, classes),
);
const StyledTextColumn = styled(Grid, { name: PREFIX, slot: 'textColumn' })(({ theme }) =>
  stylesTextColumn(theme),
);
const StyledImageColumn = styled(Grid, { name: PREFIX, slot: 'textColumn' })(({ theme }) =>
  stylesImageColumn(theme),
);
const StyledImage = styled('div', { name: PREFIX, slot: 'image' })(({ theme }) =>
  stylesImage(theme),
);
const StyledImageAddon = styled(Box, { name: PREFIX, slot: 'imageAddon' })(({ theme }) =>
  stylesImageAddon(theme),
);

const StyledItemsSpace = styled(Box)(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      itemsSpace: TextAndItemSectionProps['itemsSpace'];
    };
  }) => stylesItemsSpace(theme, ownerState),
);

const TextAndItemSection = (props: TextAndItemSectionProps) => {
  const {
    className: classNameProp,
    classesChildren,
    children,
    additionalChildren,
    sx,
    revertColumns,
    alignItems,
    alignContent,
    justifyContent,
    header,
    headerProps,
    subheader,
    subheaderProps,
    invertHeader,
    itemsSpace,
    image,
    imageAddon,
    divider,
    spaceSize,
    spaceTop,
    spaceBottom,
  } = props;

  const theme = useTheme();

  const renderColumn = (child: ReactNode, type?: string): ReactNode => {
    if (type === 'text') {
      return (
        <StyledTextColumn item xs={12} md={6} className={classes.column}>
          <StyledItemsSpace theme={theme} ownerState={{ itemsSpace }} className={classes.itemSpace}>
            {child}
          </StyledItemsSpace>
        </StyledTextColumn>
      );
    }
    return (
      <StyledImageColumn item xs={12} md={6} className={classes.column}>
        <StyledItemsSpace theme={theme} ownerState={{ itemsSpace }} className={classes.itemSpace}>
          {child}
        </StyledItemsSpace>
      </StyledImageColumn>
    );
  };

  const renderTextColumn = (): ReactNode =>
    renderColumn(
      <>
        {!invertHeader ? (
          <>
            {header && <Typography {...headerProps}>{header}</Typography>}
            {subheader && <Typography {...subheaderProps}>{subheader}</Typography>}
          </>
        ) : (
          <>
            {subheader && (
              <Typography {...subheaderProps} className={classes.subheader}>
                {subheader}
              </Typography>
            )}
            {header && <Typography {...headerProps}>{header}</Typography>}
          </>
        )}

        {children}
      </>,
      'text',
    );

  const renderImageColumn = (): ReactNode =>
    renderColumn(
      <>
        {image && (
          <StyledImage>
            <Hero
              heroImg={image.src.publicURL}
              imageName={image.src.name}
              alt={image.alt}
              title={image?.title}
            />
            {imageAddon && <StyledImageAddon>{imageAddon}</StyledImageAddon>}
          </StyledImage>
        )}
        {additionalChildren}
      </>,
      'image',
    );

  return (
    <>
      <ContentBox
        sx={sx}
        spaceSize={spaceSize}
        spaceTop={spaceTop}
        spaceBottom={spaceBottom}
        classesChildren={{
          root: clsx(classNameProp, classesChildren?.root),
          content: clsx(classesChildren?.content),
        }}
      >
        <StyledColumnsWrapper
          container
          alignItems={alignItems}
          alignContent={alignContent}
          justifyContent={justifyContent}
          direction="row"
        >
          {!revertColumns ? (
            <>
              {renderTextColumn()}
              {renderImageColumn()}
            </>
          ) : (
            <>
              {renderImageColumn()}
              {renderTextColumn()}
            </>
          )}
        </StyledColumnsWrapper>
      </ContentBox>

      {divider && (
        <Container>
          <Divider />
        </Container>
      )}
    </>
  );
};

TextAndItemSection.defaultProps = {
  revertColumns: false,
  alignItems: 'center',
  headerProps: {
    color: 'textPrimary',
    variant: 'h2',
    component: 'h2',
  },
  subheaderProps: {
    color: 'primary',
    variant: 'subtitle2',
    component: 'h3',
  },
  invertHeader: false,
  itemsSpace: 'medium',
  divider: false,
  spaceSize: 'large',
} as Partial<TextAndItemSectionProps>;

export default TextAndItemSection;
