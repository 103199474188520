import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, spacing } = theme;
  return {
    '&': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minHeight: '66vh',
      [breakpoints.up('md')]: {
        minHeight: '100vh',
      },

      [`& .${classes.content}`]: {
        display: 'flex',
        flex: 1,
        paddingTop: `${spacing(3)} !important`,
      },
    },
  };
};
