import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Box, unstable_composeClasses as composeClasses, useThemeProps } from '@mui/material';
import { SxProps } from '@mui/system';
// utils
import { componentName, getItemUtilityClass, LabelClasses } from './labelClasses';
//
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------

type LabelColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
type LabelVariant = 'filled' | 'outlined' | 'ghost';

export interface LabelProps {
  /**
   * Content of the component.
   */
  children?: ReactNode;
  /**
   * Override or extend the styles applied to the component.
   *
   * Possible values: `root` `defaultColor` `primaryColor` `secondaryColor` `infoColor` `successColor` `warningColor` `errorColor` `filledVariant` `outlinedVariant` `ghostVariant`
   */
  classes?: Partial<LabelClasses>;
  /**
   * Color of the component.
   * @default default
   */
  color?: LabelColor;
  /**
   * Class added to main element of component.
   */
  className?: string;
  /**
   * The variant to use.
   * @default ghost
   */
  variant?: LabelVariant;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   *
   * See the `sx` MUI's documentation for more details.
   */
  sx?: SxProps<Theme>;
}

const useUtilityClasses = (ownerState: Partial<LabelProps>) => {
  const { classes, color, variant } = ownerState;
  const slots = {
    root: ['root', color && `${color}Color`, variant && `${variant}Variant`],
  };
  return composeClasses(slots, getItemUtilityClass, classes);
};

const StyledRoot = styled(Box, {
  name: componentName,
  slot: 'root',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    const { color, variant } = ownerState;
    return [styles.root, color && styles[`${color}Color`], variant && styles[`${variant}Variant`]];
  },
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<LabelProps> }) =>
  stylesRoot(theme, ownerState),
);

const Label = forwardRef<HTMLDivElement, LabelProps>((inProps, ref) => {
  const props = useThemeProps({
    props: inProps,
    name: componentName,
  });
  const {
    className: classNameProp,
    color = 'default',
    variant = 'ghost',
    children,
    sx,
    ...otherProps
  } = props;
  const theme = useTheme();

  const ownerState: Partial<LabelProps> = {
    color,
    variant,
  };

  const componentClasses = useUtilityClasses(ownerState);

  return (
    <StyledRoot
      ref={ref}
      theme={theme}
      ownerState={ownerState}
      component="span"
      sx={sx}
      className={clsx(componentClasses.root, classNameProp)}
      {...otherProps}
    >
      {children}
    </StyledRoot>
  );
});

export default Label;
