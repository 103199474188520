import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, custom, customShadows, palette, transitions } = theme;
  return {
    '&': {
      [`& .${classes.toolbar}`]: {
        transition: transitions.create(['height', 'min-height', 'background-color'], {
          duration: transitions.duration.shorter,
        }),
      },
      [`& .${classes.smallToolbar}`]: {
        background: palette.background.paper,
        [breakpoints.up('md')]: {
          minHeight: (custom.headerNavHeight as number) - 16,
        },
      },
      [`& .${classes.links}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      [`& .${classes.mobileLinks}`]: {
        [breakpoints.up('md')]: {
          display: 'none',
        },
      },
      [`& .${classes.desktopLinks}`]: {
        display: 'none',
        [breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      [`& .${classes.toolbarShadow}`]: {
        left: 0,
        right: 0,
        bottom: -12,
        height: 24,
        zIndex: -1,
        margin: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: customShadows.z8,
        opacity: 0,
        transition: transitions.create(['bottom', 'opacity'], {
          duration: transitions.duration.shorter,
        }),
      },
      [`& .${classes.toolbarShadowVisible}`]: {
        opacity: 1,
        bottom: 0,
      },
    },
  };
};
