import React, { ReactNode } from 'react';
import { Twitter as TwitterIcon } from '@mui/icons-material';
import {
  FacebookLight as FacebookLightIcon,
  LinkedinLight as LinkedinLightIcon,
} from 'components/CustomIcon';

export type SocialLink = {
  label: NonNullable<ReactNode>;
  url: string;
  icon: ReactNode;
};

const SocialLinks: SocialLink[] = [
  {
    label: 'Twitter',
    url: 'https://twitter.com/NorionEcosystem',
    icon: <TwitterIcon />,
  },
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/NorionEcosystem',
    icon: <FacebookLightIcon />,
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/norion',
    icon: <LinkedinLightIcon />,
  },
];

export default SocialLinks;
