import { CSSProperties } from 'react';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      headerMobileNavHeight: CSSProperties['height'];
      headerNavHeight: CSSProperties['height'];
      fullModalMenuWidth: CSSProperties['width'];
    };
  }
  interface ThemeOptions {
    custom?: {
      headerMobileNavHeight?: CSSProperties['height'];
      headerNavHeight?: CSSProperties['height'];
      fullModalMenuWidth?: CSSProperties['width'];
    };
  }
}

const custom = {
  headerMobileNavHeight: 72,
  headerNavHeight: 96,
  fullModalMenuWidth: 350,
};

export default custom;
