// material
import { createTheme, Theme } from '@mui/material/styles';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows, { customShadows } from './shadows';
import spacing from './spacing';
import custom from './custom';
import { mixins } from './mixins';
import componentsOverride from './overrides';

// ----------------------------------------------------------------------

const ThemeConfig = (): Theme => {
  const theme = createTheme({
    direction: 'ltr',
    palette: { ...palette, mode: 'light' },
    shape,
    typography,
    breakpoints,
    shadows,
    spacing,
    customShadows,
    custom,
  });
  theme.mixins = mixins(theme);
  theme.components = componentsOverride(theme);

  return theme;
};

export default ThemeConfig;
