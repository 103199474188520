// ----------------------------------------------------------------------

declare module '@mui/system' {
  interface Shape {
    borderRadiusSm: number | string;
    borderRadiusMd: number | string;
    borderRadiusLg: number | string;
    borderRadiusXl: number | string;
  }
}

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiusLg: 24,
  borderRadiusXl: 32,
};

export default shape;
