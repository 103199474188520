import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        minHeight: '52px',

        [breakpoints.down('md')]: {
          minHeight: '34px',
        },
      },
    },
  };
};
