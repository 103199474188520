import * as React from 'react';
import clsx from 'clsx';
// gatsby
import { Link } from 'gatsby';
// material
import { styled, Theme } from '@mui/material/styles';
import { Button, useMediaQuery } from '@mui/material';
// types
import { ExtendedMainMenuItem } from 'types/main-menu-item';
//
import { stylesRoot } from './styles';

export interface HeaderDesktopMenuItemAllProps extends ExtendedMainMenuItem {
  className?: string;
  classes: { endIcon: string };
}
type ExtraProps = {
  component: React.ElementType;
  to?: string;
};

const PREFIX = 'HeaderDesktopMenuItem';
const classes = {
  text: `${PREFIX}-text`,
  textActive: `${PREFIX}-textActive`,
  featured: `${PREFIX}-featured`,
  startIcon: `${PREFIX}-startIcon`,
  endIcon: `${PREFIX}-endIcon`,
};

const StyledRoot = styled(Button, { name: PREFIX, slot: 'root' })<ExtraProps>(({ theme }) =>
  stylesRoot(theme, classes),
);

const HeaderDesktopMenuItem = (props: HeaderDesktopMenuItemAllProps) => {
  const {
    className: classNameProp,
    label,
    isLink,
    to,
    onClick,
    variant,
    active,
    startIcon,
    endIcon,
  } = props;
  const smallScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('lg'));

  return (
    <StyledRoot
      component={isLink ? Link : 'button'}
      to={isLink ? to : undefined}
      onClick={onClick}
      size={smallScreen ? 'small' : 'medium'}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      classes={{
        root: clsx(classNameProp),
        text: clsx(classes.text, active && classes.textActive),
        contained: classes.featured,
        outlined: classes.featured,
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
      }}
    >
      {label}
    </StyledRoot>
  );
};

HeaderDesktopMenuItem.defaultProps = {
  variant: 'text',
  isLink: true,
  active: false,
} as Partial<HeaderDesktopMenuItemAllProps>;

export default HeaderDesktopMenuItem;
