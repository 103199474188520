import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export const componentName = `IceoLogo`;

export interface LogoClasses {
  root: string;
  defaultSize: string;
  smallSize: string;
  mediumSize: string;
  largeSize: string;
  centered: string;
}

export type LogoClassKey = keyof LogoClasses;

export function getItemUtilityClass(slot: string): string {
  return generateUtilityClass(componentName, slot);
}

const logoClasses: LogoClasses = generateUtilityClasses(componentName, [
  'root',
  'defaultSize',
  'smallSize',
  'mediumSize',
  'largeSize',
  'centered',
]);

export default logoClasses;
