import { Theme } from '@mui/material/styles';
import { typographyClasses } from '@mui/material';
import { TextAndItemSectionProps } from './TextAndItemSection';

export const stylesColumnsWrapper = (theme: Theme, classes: any) => {
  const { spacing, breakpoints } = theme;
  return {
    [`& > .${classes.column}`]: {},
    [`& > .${classes.column}`]: {
      [breakpoints.up('md')]: {
        [`& .${classes.itemSpace} > *:last-child`]: {
          marginBottom: 0,
        },
      },
      [breakpoints.down('md')]: {
        [`&:last-child .${classes.itemSpace} > *:last-child`]: {
          marginBottom: 0,
        },
      },
    },
    [`& > .${classes.column} > .${classes.itemSpace} > .${classes.subheader}:first-of-type`]: {
      marginBottom: spacing(1),
    },
  };
};

export const stylesTextColumn = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      [breakpoints.up('md')]: {
        '&:first-of-type': {
          paddingRight: spacing(5.25),
        },
        '&:last-child': {
          paddingLeft: spacing(5.25),
        },
      },
      [breakpoints.up('lg')]: {
        '&:first-of-type': {
          paddingRight: spacing(10.75),
        },
        '&:last-child': {
          paddingLeft: spacing(10.75),
        },
      },
    },
  };
};

export const stylesImageColumn = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      [breakpoints.up('md')]: {
        '&:first-of-type': {
          paddingRight: spacing(0.75),
        },
        '&:last-child': {
          paddingLeft: spacing(0.75),
        },
      },
      [breakpoints.up('lg')]: {
        '&:first-of-type': {
          paddingRight: spacing(1.25),
        },
        '&:last-child': {
          paddingLeft: spacing(1.25),
        },
      },
    },
  };
};

export const stylesItemsSpace = (
  theme: Theme,
  ownerState: {
    itemsSpace: TextAndItemSectionProps['itemsSpace'];
  },
) => {
  const { spacing, breakpoints } = theme;
  const { itemsSpace } = ownerState;

  return {
    '&': {
      [`& > *, & > .${typographyClasses.root}`]: {
        marginBottom: spacing(2),
        ...(itemsSpace === 'small' && {
          [breakpoints.up('sm')]: {
            marginBottom: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(3),
          },
        }),
        ...(itemsSpace === 'medium' && {
          marginBottom: spacing(3),
          [breakpoints.up('sm')]: {
            marginBottom: spacing(3.5),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(4),
          },
        }),
        ...(itemsSpace === 'large' && {
          marginBottom: spacing(4),
          [breakpoints.up('sm')]: {
            marginBottom: spacing(5.5),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(7),
          },
        }),
      },
    },
  };
};

export const stylesImage = (theme: Theme) => {
  const { shape, breakpoints } = theme;
  return {
    '&': {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: shape.borderRadiusMd,
      [breakpoints.up('lg')]: {
        borderRadius: shape.borderRadiusLg,
      },
      '& img': {
        display: 'block',
      },
    },
  };
};
export const stylesImageAddon = (theme: Theme) => {
  const { spacing, palette, breakpoints } = theme;
  return {
    '&': {
      background: palette.background.paper,
      borderRadius: 'inherit',
      padding: spacing(2.5),
      position: 'absolute',
      bottom: spacing(2),
      left: spacing(2),
      width: `calc(100% - ${spacing(4)})`,
      [breakpoints.up('sm')]: {
        padding: spacing(3),
        bottom: spacing(3),
        left: spacing(3),
        width: 'auto',
        minWidth: `calc(66% - ${spacing(3)})`,
        maxWidth: `calc(100% - ${spacing(6)})`,
      },
    },
  };
};
