import { Theme } from '@mui/material/styles';

export const stylesHeader = (classes: any) => ({
  '&': {
    [`&.${classes.headerActionUnder}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& > *': {
        width: '100%',
      },
    },
  },
});

export const stylesFooter = (theme: Theme, classes: any) => {
  const { spacing } = theme;
  return {
    '&': {
      [`&.${classes.footer}`]: {
        paddingTop: 0,
        '&:before': {
          display: 'none',
        },
      },
      [`&.${classes.footerUnder}`]: {
        flexDirection: 'column',
        '& > *:not(:last-child)': {
          marginBottom: spacing(2),
        },
      },
    },
  };
};
