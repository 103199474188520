import React from 'react';
import clsx from 'clsx';
// material
import { styled, Theme } from '@mui/material/styles';
import { Box, ButtonBase, Typography } from '@mui/material';
import { AccessTimeFilledRounded as ReadingTimeIcon } from '@mui/icons-material';
// gatsby
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// components
import { AvatarWithText } from 'components';
// types
import { GatsbyLinkProps } from 'gatsby-link';
import { SxProps } from '@mui/system';
import { ChildImageSharpType } from 'types/image';
//
import {
  stylesRoot,
  styleRootInner,
  stylesImage,
  stylesTitle,
  stylesFooter,
  stylesTime,
} from './styles';

// ----------------------------------------------------------------------

export interface BlogPreviewCardProps {
  className?: string[];
  sx?: SxProps<Theme>;
  image?: ChildImageSharpType;
  title?: string;
  author?: {
    name?: string;
    image?: ChildImageSharpType;
  };
  readingTime?: string;
  date?: string;
  to: GatsbyLinkProps<any>['to'];
}

const PREFIX = 'BlogPreviewCard';
type ExtraProps = {
  component: React.ElementType;
  to: string;
};

const StyledRoot = styled(ButtonBase, { name: PREFIX, slot: 'root' })<ExtraProps>(({ theme }) =>
  stylesRoot(theme),
);
const StyledRootInner = styled(Box, { name: PREFIX, slot: 'rootInner' })(() => styleRootInner());
const StyledImage = styled(GatsbyImage, { name: PREFIX, slot: 'image' })(({ theme }) =>
  stylesImage(theme),
);
const StyledTitle = styled(Box, { name: PREFIX, slot: 'title' })(({ theme }) => stylesTitle(theme));
const StyledFooter = styled(Box, { name: PREFIX, slot: 'footer' })(({ theme }) =>
  stylesFooter(theme),
);
const StyledTime = styled(Box, { name: PREFIX, slot: 'time' })(({ theme }) => stylesTime(theme));

const BlogPreviewCard = (props: BlogPreviewCardProps) => {
  const { className: classNameProp, sx, image, title, author, readingTime, date, to } = props;

  const data = useStaticQuery(graphql`
    query {
      blogImage: file(relativePath: { eq: "empty-image.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 212)
        }
      }
    }
  `);

  return (
    <StyledRoot className={clsx(classNameProp)} sx={sx} focusRipple component={GatsbyLink} to={to}>
      <StyledRootInner>
        {image ? (
          <StyledImage
            image={image?.src.childImageSharp.gatsbyImageData}
            alt={image.alt || title}
            title={image?.title || title}
          />
        ) : (
          <StyledImage image={data.blogImage.childImageSharp.gatsbyImageData} alt={title} />
        )}
        {title && (
          <StyledTitle>
            <Typography variant="h6" component="h2">
              {title}
            </Typography>
          </StyledTitle>
        )}

        <StyledFooter>
          <Box>
            {author ? (
              <AvatarWithText title={author?.name} subtitle={date} image={author?.image} />
            ) : (
              <>
                {date && (
                  <Typography variant="caption" color="textSecondary">
                    {date}
                  </Typography>
                )}
              </>
            )}
          </Box>

          {readingTime && (
            <StyledTime>
              <ReadingTimeIcon />
              <Typography variant="caption" color="textSecondary">
                {readingTime}
              </Typography>
            </StyledTime>
          )}
        </StyledFooter>
      </StyledRootInner>
    </StyledRoot>
  );
};

BlogPreviewCard.defaultProps = {} as Partial<BlogPreviewCardProps>;

export default BlogPreviewCard;
