import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';
import { CapabilityItemProps } from './CapabilityItem';

export const stylesRoot = (
  theme: Theme,
  ownerState: {
    layout: CapabilityItemProps['layout'];
  },
  classes: any,
) => {
  const { spacing, palette, shape, direction, transitions } = theme;
  const { layout } = ownerState;
  return {
    '&': {
      [`&.${classes.root}`]: {
        display: 'flex',
        backgroundColor: palette.background.paper,
        borderRadius: shape.borderRadiusMd,
        textAlign: direction === 'rtl' ? 'right' : 'left',
        padding: spacing(4),

        ...(layout === 'horizontal' && {
          flexDirection: 'row',
          alignItems: 'center',
          [`& .${classes.icon}`]: {
            marginRight: spacing(3),
          },
        }),
        ...(layout === 'vertical' && {
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          minHeight: 212,
        }),
      },
      [`&.${classes.hover}`]: {
        transition: transitions.create(['box-shadow'], {
          duration: transitions.duration.short,
        }),
        '&:hover, &:focus': {
          boxShadow: `0 0 0 2px ${alpha(palette.primary.main, palette.tonalOffset as number)}`,
        },
      },
      [`&.${classes.featured}`]: {
        boxShadow: `0 0 0 2px ${alpha(palette.primary.main, palette.tonalOffset as number)}`,
        [`&.${classes.hover}`]: {
          '&:hover, &:focus': {
            boxShadow: `0 0 0 2px ${alpha(palette.primary.darker, palette.tonalOffset as number)}`,
          },
        },
      },
    },
  };
};

export const stylesIcon = (
  theme: Theme,
  ownerState: {
    iconSize: CapabilityItemProps['iconSize'];
  },
) => {
  const { spacing, palette, shape } = theme;
  const { iconSize } = ownerState;
  return {
    '&': {
      display: 'inline-flex',
      backgroundColor: palette.background.primary,
      color: palette.primary.light,
      '& svg': {
        color: palette.primary.light,
      },
      ...(iconSize === 'small' && {
        padding: spacing(1.5),
        borderRadius: shape.borderRadius,
        '& svg': {
          fontSize: 22,
        },
      }),
      ...(iconSize === 'medium' && {
        padding: spacing(2),
        borderRadius: shape.borderRadiusSm,
        '& svg': {
          fontSize: 24,
        },
      }),
      ...(iconSize === 'large' && {
        padding: spacing(2),
        borderRadius: shape.borderRadiusSm,
        '& svg': {
          fontSize: 32,
        },
      }),
    },
  };
};

export const stylesTitle = (theme: Theme) => {
  const { palette } = theme;
  return {
    '&': {
      color: palette.text.primary,
    },
  };
};
