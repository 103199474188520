import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

// ----------------------------------------------------------------------

export default function CssBaseline({ palette, typography }: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          margin: 0,
          padding: 0,
          border: 'none',
          fontSize: typography.htmlFontSize,
        },
        body: {
          WebkitFontSmoothing: 'auto',
          fontFamily: typography.fontFamily,
          color: palette.text.primary,
          backgroundColor: palette.background.paper,
          overflowX: 'hidden',
        },
        '#___gatsby': {
          overflow: 'hidden',
        },
        a: {
          textDecoration: 'none',
          color: palette.primary.main,
        },
        '::-moz-selection': {
          background: palette.primary.main,
          color: palette.primary.contrastText,
        },
        '::selection': {
          background: palette.primary.main,
          color: palette.primary.contrastText,
        },
        '::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },
        '::-webkit-scrollbar-thumb': {
          border: 'none',
          background: palette.primary.main,
          borderRadius: 4,
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: palette.primary.dark,
        },
        '::-webkit-scrollbar-track': {
          background: 'transparent',
          borderRadius: 0,
        },
        '*': {
          scrollbarColor: `${palette.primary.main} ${alpha(palette.grey[500], 0.1)}`,
          scrollbarWidth: 'thin',
        },
        'strong, b': {
          fontWeight: typography.fontWeightBold,
        },
      },
    },
  };
}
