import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
//
import { stylesRoot } from './styles';

type Gradient = 'primary' | 'secondary' | 'button' | 'buttonSecondary' | 'text' | 'textSecondary';

interface TextGradientProps {
  children?: string | ReactNode;
  strokeWidth?: number;
  gradientText?: Gradient;
  gradientLine: Gradient;
}

interface TextGradientAllProps extends TextGradientProps {
  className?: string;
}

const PREFIX = 'TextGradient';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('span', { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const TextGradient = (props: TextGradientAllProps) => {
  const { className: classNameProp, children, strokeWidth, gradientLine, gradientText } = props;
  const theme = useTheme();
  const gradientFillArray = () =>
    theme.palette.gradients[gradientLine]
      .replace('linear-gradient(to right, ', '')
      .replace(')', '')
      .split(', ');
  const gradientLineFill = gradientFillArray();

  if (!children) {
    return null;
  }
  return (
    <StyledRoot
      className={clsx(classes.root, classNameProp)}
      style={{
        background: gradientText
          ? theme.palette.gradients[gradientText]
          : theme.palette.gradients.text,
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
      }}
    >
      {children}
      {strokeWidth !== undefined && strokeWidth > 0 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.5 5.5" preserveAspectRatio="none">
          <path
            d="m 1.4668576,4.0978239 c 0,0 18.1672704,-2.951303 59.2980314,-2.6810674 20.794403,0.1366225 30.268349,1.9887073 30.268349,1.9887073"
            style={{
              fill: 'none',
              stroke: 'url(#paint0_linear)',
              strokeWidth,
              strokeLinecap: 'round',
            }}
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1={gradientLineFill[0].split(' ')[1]}
              x2={gradientLineFill[2].split(' ')[1]}
            >
              <stop offset="0%" stopColor={gradientLineFill[0].split(' ')[0]} />
              <stop offset="50%" stopColor={gradientLineFill[1].split(' ')[0]} />
              <stop offset="100%" stopColor={gradientLineFill[2].split(' ')[0]} />
            </linearGradient>
          </defs>
        </svg>
      )}
    </StyledRoot>
  );
};

TextGradient.defaultProps = {
  strokeWidth: 2.4,
  gradientLine: 'button',
  gradientText: 'text',
} as Partial<TextGradientAllProps>;

export default TextGradient;
