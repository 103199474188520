import { useStaticQuery, graphql } from 'gatsby';
import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
// hooks
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

const useSiteImages = () => {
  const { currentLang } = useLocales();
  const smallScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('md'));
  const result = useStaticQuery(graphql`
    query siteImages {
      allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(formats: [AUTO, PNG, WEBP], quality: 100)
            }
            name
          }
        }
      }
    }
  `);

  const handleFindImage = (newName: string, imageName?: string) => {
    const items = result.allFile.edges;
    let image = items.find((edge: any) => edge.node.name === newName);
    if (image === undefined && imageName) {
      image = items.find((edge: any) => edge.node.name === imageName);
    }
    if (image === undefined) {
      return undefined;
    }
    return image.node.childImageSharp;
  };

  const handleGetImage = (imageName: string) => {
    const newName = checkDynamicalyImage(imageName);

    const image = handleFindImage(newName, imageName);
    if (image === undefined) {
      return handleFindImage('empty-image');
    }
    return image;
  };

  const checkDynamicalyImage = (imageName: string) => {
    let newName = imageName;
    const device = smallScreen ? 'mobile' : 'desktop';

    if (handleFindImage(`${newName}-${currentLang.value}-${device}`)) {
      newName = `${newName}-${currentLang.value}-${device}`;
    } else if (handleFindImage(`${newName}-${currentLang.value}`)) {
      newName = `${newName}-${currentLang.value}`;
    } else if (handleFindImage(`${newName}-${device}`)) {
      newName = `${newName}-${device}`;
    } else {
      newName = imageName;
    }
    return newName;
  };

  return {
    allFile: result.allFile.edges,
    findImage: handleGetImage,
  };
};

export default useSiteImages;
