export const NativeFormsIdsTypeArray = [
  'contact',
  'for-investors',
  'newsletter-blog',
  'newsletter-investor',
] as const;

export type NativeFormsIdsType = typeof NativeFormsIdsTypeArray[number];

export type NativeFormsLangUrlsType = {
  pl: string;
  en: string;
};

export type NativeFormsPropertiesType = {
  [index in NativeFormsIdsType]: NativeFormsLangUrlsType;
};

export const NativeFormsUrls: NativeFormsPropertiesType = {
  contact: {
    pl: 'https://form.nativeforms.com/BFndv1jZmADR3Q3Qx1Db',
    en: 'https://form.nativeforms.com/TRGRa1jZmADR3Q3Qx1Db',
  },
  'for-investors': {
    pl: 'https://form.nativeforms.com/IXS4hkMz0jZmADR3Q3Qx1Db',
    en: 'https://form.nativeforms.com/ga2c0Sa1jZmADR3Q3Qx1Db',
  },
  'newsletter-blog': {
    pl: 'https://form.nativeforms.com/QhnWy1jZmADR3Q3Qx1Db',
    en: 'https://form.nativeforms.com/0cnTz1jZmADR3Q3Qx1Db',
  },
  'newsletter-investor': {
    pl: 'https://form.nativeforms.com/sJ0YU1jZmADR3Q3Qx1Db',
    en: 'https://form.nativeforms.com/8GdGhzQp1jZmADR3Q3Qx1Db',
  },
};

const getUrl = (formId: NativeFormsIdsType, value: string): string => {
  switch (value) {
    case 'pl':
    case 'en':
      return NativeFormsUrls[formId][value];

    default:
      return NativeFormsUrls[formId].en;
  }
};
const getNativeFormsProperties = (formId: NativeFormsIdsType, lang: string) => ({
  formId,
  url: getUrl(formId, lang),
});

export default getNativeFormsProperties;
