import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z24,
          '&.MuiPaper-rounded': {
            borderRadius: theme.shape.borderRadiusMd,
          },
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
          },
        },
        paperFullWidth: {},
      },
    },
    MuiDialogTitle: {
      styleOverrides: {},
    },
    MuiDialogContent: {
      styleOverrides: {},
    },
    MuiDialogActions: {
      styleOverrides: {},
    },
  };
}
