import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { customShadows, palette, shape, spacing, typography } = theme;
  const isLight = palette.mode === 'light';
  return {
    '&': {
      [`& .${classes.root}`]: {
        '& .SnackbarContent-root': {
          width: '100%',
          padding: spacing(1.5),
          margin: spacing(0.25, 0),
          boxShadow: customShadows.z8,
          borderRadius: shape.borderRadius,
          color: palette.grey[isLight ? 0 : 800],
          backgroundColor: palette.grey[isLight ? 900 : 0],
          '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
            {
              color: palette.text.primary,
              backgroundColor: palette.background.paper,
            },
        },
        '& .SnackbarItem-message': {
          padding: '0 !important',
          fontWeight: typography.fontWeightMedium,
        },
        '& .SnackbarItem-action': {
          marginRight: 0,
          color: palette.action.active,
          '& svg': { width: 20, height: 20 },
        },
      },
    },
  };
};
