import React from 'react';
// material
import { Box } from '@mui/material';
// components
import { HeaderDesktopMenuItem } from 'components';
// types
import { ExtendedMainMenuItem } from 'types/main-menu-item';

interface HeaderDesktopMenuProps {
  items: ExtendedMainMenuItem[];
  additionalItems?: ExtendedMainMenuItem[];
}

const HeaderDesktopMenu = (props: HeaderDesktopMenuProps) => {
  const { items, additionalItems } = props;

  return (
    <>
      {items.map((item: ExtendedMainMenuItem, index) => (
        <HeaderDesktopMenuItem
          key={index}
          to={item.to ? item.to : undefined}
          onClick={item.onClick}
          isLink={item.isLink}
          active={item.active}
          label={item.label}
          variant={item.variant}
        />
      ))}
      <Box sx={{ flexGrow: 1 }} />
      {additionalItems &&
        additionalItems.map((item: ExtendedMainMenuItem, index) => (
          <HeaderDesktopMenuItem
            key={index}
            to={item.to || undefined}
            isLink={item.isLink}
            onClick={item.onClick}
            active={item.active}
            label={item.label}
            variant={item.variant}
          />
        ))}
    </>
  );
};

export default HeaderDesktopMenu;
