// ----------------------------------------------------------------------

export default function Accordion() {
  return {
    MuiAccordion: {
      styleOverrides: {},
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {},
        expandIconWrapper: {},
      },
    },
  };
}
