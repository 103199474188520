import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// material
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Grid } from '@mui/material';
// components
import { LangSwitcher, Logo, StayInContact, Cookies } from 'components';
import { Copy as CopyIcon } from 'components/CustomIcon';
// hooks
import { useLocales, usePath } from 'hooks';
//
import {
  stylesFooter,
  stylesFooterTop,
  stylesFooterBottom,
  stylesItemBox,
  stylesHeading,
  stylesShowText,
  stylesBox,
  stylesEmailBox,
  stylesUl,
  stylesCopy,
  stylesGridCopy,
  stylesGridLang,
} from './styles';

const PREFIX = 'Footer';
type ExtraProps = {
  component: React.ElementType;
};

const StyledFooter = styled('footer', { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesFooter(theme),
);
const StyledFooterTop = styled(Grid, { name: PREFIX, slot: 'footerTop' })(({ theme }) =>
  stylesFooterTop(theme),
);
const StyledFooterBottom = styled(Container, { name: PREFIX, slot: 'footerBottom' })(({ theme }) =>
  stylesFooterBottom(theme),
);
const StyledItemBox = styled(Grid, { name: PREFIX, slot: 'itemBox' })(({ theme }) =>
  stylesItemBox(theme),
);
const StyledHeading = styled(Typography, { name: PREFIX, slot: 'heading' })<ExtraProps>(
  ({ theme }) => stylesHeading(theme),
);
const StyledShowText = styled(Typography, { name: PREFIX, slot: 'showText' })(({ theme }) =>
  stylesShowText(theme),
);
const StyledBox = styled('div', { name: PREFIX, slot: 'box' })(() => stylesBox());
const StyledEmailBox = styled(Box, { name: PREFIX, slot: 'emailBox' })(({ theme }) =>
  stylesEmailBox(theme),
);
const StyledUl = styled('ul', { name: PREFIX, slot: 'ul' })(({ theme }) => stylesUl(theme));
const StyledCopy = styled(Typography, { name: PREFIX, slot: 'ul' })(({ theme }) =>
  stylesCopy(theme),
);
const StyledGridCopy = styled(Grid, { name: PREFIX, slot: 'gridCopy' })(({ theme }) =>
  stylesGridCopy(theme),
);
const StyledGridLang = styled(Grid, { name: PREFIX, slot: 'gridLang' })(({ theme }) =>
  stylesGridLang(theme),
);

const Footer = () => {
  const { translate: t, currentLang } = useLocales();
  const { findPagePath, findFileMd } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');
  const email = findFileMd('contact').node?.frontmatter?.footerEmail;

  const getPrivacyPolicyPath = () =>
    language === 'pl' ? '/polityka-prywatnosci-pl.pdf' : '/privacy-policy-en.pdf';

  useEffect(() => setLanguage(currentLang.value), [currentLang.value]);

  return (
    <StyledFooter>
      <Container>
        <StyledFooterTop container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container spacing={2} justifyContent="flex-start">
              <StyledItemBox item xs={12} sm={12} md={5} lg={4}>
                <StyledHeading component="h6" variant="h6">
                  {t('footer.titleData')}
                </StyledHeading>
                {email && (
                  <StyledEmailBox>
                    {showEmail ? (
                      <StyledBox>
                        <a href={`mailto:${email}`}>{email}</a>
                        <CopyToClipboard
                          text={email}
                          onCopy={() =>
                            enqueueSnackbar(t('common.successfullyCopiedToClipboard'), {
                              variant: 'success',
                            })
                          }
                        >
                          <CopyIcon fontSize="small" />
                        </CopyToClipboard>
                      </StyledBox>
                    ) : (
                      <StyledShowText
                        variant="body2"
                        onClick={() => {
                          setShowEmail(true);
                        }}
                      >
                        {t('footer.showEmailText')}
                      </StyledShowText>
                    )}
                  </StyledEmailBox>
                )}

                <Box>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: t('footer.address') }}
                    gutterBottom
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: t('footer.description') }}
                  />
                </Box>
              </StyledItemBox>
              <StyledItemBox item xs={6} sm={6} md={3} lg={3}>
                <StyledHeading variant="h6" component="h5">
                  {t('footer.titleSolutions')}
                </StyledHeading>
                <StyledUl>
                  {findPagePath('product').split('/').length > 2 && (
                    <li>
                      <Link to={findPagePath('product')}>{t('footer.tokenization')}</Link>
                    </li>
                  )}
                  {findPagePath('for-investors').split('/').length > 2 && (
                    <li>
                      <Link to={findPagePath('for-investors')}>{t('footer.investors')}</Link>
                    </li>
                  )}
                </StyledUl>
              </StyledItemBox>
              <StyledItemBox item xs={6} sm={6} md={4} lg={4}>
                <StyledHeading variant="h6" component="h2">
                  {t('footer.titleAbout')}
                </StyledHeading>
                <StyledUl>
                  {findPagePath('about-us').split('/').length > 2 && (
                    <li>
                      <Link to={findPagePath('about-us')}>{t('footer.ourTeam')}</Link>
                    </li>
                  )}
                  {findPagePath('blog').split('/').length > 2 && (
                    <li>
                      <Link to={findPagePath('blog')}>{t('footer.blog')}</Link>
                    </li>
                  )}
                  <li>
                    <a href={getPrivacyPolicyPath()} target="_blank" rel="noopener noreferrer">
                      {t('footer.privacyPolicy')}
                    </a>
                  </li>
                </StyledUl>
              </StyledItemBox>
            </Grid>
          </Grid>
          <StyledItemBox item xs={12} sm={12} md={3} lg={3}>
            <StyledHeading variant="h6" component="h2">
              {t('footer.titleContact')}
            </StyledHeading>
            <StayInContact showHeader={false} />
          </StyledItemBox>
        </StyledFooterTop>
      </Container>

      <Cookies />

      <StyledFooterBottom>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={{ xs: 2, md: 3, lg: 4 }}
        >
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <Logo size="small" />
          </Grid>
          <StyledGridCopy item xs={12} sm={12} md={6} lg={6}>
            <Box>
              <StyledCopy variant="body2">
                <strong>© 2021 Norion</strong> - {t('footer.copyText')}
              </StyledCopy>
            </Box>
          </StyledGridCopy>
          <StyledGridLang item xs={6} sm={6} md={3} lg={3}>
            <LangSwitcher textAlign="right" />
          </StyledGridLang>
        </Grid>
      </StyledFooterBottom>
    </StyledFooter>
  );
};

export default Footer;
