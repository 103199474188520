import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';
//
import { stylesRoot } from './styles';

interface HeadingTextWithSpaceLineProps {
  children?: string;
  typographyProps?: TypographyProps;
}

interface HeadingTextWithSpaceLineAllProps extends HeadingTextWithSpaceLineProps {
  className?: string;
}

const PREFIX = 'HeadingTextWithSpaceLine';

const StyledRoot = styled('div', { name: PREFIX, slot: 'root' })(({ theme }) => stylesRoot(theme));

const HeadingTextWithSpaceLine = (props: HeadingTextWithSpaceLineAllProps) => {
  const { className: classNameProp, children, typographyProps } = props;

  if (!children) {
    return null;
  }
  return (
    <StyledRoot className={clsx(classNameProp)}>
      <Typography {...typographyProps}>{children}</Typography>
    </StyledRoot>
  );
};

HeadingTextWithSpaceLine.defaultProps = {
  typographyProps: {
    variant: 'h5',
  },
} as Partial<HeadingTextWithSpaceLineAllProps>;

export default HeadingTextWithSpaceLine;
