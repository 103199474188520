import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
// material
import { Theme, styled, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ImageListProps,
  Grid,
} from '@mui/material';
// components
import { Hero } from 'components';
// hooks
import { usePrevious, useWidth } from 'hooks';
// helpers
import { Falseable } from 'helpers/falseable';
// types
import { ImageTypeArray } from 'types/image';
//
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------

type colSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface LogotypesProps {
  classesChildren?: any;
  colsXs?: colSize;
  colsSm?: Falseable<colSize>;
  colsMd?: Falseable<colSize>;
  colsLg?: Falseable<colSize>;
  colsXl?: Falseable<colSize>;
  gap?: ImageListProps['gap'];
  greyScale?: boolean | 'colorOnHover';
  images?: ImageTypeArray;
  imageAddon?: ReactNode;
  additionalContent?: ReactNode;
  additionalContentAsCol?: boolean;
  cssGrid?: boolean;
  sx?: SxProps<Theme>;
}

interface LogotypesAllProps extends LogotypesProps {
  className?: string;
}

const PREFIX = 'Logotypes';
const classes = {
  imagesContainer: `${PREFIX}-imagesContainer`,
  imageListItem: `${PREFIX}-imageListItem`,
  image: `${PREFIX}-image`,
  additionalContentAsCol: `${PREFIX}-additionalContentAsCol`,
  additionalContent: `${PREFIX}-additionalContent`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      greyScale: LogotypesProps['greyScale'];
    };
  }) => stylesRoot(theme, ownerState, classes),
);

const Logotypes = (props: LogotypesAllProps) => {
  const {
    className: classNameProp,
    classesChildren,
    colsXs,
    colsSm,
    colsMd,
    colsLg,
    colsXl,
    gap,
    greyScale,
    images,
    imageAddon,
    additionalContent,
    additionalContentAsCol,
    cssGrid,
    sx,
  } = props;
  const width = useWidth();
  const prevWidth = usePrevious(width);
  const [colsSize, setColsSize] = useState<LogotypesProps['colsXs']>(colsXs);
  const gapSize = gap ? gap * 8 : undefined;
  const extendedGridItemWidth = colsSize
    ? `${parseFloat(String(100 / colsSize)).toFixed(4)}%`
    : 'auto';

  const theme = useTheme();

  useEffect(() => {
    const colsSizeMap = { xs: colsXs, sm: colsSm, md: colsMd, lg: colsLg, xl: colsXl };

    if (prevWidth !== width) {
      let size = colsXs;
      const objectValues = Object.values(colsSizeMap);
      const sliceIndex = Object.keys(colsSizeMap).indexOf(width) + 1;

      objectValues.slice(0, sliceIndex).forEach((value) => {
        if (value !== false) {
          size = value;
        }
      });

      setColsSize(size);
    }
  }, [colsLg, colsMd, colsSm, colsXl, colsXs, prevWidth, width]);

  return (
    <StyledRoot
      theme={theme}
      ownerState={{ greyScale }}
      className={clsx(classNameProp, classesChildren?.root)}
      sx={sx}
    >
      {images && (
        <div className={classes.imagesContainer}>
          {cssGrid ? (
            <ImageList gap={gapSize} cols={colsSize}>
              {images.map((item, index) => (
                <ImageListItem
                  key={index}
                  classes={{
                    root: clsx(classes.imageListItem, classesChildren?.imageListItem),
                  }}
                >
                  <Hero
                    heroImg={item.src.publicURL}
                    imageName={item.src.name}
                    alt={item.alt}
                    title={item?.title}
                    className={clsx(
                      'MuiImageListItem-img',
                      classes.image,
                      item.src.name === 'uniswap' && 'MuiImageListItem-img-uniswap',
                    )}
                  />
                  {imageAddon}
                </ImageListItem>
              ))}

              {additionalContent && additionalContentAsCol && (
                <ImageListItem
                  classes={{ root: clsx(classes.imageListItem, classesChildren?.imageListItem) }}
                >
                  <ImageListItemBar
                    title={additionalContent}
                    position="below"
                    classes={{
                      root: classes.additionalContentAsCol,
                      titleWrap: classes.additionalContent,
                    }}
                  />
                </ImageListItem>
              )}
            </ImageList>
          ) : (
            <Grid container spacing={gap} alignItems="center" justifyContent="center">
              {images.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={false}
                  classes={{
                    root: clsx(classes.imageListItem, classesChildren?.imageListItem),
                  }}
                  sx={{
                    flexBasis: extendedGridItemWidth,
                    flexGrow: 0,
                  }}
                >
                  <Hero
                    heroImg={item.src.publicURL}
                    imageName={item.src.name}
                    alt={item.alt}
                    title={item?.title}
                    className={clsx(
                      'MuiImageListItem-img',
                      classes.image,
                      item.src.name === 'uniswap' && `MuiImageListItem-img-${item.src.name}`,
                    )}
                  />
                  {imageAddon}
                </Grid>
              ))}

              {additionalContent && additionalContentAsCol && (
                <Grid
                  item
                  xs
                  classes={{ root: clsx(classes.imageListItem, classesChildren?.imageListItem) }}
                >
                  <Box className={classes.additionalContent}>{additionalContent}</Box>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      )}

      {additionalContent && !additionalContentAsCol && (
        <>
          {gapSize ? (
            <Box
              sx={{ ml: cssGrid ? `${gapSize}px` : `${gapSize / 2}px` }}
              className={classes.additionalContent}
            >
              {additionalContent}
            </Box>
          ) : (
            <Box className={classes.additionalContent}>{additionalContent}</Box>
          )}
        </>
      )}
    </StyledRoot>
  );
};

Logotypes.defaultProps = {
  colsXs: 2,
  colsSm: false,
  colsMd: false,
  colsLg: false,
  colsXl: false,
  gap: 3,
  additionalContentAsCol: false,
  cssGrid: false,
} as Partial<LogotypesAllProps>;

export default Logotypes;
