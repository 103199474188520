import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, direction, palette, shape, spacing, transitions } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        height: 480,
        [breakpoints.up('sm')]: {
          height: 520,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: spacing(2),
            bottom: spacing(2),
            left: spacing(2),
            right: spacing(-2),
            background: alpha(palette.primary.main, (palette.tonalOffset as number) / 2),
            borderRadius: shape.borderRadiusMd,
          },
        },
        [breakpoints.up('md')]: {
          height: 585,
        },
        [breakpoints.up('lg')]: {
          height: 650,
          '&:before': {
            top: spacing(3),
            bottom: spacing(3),
            left: spacing(3),
            right: spacing(-3),
          },
        },
      },
      '& .slick-slide': {
        '& > div': {
          height: 'inherit',
        },
      },
      // '& .slick-slide': {
      //   '& img': {
      //     transform: 'scale(1.2)',
      //     transition: transitions.create(['transform'], {
      //       duration: '1.25s',
      //       easing: transitions.easing.easeInOut,
      //     }),
      //   },
      //   '&.slick-active img': {
      //     transform: 'scale(1)',
      //   },
      // },
      [`& .${classes.carouselWrapper}`]: {
        position: 'relative',
        height: 'inherit',
        '& .slick-list': {
          height: 'inherit',
          '& .slick-track': {
            height: 'inherit',
          },
        },
      },
      [`& .${classes.item}`]: {
        display: 'block !important',
        height: 'inherit',
        position: 'relative',
        zIndex: 0,
        borderRadius: shape.borderRadiusMd,
        overflow: 'hidden',
        backgroundColor: alpha(palette.primary.main, (palette.tonalOffset as number) / 2),
      },
      [`& .${classes.image}`]: {
        maxWidth: 528,
        maxHeight: 650,
        width: '100%',
        height: 'inherit',
        objectFit: 'cover',
      },
      [`& .${classes.navigation}`]: {
        position: 'absolute',
        bottom: spacing(2),
        left: spacing(2),
        right: spacing(2),
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1.5, 3),
        justifyContent: 'space-between',
        backgroundColor: palette.background.paper,
        borderRadius: shape.borderRadiusSm,
        flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
      },
      [`& .${classes.category}`]: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: spacing(2),
        right: spacing(2),
        maxWidth: `calc(100% - ${spacing(4)})`,
        padding: spacing(1.5),
        backgroundColor: palette.background.paper,
        borderRadius: shape.borderRadiusSm,
        '& svg': {
          fontSize: 20,
          marginRight: spacing(1.5),
          color: palette.primary.lighter,
        },
      },
      [`& .${classes.navigationButton}`]: {
        display: 'flex',
        marginTop: spacing(1.5),
        marginBottom: spacing(1.5),
        color: palette.text.secondary,
        background: 'transparent',
        boxShadow: `0 0 0 1px ${palette.divider}`,
        transition: transitions.create(['background', 'border', 'color'], {
          duration: transitions.duration.short,
        }),
        '&:hover': {
          background: palette.primary.main,
          color: palette.primary.contrastText,
          boxShadow: 'none',
        },
      },
      [`& .${classes.title}`]: {
        padding: spacing(0, 2),
      },
    },
  };
};
