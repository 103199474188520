import React, { ReactNode } from 'react';
import clsx from 'clsx';
// snackbar
import { SnackbarProvider } from 'notistack';
// material
import { Theme, styled } from '@mui/material/styles';
import { alpha } from '@mui/system';
import {
  CheckCircleRounded as SuccessIcon,
  ErrorRounded as ErrorIcon,
  InfoRounded as InfoIcon,
  WarningRounded as WarningIcon,
} from '@mui/icons-material';
import { Box } from '@mui/material';
//
import { stylesRoot } from './styles';

interface NotistackProviderProps {
  children?: ReactNode;
}

export interface NotistackProviderAllProps extends NotistackProviderProps {
  className?: string;
}

type SnackbarIconProps = {
  icon: ReactNode;
  color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
};

const PREFIX = 'SnackbarIcon';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('div', { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 1,
        width: 36,
        minWidth: 36,
        height: 36,
        borderRadius: ({ shape }: Theme) => `${shape.borderRadius}px`,
        color: `${color}.main`,
        bgcolor: ({ palette }: Theme) => alpha(palette[color].main, palette.tonalOffset as number),
      }}
    >
      {icon}
    </Box>
  );
}

const NotistackProvider = (props: NotistackProviderAllProps) => {
  const { className: classNameProp, children } = props;
  return (
    <>
      <StyledRoot>
        <SnackbarProvider
          dense={false}
          // preventDuplicate
          autoHideDuration={4500}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          iconVariant={{
            success: <SnackbarIcon icon={<SuccessIcon />} color="success" />,
            error: <SnackbarIcon icon={<ErrorIcon />} color="error" />,
            warning: <SnackbarIcon icon={<WarningIcon />} color="warning" />,
            info: <SnackbarIcon icon={<InfoIcon />} color="info" />,
          }}
          classes={{
            root: clsx(classes.root, classNameProp),
          }}
        >
          {children}
        </SnackbarProvider>
      </StyledRoot>
    </>
  );
};

export default NotistackProvider;
