import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Lists({ palette }: Theme) {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          '& > .MuiListItem-divider:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiListItem: {
      defaultProps: {},
      styleOverrides: {
        divider: {
          borderColor: palette.action.selected,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {},
    },
    MuiListItemAvatar: {
      styleOverrides: {},
    },
    MuiListItemText: {
      styleOverrides: {},
    },
  };
}
