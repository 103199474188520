import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Card, CardActions, CardContent, CardHeader, CardHeaderProps } from '@mui/material';
//
import { stylesFooter, stylesHeader } from './styles';

interface CardBoxProps {
  className?: string;
  classesChildren?: any;
  children?: ReactNode;
  action?: CardHeaderProps['action'];
  actionUnder?: boolean;
  title?: string;
  subtitle?: string;
  footer?: ReactNode;
  footerUnder?: boolean;
}

const PREFIX = 'CardBox';
const classesHeader = {
  headerActionUnder: `${PREFIX}-headerActionUnder`,
};
const classesFooter = {
  footer: `${PREFIX}-footer`,
  footerUnder: `${PREFIX}-footerUnder`,
};
const StyledHeader = styled(CardHeader)(() => stylesHeader(classesHeader));
const StyledFooter = styled(CardActions)(({ theme }) => stylesFooter(theme, classesFooter));

const CardBox = (props: CardBoxProps) => {
  const {
    className: classNameProp,
    classesChildren,
    children,
    title,
    subtitle,
    action,
    actionUnder,
    footer,
    footerUnder,
  } = props;

  return (
    <Card className={clsx(classNameProp, classesChildren?.root)} variant="outlined">
      {(title || subtitle) && (
        <StyledHeader
          title={title}
          subheader={subtitle}
          titleTypographyProps={{ variant: 'h4', component: 'h3' }}
          action={action}
          classes={{ root: clsx(actionUnder && classesHeader.headerActionUnder) }}
        />
      )}
      <CardContent>{children}</CardContent>
      {footer && (
        <StyledFooter
          classes={{
            root: clsx(
              classesFooter.footer,
              footerUnder && classesFooter.footerUnder,
              classesChildren?.footer,
            ),
          }}
        >
          {footer}
        </StyledFooter>
      )}
    </Card>
  );
};

CardBox.defaultProps = {
  actionUnder: false,
  footerUnder: false,
} as Partial<CardBoxProps>;

export default CardBox;
