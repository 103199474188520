import * as React from 'react';
// gatsby
import { GatsbyImage } from 'gatsby-plugin-image';
// hooks
import { useSiteImages } from 'hooks';
// types
import { SimpleImageAttributesType } from 'types/image';

interface ChangeableImageProps extends SimpleImageAttributesType {
  className?: string;
  heroImg: string;
  title?: string;
  alt: string;
  imageName: string;
}

const ChangeableImage = (props: ChangeableImageProps) => {
  const { className: classNameProp, imageName, heroImg, alt, title } = props;
  const { findImage } = useSiteImages();
  const image = findImage(imageName) || false;

  return (
    <>
      {image && image.gatsbyImageData ? (
        <>
          <GatsbyImage
            image={
              image?.gatsbyImageData ? { ...image.gatsbyImageData, layout: 'fullWidth' } : undefined
            }
            alt={alt}
            title={title || undefined}
            className={classNameProp}
          />
        </>
      ) : (
        heroImg && <img src={heroImg} alt={alt} title={title} className={classNameProp} />
      )}
    </>
  );
};

export default ChangeableImage;
