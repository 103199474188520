import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  TypographyProps,
  unstable_composeClasses as composeClasses,
  useThemeProps,
} from '@mui/material';
// types
import { SxProps } from '@mui/system';
// utils
import { BoxWithHeaderClasses, componentName, getItemUtilityClass } from './boxWithHeaderClasses';
//
import { headingSpaceStyles, rootStyles } from './styles';
// ----------------------------------------------------------------------

interface TypographyExtendsProps extends TypographyProps {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
}

export interface BoxWithHeaderProps {
  /**
   * Class added to main element of component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component.
   *
   * Possible values: `root` `headingSpace` `miniSpace` `smallSpace` `mediumSpace` `largeSpace` `header` `subheader` `content`
   */
  classes?: Partial<BoxWithHeaderClasses>;
  /**
   * Content of the component.
   */
  children?: ReactNode;
  /**
   * Header of the component.
   */
  header?: ReactNode;
  /**
   * Typography properties for header.
   *
   * *`TypographyProps` - [see MUI's Typography API](https://mui.com/material-ui/api/typography/)*
   *
   * @default { color: 'textPrimary', variant: 'h1', component: 'h3' }
   */
  headerProps?: TypographyExtendsProps;
  /**
   * Subheader of the component.
   */
  subheader?: ReactNode;
  /**
   * Typography properties for subheader.
   *
   * *`TypographyProps` - [see MUI's Typography API](https://mui.com/material-ui/api/typography/)*
   *
   * @default { color: 'primary', variant: 'subtitle1', component: 'h2' }
   */
  subheaderProps?: TypographyExtendsProps;
  /**
   * Invert composition of heading space.
   * @default false
   */
  invertHeader?: boolean;
  /**
   * Set text align for component.
   * @default left
   */
  textAlign?: TypographyProps['align'];
  /**
   * Set the spacing under the header
   * @default large
   */
  spaceHeader?: 'mini' | 'small' | 'medium' | 'large';
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   *
   * See the `sx` MUI's documentation for more details.
   */
  sx?: SxProps<Theme>;
}

const useUtilityClasses = (ownerState: Partial<BoxWithHeaderProps>) => {
  const { classes, spaceHeader } = ownerState;
  const slots = {
    root: ['root'],
    headingSpace: ['headingSpace', spaceHeader && `${spaceHeader}Space`],
    header: ['header'],
    subheader: ['subheader'],
    content: ['content'],
  };
  return composeClasses(slots, getItemUtilityClass, classes);
};

const StyledRoot = styled(Box, {
  name: componentName,
  slot: 'root',
  overridesResolver: (props, styles) => styles.root,
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<BoxWithHeaderProps> }) =>
  rootStyles(theme, ownerState),
);

const StyledHeadingSpace = styled('div', {
  name: componentName,
  slot: 'headingSpace',
  overridesResolver: (props, styles) => {
    const { spaceHeader } = props.ownerState;
    return [styles.headingSpace, spaceHeader && styles[`${spaceHeader}Space`]];
  },
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<BoxWithHeaderProps> }) =>
  headingSpaceStyles(theme, ownerState),
);

const StyledHeader = styled(Typography, {
  name: componentName,
  slot: 'header',
  overridesResolver: (props, styles) => styles.header,
})({});

const StyledSubheader = styled(Typography, {
  name: componentName,
  slot: 'subheader',
  overridesResolver: (props, styles) => styles.subheader,
})({});

const StyledContent = styled('div', {
  name: componentName,
  slot: 'content',
  overridesResolver: (props, styles) => styles.content,
})({});

const BoxWithHeader = forwardRef<HTMLDivElement, BoxWithHeaderProps>((inProps, ref) => {
  const props = useThemeProps({
    props: inProps,
    name: componentName,
  });
  const {
    className: classNameProp,
    classes: classesProp,
    children,
    sx,
    header,
    headerProps = {
      color: 'textPrimary',
      variant: 'h1',
      component: 'h3',
    },
    subheader,
    subheaderProps = {
      color: 'primary',
      variant: 'subtitle1',
      component: 'h2',
    },
    invertHeader = false,
    textAlign = 'left',
    spaceHeader = 'large',
  } = props;
  const theme = useTheme();

  const ownerState: Partial<BoxWithHeaderProps> = {
    classes: classesProp,
    textAlign,
    spaceHeader,
  };

  const classes = useUtilityClasses(ownerState);

  const renderHeader = (): ReactNode => {
    if (header) {
      return (
        <StyledHeader className={classes.header} {...headerProps}>
          {header}
        </StyledHeader>
      );
    }
    return null;
  };

  const renderSubheader = (): ReactNode => {
    if (subheader) {
      return (
        <StyledSubheader className={classes.subheader} {...subheaderProps}>
          {subheader}
        </StyledSubheader>
      );
    }
    return null;
  };

  return (
    <StyledRoot
      ref={ref}
      theme={theme}
      className={clsx(classNameProp, classes.root)}
      ownerState={{ textAlign }}
      sx={sx}
    >
      {(header || subheader) && (
        <StyledHeadingSpace
          theme={theme}
          className={classes.headingSpace}
          ownerState={{ spaceHeader, children }}
        >
          {!invertHeader ? (
            <>
              {renderSubheader()}
              {renderHeader()}
            </>
          ) : (
            <>
              {renderHeader()}
              {renderSubheader()}
            </>
          )}
        </StyledHeadingSpace>
      )}

      {children && <StyledContent className={classes.content}>{children}</StyledContent>}
    </StyledRoot>
  );
});

export default BoxWithHeader;
