import * as React from 'react';
import { Typography, Paper, Box, BoxProps, PaperProps, TypographyVariant } from '@mui/material';
import { useTheme } from '@mui/system';

// ----------------------------------------------------------------------

interface BlockProps extends BoxProps {
  title?: string;
  titleVariant?: TypographyVariant;
  titleGutterBottom?: boolean;
  paperProps?: PaperProps;
}

const Block = (props: BlockProps) => {
  const { children, title, titleVariant, titleGutterBottom, paperProps, sx, ...other } = props;
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }} {...other}>
      {title && (
        <Typography gutterBottom={titleGutterBottom} variant={titleVariant}>
          {title}
        </Typography>
      )}
      <Paper
        sx={{
          p: 1,
          '& > *': { my: `${theme.spacing(1)} !important` },
          ...sx,
        }}
        {...paperProps}
      >
        {children}
      </Paper>
    </Box>
  );
};

Block.defaultProps = {
  titleVariant: 'h6',
  titleGutterBottom: true,
} as Partial<BlockProps>;

export default Block;
