import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="M14.13 11.34C14.52 9.70003 13.09 8.27003 11.45 8.66003C10.68 8.85003 10.05 9.48003 9.85998 10.25C9.46998 11.89 10.9 13.32 12.54 12.93C13.32 12.74 13.95 12.11 14.13 11.34Z" />
    <path d="M6.01996 17.97C5.83996 17.97 5.64996 17.9 5.50996 17.77C3.56996 15.97 2.45996 13.43 2.45996 10.79C2.45996 5.53 6.73996 1.25 12 1.25C17.26 1.25 21.54 5.53 21.54 10.79C21.54 13.45 20.47 15.91 18.53 17.74C18.23 18.02 17.75 18.01 17.47 17.71C17.19 17.41 17.2 16.93 17.5 16.65C19.14 15.11 20.04 13.04 20.04 10.8C20.04 6.37 16.43 2.76 12 2.76C7.56996 2.76 3.95996 6.37 3.95996 10.8C3.95996 13.06 4.86996 15.14 6.52996 16.68C6.82996 16.96 6.84996 17.44 6.56996 17.74C6.41996 17.89 6.21996 17.97 6.01996 17.97Z" />
    <path d="M16 15.3C15.82 15.3 15.63 15.23 15.49 15.1C15.19 14.82 15.17 14.34 15.46 14.04C16.29 13.16 16.75 12 16.75 10.8C16.75 8.18 14.62 6.06 12.01 6.06C9.40002 6.06 7.27002 8.19 7.27002 10.8C7.27002 12.01 7.73002 13.16 8.56002 14.04C8.84002 14.34 8.83002 14.82 8.53002 15.1C8.23002 15.39 7.75002 15.37 7.47002 15.07C6.38002 13.91 5.77002 12.39 5.77002 10.8C5.77002 7.36 8.57002 4.56 12.01 4.56C15.45 4.56 18.25 7.36 18.25 10.8C18.25 12.39 17.65 13.91 16.55 15.07C16.4 15.22 16.2 15.3 16 15.3Z" />
    <path d="M10.3 16.66L8.86001 18.45C7.72001 19.88 8.74001 22 10.56 22H13.43C15.26 22 16.28 19.88 15.13 18.46L13.69 16.67C12.83 15.57 11.17 15.57 10.3 16.66Z" />
  </>,
  'Airdrop',
);
