import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, spacing, typography } = theme;
  return {
    '&': {
      '&:not(:first-of-type)': {
        marginLeft: spacing(0.5),
      },
      '&:not(:last-child)': {
        marginRight: spacing(0.5),
      },
      [breakpoints.up('lg')]: {
        '&:not(:first-of-type)': {
          marginLeft: spacing(1),
        },
        '&:not(:last-child)': {
          marginRight: spacing(1),
        },
      },
      [`&.${classes.text}`]: {
        fontWeight: typography.fontWeightMedium,
        color: palette.text.secondary,
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
        [breakpoints.up('lg')]: {
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        },
      },
      [`&.${classes.textActive}`]: {
        color: palette.primary.dark,
        fontWeight: typography.fontWeightBold,
      },
      [`&.${classes.featured}`]: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        [breakpoints.up('lg')]: {
          paddingLeft: spacing(4),
          paddingRight: spacing(4),
        },
      },
      [`&.${classes.startIcon}`]: {},
      [`&.${classes.endIcon}`]: {
        fontSize: '30px !important',
      },
    },
  };
};
