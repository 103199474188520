import { Theme } from '@mui/material/styles';
import { FeaturedBoxProps } from './FeaturedBox';

export const stylesRoot = (
  theme: Theme,
  ownerState: {
    image: FeaturedBoxProps['image'];
  },
  classes: any,
) => {
  const { spacing, breakpoints } = theme;
  const { image } = ownerState;

  return {
    '&': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      ...(image && {
        overflow: 'unset',
        marginTop: 68,
        [`& .${classes.image}`]: {
          marginTop: `calc(${spacing(-3)} - 68px)`,
        },
        [breakpoints.up('md')]: {
          marginTop: 84,
          [`& .${classes.image}`]: {
            marginTop: `calc(${spacing(-3)} - 84px)`,
          },
        },
        [breakpoints.up('lg')]: {
          [`& .${classes.image}`]: {
            marginTop: `calc(${spacing(-4)} - 84px)`,
          },
        },
      }),
    },
  };
};

export const stylesContent = () => ({
  '&': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const stylesTitle = (theme: Theme) => {
  const { palette } = theme;
  return {
    '&': {
      color: palette.text.primary,
    },
  };
};

export const stylesImage = (theme: Theme) => {
  const { breakpoints } = theme;
  return {
    '&': {
      margin: '0 auto',
      height: 136,
      width: '100%',
      maxWidth: 136,
      [breakpoints.up('md')]: {
        height: 168,
        maxWidth: 168,
      },
    },
  };
};
