import React, { Suspense } from 'react';
// material
import { Box, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress sx={{ display: 'block' }} />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
