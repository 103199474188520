import React, { forwardRef } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { unstable_composeClasses as composeClasses, useThemeProps } from '@mui/material';
import { SxProps } from '@mui/system';
// image
import NorionLogo from 'images/svg/Logo.svg';
//
import { rootStyles } from './styles';
import { componentName, getItemUtilityClass, LogoClasses } from './logoClasses';

export interface LogoProps {
  /**
   * Class added to main element of component.
   */
  className?: string;

  /**
   * Override or extend the styles applied to the component.
   *
   * Possible values: `root` `defaultSize` `smallSize` `mediumSize` `largeSize` `centered`
   */
  classes?: Partial<LogoClasses>;

  /**
   * The `src` attribute for the `img` element.
   * @default NorionLogo
   */
  svg?: string;

  /**
   * Props which allows to provide an alt attribute for the rendered `img` element.
   * @default Norion
   */
  alt?: string;

  /**
   * Props which allows to provide size of the logo.
   * @default default
   */
  size?: 'default' | 'small' | 'medium' | 'large';

  /**
   * Props to determine if the logo should be centered
   * @default false
   */
  center?: boolean;

  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   *
   * See the `sx` MUI's documentation for more details.
   */
  sx?: SxProps<Theme>;
}

const useUtilityClasses = (ownerState: Partial<LogoProps>) => {
  const { classes, size, center } = ownerState;
  const slots = {
    root: ['root', size && `${size}Size`, center && `centered`],
  };
  return composeClasses(slots, getItemUtilityClass, classes);
};

const StyledRoot = styled('div', {
  name: componentName,
  slot: 'root',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    const { size, center } = ownerState;
    return [styles.root, size && styles[`${size}Size`], center && styles.centered];
  },
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<LogoProps> }) =>
  rootStyles(theme, ownerState),
);

const Logo = forwardRef<HTMLDivElement, LogoProps>((inProps, ref) => {
  const props = useThemeProps({
    props: inProps,
    name: componentName,
  });

  const {
    className: classNameProp,
    svg = NorionLogo,
    alt = 'Norion',
    size = 'default',
    center = false,
    sx,
    ...otherProps
  } = props;

  const theme = useTheme();
  const ownerState: Partial<LogoProps> = {
    size,
    center,
  };

  const componentClasses = useUtilityClasses(ownerState);

  return (
    <StyledRoot
      ref={ref}
      theme={theme}
      sx={sx}
      ownerState={ownerState}
      className={clsx(componentClasses.root, classNameProp)}
      {...otherProps}
    >
      <img src={svg} alt={alt} />
    </StyledRoot>
  );
});

export default Logo;
