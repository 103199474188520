import React, { useState } from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled } from '@mui/material/styles';
import { DialogContent, Dialog, Hidden, IconButton, Typography, Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// components
import { BoxWithHeader, Newsletter } from 'components';
// hooks
import { useLocales } from 'hooks';
// helpers
import getNativeFormsProperties, { NativeFormsIdsType } from 'helpers/getNativeFormsUrl';
//
import { stylesRoot } from './styles';

interface ModalInvestorProps {
  open: boolean;
  handleClose?: VoidFunction;
}

interface ModalInvestorAllProps extends ModalInvestorProps {
  className?: string;
}

const PREFIX = 'ModalInvestor';
const classes = {
  content: `${PREFIX}-content`,
  closeButton: `${PREFIX}-closeButton`,
  side: `${PREFIX}-side`,
  leftSide: `${PREFIX}-leftSide`,
  rightSide: `${PREFIX}-rightSide`,
  image: `${PREFIX}-image`,
  rightTopLineImage: `${PREFIX}-rightTopLineImage`,
  rightBottomLineImage: `${PREFIX}-rightBottomLineImage`,
  coin: `${PREFIX}-coin`,
  leftBox: `${PREFIX}-leftBox`,
  bottomInfo: `${PREFIX}-bottomInfo`,
  redLineImage: `${PREFIX}-redLineImage`,
  pinkCoin: `${PREFIX}-pinkCoin`,
  nativeForms: `${PREFIX}-nativeForms`,
};

const StyledRoot = styled(Dialog, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const ModalInvestor = (props: ModalInvestorAllProps) => {
  const { className: classNameProp, open, handleClose } = props;
  const { currentLang, translate: t } = useLocales();
  const [showHeader, setShowHeader] = useState<boolean>(false);

  const FORM_ID: NativeFormsIdsType = 'for-investors';
  const formProperties = getNativeFormsProperties(FORM_ID, currentLang.value);

  const onClose = (): void => {
    if (typeof handleClose === 'function') {
      handleClose();
    }
    setTimeout(() => setShowHeader(false), 300);
  };

  const data = useStaticQuery(graphql`
    query {
      uniswapImage: file(relativePath: { eq: "investor-modal/uniswap.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 164)
        }
      }
      redLineDecoration: file(relativePath: { eq: "investor-modal/decoration-red-line.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 136)
        }
      }
      pinkCoinDecoration: file(relativePath: { eq: "coin3.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 106, formats: [AUTO, WEBP, AVIF])
        }
      }
      coinDecoration: file(relativePath: { eq: "coin2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 240, formats: [AUTO, WEBP, AVIF])
        }
      }
      firstLineDecoration: file(relativePath: { eq: "investor-modal/line-top.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 213, formats: [AUTO, WEBP, AVIF])
        }
      }
      secondLineDecoration: file(relativePath: { eq: "investor-modal/line-bottom.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 385, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  return (
    <StyledRoot
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      className={clsx(classNameProp)}
    >
      <DialogContent className={classes.content}>
        <IconButton
          aria-label="close"
          size="small"
          color="inherit"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} textAlign="left">
            <Box className={clsx(classes.leftSide, classes.side)}>
              <Hidden mdDown>
                <div className={clsx(classes.image, classes.rightTopLineImage)}>
                  <GatsbyImage
                    image={data.firstLineDecoration.childImageSharp.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                    alt=""
                    title=""
                  />
                </div>
              </Hidden>

              <div className={clsx(classes.image, classes.rightBottomLineImage)}>
                <GatsbyImage
                  image={data.secondLineDecoration.childImageSharp.gatsbyImageData}
                  imgStyle={{ objectFit: 'contain' }}
                  alt=""
                  title=""
                />
              </div>
              <Hidden mdDown>
                <div className={clsx(classes.image, classes.coin)}>
                  <GatsbyImage
                    image={data.coinDecoration.childImageSharp.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                    alt=""
                    title=""
                  />
                </div>
              </Hidden>

              <BoxWithHeader
                header={t('modalInvestor.title')}
                headerProps={{
                  color: 'textPrimary',
                  variant: 'h2',
                }}
                subheader={t('modalInvestor.subtitle')}
                textAlign="left"
                spaceHeader="mini"
                className={classes.leftBox}
              >
                <Typography
                  color="textSecondary"
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: t('modalInvestor.description') }}
                />
              </BoxWithHeader>
              <Box className={classes.bottomInfo}>
                <Typography color="textSecondary" variant="body1">
                  {t('modalInvestor.bottomText')}
                </Typography>
                <GatsbyImage
                  image={data.uniswapImage.childImageSharp.gatsbyImageData}
                  alt={data.uniswapImage.name}
                  title=""
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            textAlign="left"
            className={clsx(classes.rightSide, classes.side)}
          >
            <Hidden mdDown>
              <div className={clsx(classes.image, classes.redLineImage)}>
                <GatsbyImage
                  image={data.redLineDecoration.childImageSharp.gatsbyImageData}
                  imgStyle={{ objectFit: 'contain' }}
                  alt=""
                  title=""
                />
              </div>
            </Hidden>
            <Hidden mdDown>
              <div className={clsx(classes.image, classes.pinkCoin)}>
                <GatsbyImage
                  image={data.pinkCoinDecoration.childImageSharp.gatsbyImageData}
                  imgStyle={{ objectFit: 'contain' }}
                  alt=""
                  title=""
                />
              </div>
            </Hidden>

            <BoxWithHeader
              header={showHeader ? t('modalInvestor.formTitle') : null}
              headerProps={{
                color: 'textPrimary',
                variant: 'h3',
              }}
              textAlign="left"
              spaceHeader="mini"
            >
              <Newsletter
                className={classes.nativeForms}
                form={formProperties.url}
                formId={formProperties.formId}
                onLoad={() => setShowHeader(true)}
                onSend={() => setShowHeader(false)}
              />
            </BoxWithHeader>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledRoot>
  );
};

export default ModalInvestor;
