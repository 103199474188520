import * as React from 'react';
import slugify from 'slugify';
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql } from 'gatsby';
// types
import { Metadata } from 'types/metadata';
// hooks
import { useLocales, usePath } from 'hooks';
import { useLocation } from '@reach/router';

interface SEOProps {
  metaExtended?: Array<{ name: string; content: string }>;
  metadata?: Metadata;
  mainPhoto?: string;
  userLang?: string;
  locale?: string;
  path?: string;
}

function Seo({ metaExtended = [], metadata, mainPhoto, userLang, locale, path }: SEOProps) {
  const { pathname } = useLocation();
  const { allLang, pathLang, defaultLang, currentLang } = useLocales();
  const { allMarkdown, allSitePage } = usePath();
  const pagePath = path && path !== '/*' ? path : pathname;
  const pageLang = pathLang || locale;

  const { site, pages }: any = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            ogImage
            fbAppId
            twitterCreator
            twitterUrl
            keywords
          }
        }
        pages: allMdx(filter: { frontmatter: { globalMetadata: { in: true } } }) {
          edges {
            node {
              slug
              parent {
                id
                ... on File {
                  name
                  relativeDirectory
                }
              }
              frontmatter {
                title
                slug
                metadata {
                  title
                  description
                  og_title
                  og_description
                  og_type
                  og_image {
                    publicURL
                    name
                  }
                  fb_app_id
                  twitter_card
                  twitter_creator
                  twitter_title
                  twitter_description
                  twitter_image {
                    publicURL
                    name
                  }
                  twitter_url
                  keywords
                }
              }
            }
          }
        }
      }
    `,
  );

  const homePage = pages.edges.find(
    (page: any) => page.node.parent.name.split('.')[1] === userLang || pageLang,
  );
  const homePageMetaTags = homePage?.node?.frontmatter?.metadata;

  const activeUrl = `${site.siteMetadata.siteUrl}${pagePath}`;
  const fullMainImageOrStandard = mainPhoto
    ? `${site.siteMetadata.siteUrl}${mainPhoto}`
    : `${site.siteMetadata.siteUrl}${homePageMetaTags?.og_image?.publicURL}`;
  const fullOgImageUrl = metadata?.og_image?.publicURL
    ? `${site.siteMetadata.siteUrl}${metadata?.og_image?.publicURL}`
    : fullMainImageOrStandard;
  const fullTwitterImageUrl = metadata?.twitter_image?.publicURL
    ? `${site.siteMetadata.siteUrl}${metadata?.twitter_image?.publicURL}`
    : fullOgImageUrl;

  const metaTitle: string = metadata?.title || homePageMetaTags?.title || site.siteMetadata.title;
  const metaDescription: string =
    metadata?.description || homePageMetaTags?.description || site.siteMetadata.description;
  const metaAuthor: string =
    metadata?.author || homePageMetaTags?.author || site.siteMetadata.author;
  const metaOgUrl: string = activeUrl;
  const metaOgTitle: string =
    metadata?.og_title || metadata?.title || homePageMetaTags?.title || site.siteMetadata.title;
  const metaOgDescription: string =
    metadata?.og_description ||
    metadata?.description ||
    homePageMetaTags?.description ||
    site.siteMetadata.description;
  const metaOgType: string = metadata?.og_type || 'website';
  const metaOgImage: string = fullOgImageUrl;
  const metaFbApp: string =
    metadata?.fb_app_id || homePageMetaTags?.fb_app_id || site.siteMetadata.fbAppId;
  const metaOgLocale: string =
    Object.values(allLang).find((lang: any) => lang.value === userLang || lang.value === pageLang)
      ?.localeLabel || currentLang.localeLabel;
  const metaTwitterCard: string = 'summary';
  const metaTwitterCreator: string =
    metadata?.twitter_creator ||
    homePageMetaTags?.twitter_creator ||
    site.siteMetadata.twitterCreator;
  const metaTwitterTitle: string =
    metadata?.twitter_title || homePageMetaTags?.twitter_title || site.siteMetadata.title;
  const metaTwitterDescription: string =
    metadata?.twitter_description ||
    homePageMetaTags?.twitter_description ||
    site.siteMetadata.description;
  const metaTwitterImage: string = fullTwitterImageUrl;
  const metaTwitterUrl: string =
    metadata?.twitter_url || homePageMetaTags?.twitter_url || site.siteMetadata.twitterUrl;
  const metaKeywords: string =
    metadata?.keywords || homePageMetaTags?.keywords || site.siteMetadata.keywords;

  const generateAdditionalHreflang = (lang: string, path: string) => ({
    rel: 'alternate',
    hreflang: 'x-default',
    href: `${site.siteMetadata.siteUrl}${path}`,
  });

  const generateLinkDataHelmet = () => {
    const linkArray: { rel: string; hreflang: string; href: string }[] = [];
    const url = pagePath.replace(/\/$/, ``).split('/').pop();

    const activeSite = allMarkdown.edges.filter(
      (item: any) =>
        item.node.frontmatter.slug === url ||
        slugify(item.node.frontmatter.title, {
          replacement: '-',
          lower: true,
        }) === url,
    );

    if (!activeSite.length) {
      Object.values(allLang).forEach((lang) => {
        linkArray.push({
          rel: 'alternate',
          hreflang: lang.value,
          href: `${site.siteMetadata.siteUrl}/${
            defaultLang?.value === lang.value ? '' : `${lang.value}/`
          }`,
        });
        if (defaultLang?.value === lang.value) {
          linkArray.push(generateAdditionalHreflang(lang.value, `/`));
        }
      });
      return linkArray;
    }

    const matchSiteArray = allMarkdown.edges.filter(
      (item: any) =>
        item.node.parent.relativeDirectory === activeSite[0].node.parent.relativeDirectory &&
        item.node.frontmatter.published,
    );

    matchSiteArray.forEach((md: any) => {
      const findSite = allSitePage.edges.filter(
        (site: any) => site.node.pageContext.slug === md.node.frontmatter.slug,
      );
      findSite.forEach((website: any) => {
        if (
          !linkArray.filter(
            (link) => link.href === `${site.siteMetadata.siteUrl}${website.node.path}`,
          ).length
        ) {
          linkArray.push({
            rel: 'alternate',
            hreflang: website.node.pageContext.locale,
            href: `${site.siteMetadata.siteUrl}${website.node.path}`,
          });

          if (website.node.pageContext.locale === defaultLang?.value) {
            linkArray.push(
              generateAdditionalHreflang(website.node.pageContext.locale, website.node.path),
            );
          }
        }
      });
    });

    return linkArray;
  };

  const metaDataHelmet = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'author',
      content: metaAuthor,
    },
    {
      property: 'og:url',
      content: metaOgUrl,
    },
    {
      property: 'og:type',
      content: metaOgType,
    },
    {
      property: 'og:title',
      content: metaOgTitle,
    },
    {
      property: 'og:description',
      content: metaOgDescription,
    },
    {
      property: 'og:image',
      content: metaOgImage,
    },
    {
      property: 'og:image:width',
      content: '1200',
    },
    {
      property: 'og:image:height',
      content: '630',
    },
    {
      property: 'fb:app_id',
      content: metaFbApp,
    },
    {
      property: 'og:locale',
      content: metaOgLocale,
    },
    {
      name: 'twitter:card',
      content: metaTwitterCard,
    },
    {
      name: 'twitter:creator',
      content: metaTwitterCreator,
    },
    {
      name: 'twitter:title',
      content: metaTwitterTitle,
    },
    {
      name: 'twitter:description',
      content: metaTwitterDescription,
    },
    {
      name: 'twitter:url',
      content: metaTwitterUrl,
    },
    {
      name: 'twitter:image',
      content: metaTwitterImage,
    },
    {
      name: 'keywords',
      content: metaKeywords,
    },
  ].concat(metaExtended);

  const linkDataHelmet = [...generateLinkDataHelmet()];

  return (
    <Helmet
      htmlAttributes={{
        lang: pageLang,
      }}
      defer={false}
    >
      <title>{metaTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      {metaDataHelmet.map((metaTag, index) => (
        <meta key={index} {...metaTag} />
      ))}
      {linkDataHelmet.map((linkTag, index) => (
        <link key={index} {...linkTag} />
      ))}
    </Helmet>
  );
}

export default Seo;
