import { Theme } from '@mui/material/styles';
import { CSSInterpolation } from '@mui/styled-engine';
import { LogoProps } from './Logo';

export const rootStyles = (theme: Theme, ownerState: Partial<LogoProps>): CSSInterpolation => {
  const { size, center } = ownerState;

  return {
    '&': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      justifyContent: center ? 'center' : 'flex-start',
      ...(size === 'default' && {
        height: 32,
      }),
      ...(size === 'small' && {
        height: 26,
      }),
      ...(size === 'medium' && {
        height: 40,
      }),
      ...(size === 'large' && {
        height: 48,
      }),
      '& img': {
        display: 'block',
        margin: 0,
        height: '100%',
        maxWidth: '100%',
      },
    },
  };
};
