import React from 'react';
import clsx from 'clsx';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Box, Typography, TypographyProps } from '@mui/material';
//
import { stylesRoot, stylesIcon, stylesTitle } from './styles';

interface TypographyExtendsProps extends TypographyProps {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
}

export interface CapabilityItemProps {
  className?: string;
  title?: string;
  icon?: ReactJSXElement;
  featured?: boolean;
  iconSize?: 'small' | 'medium' | 'large';
  layout?: 'horizontal' | 'vertical';
  hover?: boolean;
  titleTypographyProps?: TypographyExtendsProps;
}

const PREFIX = 'CapabilityItem';
const classes = {
  root: `${PREFIX}-root`,
  hover: `${PREFIX}-hover`,
  featured: `${PREFIX}-featured`,
  icon: `${PREFIX}-icon`,
};

const StyledRoot = styled(Box)(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      layout: CapabilityItemProps['layout'];
    };
  }) => stylesRoot(theme, ownerState, classes),
);

const StyledIcon = styled('div')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      iconSize: CapabilityItemProps['iconSize'];
    };
  }) => stylesIcon(theme, ownerState),
);

const StyledTitle = styled(Typography, { name: PREFIX, slot: 'title' })(({ theme }) =>
  stylesTitle(theme),
);

const CapabilityItem = (props: CapabilityItemProps) => {
  const {
    className: classNameProp,
    title,
    icon,
    iconSize,
    layout,
    titleTypographyProps,
    hover,
    featured,
  } = props;
  const theme = useTheme();

  return (
    <StyledRoot
      theme={theme}
      ownerState={{ layout }}
      className={clsx(
        classes.root,
        hover && classes.hover,
        featured && classes.featured,
        classNameProp,
      )}
    >
      {icon && (
        <StyledIcon theme={theme} ownerState={{ iconSize }} className={classes.icon}>
          {icon}
        </StyledIcon>
      )}
      {title && (
        <StyledTitle {...titleTypographyProps} lineHeight={1.4}>
          {title}
        </StyledTitle>
      )}
    </StyledRoot>
  );
};

CapabilityItem.defaultProps = {
  iconSize: 'medium',
  layout: 'vertical',
  titleTypographyProps: {
    variant: 'h5',
    component: 'h3',
  },
  hover: false,
  featured: false,
} as Partial<CapabilityItemProps>;

export default CapabilityItem;
