import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
// material
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import { Box, IconButton, Typography } from '@mui/material';
import {
  ChevronLeftRounded as ArrowLeftIcon,
  ChevronRightRounded as ArrowRightIcon,
} from '@mui/icons-material';
// components
import { ChangeableImage } from 'components';
import { Brush2 as CategoryIcon } from 'components/CustomIcon';
// types
import { CarouselProps } from 'types/carousel';
//
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------

export interface HeroCarouselProps {
  className?: string;
  items: CarouselProps['items'];
}

const PREFIX = 'HeroCarousel';
const classes = {
  root: `${PREFIX}-root`,
  carouselWrapper: `${PREFIX}-carouselWrapper`,
  item: `${PREFIX}-item`,
  image: `${PREFIX}-image`,
  navigation: `${PREFIX}-navigation`,
  category: `${PREFIX}-category`,
  navigationButton: `${PREFIX}-navigationButton`,
  title: `${PREFIX}-title`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const HeroCarousel = (props: HeroCarouselProps) => {
  const { className: classNameProp, items } = props;
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const carouselRef = useRef<Slider | null>(null);
  const [title, setTitle] = useState<string | false>(items[0]?.title || false);
  const [category, setCategory] = useState<string | false>(items[0]?.category || false);

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: Boolean(theme.direction !== 'rtl'),
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (currentSlide: number, nextSlide: number) => {
      setTitle(items[nextSlide]?.title || false);
      setCategory(items[nextSlide]?.category || false);
    },
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      <Slider
        ref={carouselRef}
        {...settings}
        lazyLoad="ondemand"
        className={classes.carouselWrapper}
      >
        {items.map((item, index) => (
          <Box key={index} className={classes.item}>
            <ChangeableImage
              className={classes.image}
              imageName={item.name || 'Image'}
              heroImg={item.image}
              alt={item?.alt || item.title || ''}
              title={item?.imageTitle || item.title}
            />
          </Box>
        ))}
      </Slider>

      {category && (
        <div className={classes.category}>
          <CategoryIcon />
          <Typography variant="subtitle2" component="h3" color="textPrimary">
            {category}
          </Typography>
        </div>
      )}

      <div className={classes.navigation}>
        <IconButton
          size="small"
          aria-label="prevSlide"
          className={classes.navigationButton}
          onClick={handlePrevious}
        >
          <>{isRTL ? <ArrowRightIcon /> : <ArrowLeftIcon />}</>
        </IconButton>

        {title && (
          <Box className={classes.title}>
            <Typography variant="h6" component="h4" align="center">
              {title}
            </Typography>
          </Box>
        )}

        <IconButton
          size="small"
          aria-label="nextSlide"
          className={classes.navigationButton}
          onClick={handleNext}
        >
          <>{isRTL ? <ArrowLeftIcon /> : <ArrowRightIcon />}</>
        </IconButton>
      </div>
    </StyledRoot>
  );
};

export default HeroCarousel;
