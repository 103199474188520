import React from 'react';
// components
import { ChangeableImage } from 'components';
// types
import { SimpleImageAttributesType } from 'types/image';

export interface HeroProps extends SimpleImageAttributesType {
  heroImg: string;
  alt: string;
  title?: string;
  imageName: string;
  className?: string;
}

const Hero = ({ heroImg, imageName, className, alt, title }: HeroProps) => (
  <ChangeableImage
    imageName={imageName}
    className={className}
    heroImg={heroImg}
    alt={alt}
    title={title}
  />
);

export default Hero;
