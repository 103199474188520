import { Theme } from '@mui/material/styles';
import { CSSInterpolation } from '@mui/styled-engine';
import { TextMDXRendererProps } from './TextMDXRenderer';

export const rootStyles = (
  theme: Theme,
  ownerState: Partial<TextMDXRendererProps>,
): CSSInterpolation => {
  const { breakpoints, palette, shape, spacing, transitions, typography } = theme;
  const { anchors } = ownerState;

  return {
    '&': {
      '& > *': {
        marginTop: 0,
        marginBottom: spacing(3),
        [breakpoints.up('md')]: {
          marginBottom: spacing(4),
        },
      },
      '& h1': {
        ...typography.h2,
      },
      '& h2': {
        ...typography.h3,
      },
      '& h3': {
        ...typography.h4,
      },
      '& h4': {
        ...typography.h5,
      },
      '& h5': {
        ...typography.h6,
      },
      '& h6': {
        ...typography.body1,
        fontWeight: typography.fontWeightBold,
      },
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        marginTop: spacing(3),
        [breakpoints.up('md')]: {
          marginTop: spacing(4),
        },
        '&:first-of-type': {
          marginTop: 0,
        },
      },
      '& p': {
        ...typography.body1,
      },
      '& small': {
        fontSize: '80%',
      },
      '& ul, & ol': {
        padding: `0 0 0 ${spacing(5)}`,
        [breakpoints.up('md')]: {
          paddingLeft: spacing(6),
        },
        '& li': {
          padding: 0,
          position: 'relative',
          listStyle: 'none',
          marginBottom: spacing(1.5),
          [breakpoints.up('md')]: {
            marginBottom: spacing(2.25),
          },
          ...typography.body1,
        },
        '& ul, & ol': {
          marginBottom: spacing(1.5),
          [breakpoints.up('md')]: {
            marginBottom: spacing(2.25),
          },
        },
      },
      '& ul': {
        '& > li:before': {
          content: '""',
          position: 'absolute',
          left: spacing(-3.5),
          top: spacing(1.25),
          width: spacing(1),
          height: spacing(1),
          borderRadius: '50%',
          backgroundColor: palette.primary.main,
        },
      },
      '& ol': {
        counterReset: 'listitem',
        '& > li:before': {
          counterIncrement: 'listitem',
          content: "counters(listitem, '.') '.'",
          position: 'absolute',
          left: spacing(-3.5),
          color: palette.text.disabled,
          fontWeight: typography.fontWeightBold,
        },
      },
      '& ul > li > ul': {
        marginTop: spacing(1.5),
        [breakpoints.up('md')]: {
          marginTop: spacing(2.25),
        },
        '& > li': {
          '&:before': {
            background: palette.text.disabled,
            '&:after': {
              content: '""',
              position: 'absolute',
              left: spacing(-7.5),
              top: spacing(1),
              width: spacing(2),
              height: spacing(2),
              backgroundColor: palette.background.default,
            },
          },
        },
      },
      '& blockquote, & cite': {
        margin: spacing(0, 2, 0, 5),
        ...typography.body2,
        color: palette.text.secondary,
        [breakpoints.up('md')]: {
          margin: spacing(0, 3, 0, 6),
        },
        '& p': {
          ...typography.body2,
        },
      },
      '& .gatsby-resp-image-wrapper': {
        maxWidth: '100% !important',
        borderRadius: shape.borderRadiusMd,
        overflow: 'hidden',
      },
      '& > *:last-child': {
        marginBottom: 0,
      },

      ...(anchors && {
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          display: 'flex',
          alignItems: 'center',
          '& .anchor': {
            display: 'flex',
            margin: spacing(-0.5, -0.25),
            marginRight: spacing(0.75),
            padding: spacing(0.25),
            borderRadius: spacing(0.5),
            color: palette.primary.main,
            transition: transitions.create(['background', 'color'], {
              duration: transitions.duration.shorter,
            }),
            '& svg': {
              width: 16,
              height: 16,
              fill: 'currentColor',
            },
            '&:hover, &:focus': {
              color: palette.primary.dark,
              background: palette.action.hover,
            },
          },
        },
      }),

      ...(!anchors && {
        '& .anchor': {
          display: 'none !important',
        },
      }),
    },
  };
};
