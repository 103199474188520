import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { palette, spacing, breakpoints } = theme;
  return {
    '&': {
      '& br': {
        display: 'none',
        [breakpoints.up('md')]: {
          display: 'block',
        },
      },
      [`& .${classes.content}`]: { padding: 0 },
      [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: spacing(2.5),
        top: spacing(2.5),
        color: palette.grey[500],
        background: palette.background.paper,
        zIndex: 2,
        borderRadius: spacing(1.5),
        fontSize: 10,
        '&:hover': {
          backgroundColor: alpha(palette.primary.main, (palette.tonalOffset as number) * 0.75),
          color: palette.primary.main,
          '@media (hover: none)': {
            backgroundColor: palette.action.hover,
            color: palette.text.primary,
          },
        },

        [breakpoints.up('md')]: {
          right: spacing(4),
          top: spacing(4),
          background: palette.background.default,
        },
      },
      [`& .${classes.side}`]: {
        [breakpoints.up('md')]: {
          minHeight: '480px',
          height: '80vh',
          maxHeight: '728px',
        },
      },
      [`& .${classes.leftSide}`]: {
        padding: spacing(4),
        position: 'relative',
        overflow: 'hidden',
        background: palette.background.default,

        [breakpoints.up('md')]: {
          padding: spacing(8),
        },
      },
      [`& .${classes.rightSide}`]: {
        padding: spacing(4),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [breakpoints.up('md')]: {
          padding: spacing(8),
        },
      },
      [`& .${classes.image}`]: {
        position: 'absolute',
        pointerEvents: 'none',
      },
      [`& .${classes.rightTopLineImage}`]: {
        right: 0,
        top: 0,
      },
      [`& .${classes.rightBottomLineImage}`]: {
        right: '-170px',
        bottom: '-120px',

        [breakpoints.up('md')]: {
          right: '-120px',
          bottom: '-180px',
          zIndex: 1,
        },
      },
      [`& .${classes.coin}`]: {
        right: '-30px',
        bottom: '72px',
        '@media (max-height: 720px)': {
          transform: 'scale(0.6)',
          right: '-100px',
        },
        '@media (max-height: 520px)': {
          display: 'none',
        },
      },
      [`& .${classes.leftBox}`]: {
        position: 'relative',
        zIndex: 3,
        paddingRight: '20%',
        [breakpoints.up('md')]: {
          paddingTop: spacing(8),
          paddingRight: '0%',

          '@media (max-height: 720px)': {
            paddingTop: spacing(2),
          },
        },
      },
      [`& .${classes.bottomInfo}`]: {
        position: 'relative',
        marginTop: spacing(4),
        zIndex: 3,
        [breakpoints.up('md')]: {
          position: 'absolute',
          left: spacing(8),
          bottom: spacing(6),
        },
      },
      [`& .${classes.redLineImage}`]: {
        left: 0,
        transform: 'translateX(-55%)',
        top: '16%',
      },
      [`& .${classes.pinkCoin}`]: {
        right: 'calc(100% - 30px)',
        bottom: '252px',
        margin: 'auto',
        width: '106px',
        zIndex: 2,
        '@media (max-height: 720px)': {
          display: 'none',
        },
      },
      [`& .${classes.nativeForms}`]: {
        '& .nf-submit-button-container': {
          '& .nf-submit-button': {
            width: 'auto',
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            minWidth: '100%',
            [breakpoints.up('sm')]: {
              minWidth: '50%',
            },
            [breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
        },
      },
    },
  };
};
