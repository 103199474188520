import * as React from 'react';
import clsx from 'clsx';
// gatsby
import { Link } from 'gatsby';
// hooks
import { useOffSetTop, useLocales, usePath } from 'hooks';
import { useLocation } from '@reach/router';
// material
import { styled } from '@mui/material/styles';
import { AppBar, Box, Container, Toolbar, Grid } from '@mui/material';
// components
import { HeaderDesktopMenu, HeaderMobileMenu, Logo } from 'components';
// types
import { ExtendedMainMenuItem } from 'types/main-menu-item';
//
import { stylesRoot } from './styles';

interface HeaderProps {
  className?: string;
}

const PREFIX = 'Header';
const classes = {
  toolbar: `${PREFIX}-toolbar`,
  smallToolbar: `${PREFIX}-smallToolbar`,
  links: `${PREFIX}-links`,
  mobileLinks: `${PREFIX}-mobileLinks`,
  desktopLinks: `${PREFIX}-desktopLinks`,
  toolbarShadow: `${PREFIX}-toolbarShadow`,
  toolbarShadowVisible: `${PREFIX}-toolbarShadowVisible`,
};

const StyledRoot = styled(AppBar, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Header = (props: HeaderProps) => {
  const { className: classNameProp } = props;
  const { pathname } = useLocation();
  const { defaultLang, pathLang, translate: t } = useLocales();
  const { findPagePathWithSlug, allMarkdown, findPagePath } = usePath();
  const isOffset = useOffSetTop(1);

  const siteList = allMarkdown.edges
    .filter(
      (site: any) =>
        site.node.slug.split('.').pop() === pathLang && site.node.frontmatter.navigation,
    )
    .sort(
      (a: any, b: any) => Number(a.node.slug.split('.')[0]) - Number(b.node.slug.split('.')[0]),
    );
  const items: ExtendedMainMenuItem[] = siteList.map((site: any) => {
    const link = findPagePathWithSlug(site.node.frontmatter.slug, site.node.frontmatter.title);
    const linkNoSlashes = link.replace(/\/$/, ``);
    const pathnameNoSlashes = pathname.replace(/\/$/, ``);

    return {
      key: site.node.frontmatter.slug,
      to: link !== '/' ? link : '',
      active: pathnameNoSlashes === linkNoSlashes || pathnameNoSlashes.includes(linkNoSlashes),
      label: site.node.frontmatter.title,
    };
  });

  if (process.env.NODE_ENV === 'development') {
    items.push({
      to: '/styleguide/',
      active: pathname === '/styleguide/',
      label: 'Style Guide',
    });
  }

  const additionalItems: ExtendedMainMenuItem[] = [
    {
      to: findPagePath('product'),
      active: pathname === findPagePath('product'),
      label: t('common.tokenize'),
      variant: 'outlined',
    },
  ];

  return (
    <StyledRoot color="transparent" sx={{ boxShadow: 0 }} classes={{ root: clsx(classNameProp) }}>
      <Toolbar
        disableGutters
        classes={{ root: clsx(classes.toolbar, isOffset && classes.smallToolbar) }}
      >
        <Container>
          <Grid container spacing={5} alignItems="center" wrap="nowrap">
            <Grid item xs="auto">
              <Link
                to={
                  pathname === `/`
                    ? `/`
                    : `/${defaultLang?.value === pathLang ? '' : `${pathLang}/`}`
                }
              >
                <Logo />
              </Link>
            </Grid>

            <Grid item xs>
              <Box className={clsx(classes.links, classes.desktopLinks)}>
                <HeaderDesktopMenu items={items} additionalItems={additionalItems} />
              </Box>
              <Box className={clsx(classes.links, classes.mobileLinks)}>
                <HeaderMobileMenu items={items} additionalItems={additionalItems} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>

      <div className={clsx(classes.toolbarShadow, isOffset && classes.toolbarShadowVisible)} />
    </StyledRoot>
  );
};

export default Header;
