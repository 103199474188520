export default function ImageList() {
  return {
    MuiImageList: {
      styleOverrides: {},
    },

    MuiImageListItem: {
      styleOverrides: {
        standard: {
          '& .MuiImageListItem-img': {
            margin: 'auto 0',
            flexGrow: 'unset',
          },
        },
        img: {},
      },
    },
  };
}
