import { Theme } from '@mui/material/styles';
import { CSSInterpolation } from '@mui/styled-engine';
import { BoxWithHeaderProps } from './BoxWithHeader';

export const rootStyles = (
  theme: Theme,
  ownerState: Partial<BoxWithHeaderProps>,
): CSSInterpolation => {
  const { textAlign } = ownerState;

  return {
    textAlign,
  };
};

export const headingSpaceStyles = (
  theme: Theme,
  ownerState: Partial<BoxWithHeaderProps>,
): CSSInterpolation => {
  const { spacing, breakpoints } = theme;
  const { spaceHeader, children } = ownerState;

  return {
    '&': {
      ...(children && {
        marginBottom: spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        '& > *:not(:last-child)': {
          marginBottom: spacing(0.5),
        },
        ...(spaceHeader === 'mini' && {}),
        ...(spaceHeader === 'small' && {
          [breakpoints.up('sm')]: {
            marginBottom: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(3),
          },
        }),
        ...(spaceHeader === 'medium' && {
          [breakpoints.up('sm')]: {
            marginBottom: spacing(3),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(4),
          },
        }),
        ...(spaceHeader === 'large' && {
          marginBottom: spacing(4),
          [breakpoints.up('sm')]: {
            marginBottom: spacing(5.5),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(7),
          },
        }),
      }),
    },
  };
};
