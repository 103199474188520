import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, spacing, typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      },
      [`& .${classes.header}`]: { flexDirection: 'column' },
      [`& .${classes.headerAvatar}`]: {
        marginRight: 0,
        marginTop: spacing(2),
        marginBottom: spacing(2),
        [breakpoints.up('lg')]: {
          marginTop: spacing(3),
        },
        '& .MuiAvatar-root': {
          width: spacing(10),
          height: spacing(10),
          fontSize: spacing(5),
        },
      },
      [`& .${classes.headerContent}`]: { textAlign: 'center' },
      [`& .${classes.headerSubheader}`]: { fontWeight: typography.fontWeightRegular },
      [`& .${classes.avatar}`]: { backgroundColor: palette.background.default },
      [`& .${classes.cardContent}`]: { display: 'flex', alignItems: 'center', flex: 1 },
    },
  };
};
