import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => ({
  '&': {
    [`&.${classes.text}`]: {},
    [`&.${classes.featured}`]: {},
    [`&.${classes.startIcon}`]: {},
    [`&.${classes.endIcon}`]: {},
  },
});

export const stylesListItem = (theme: Theme, classes: any) => {
  const { breakpoints, palette, spacing, typography } = theme;
  return {
    '&': {
      [`&.${classes.normalItem}`]: {
        padding: 0,
        '& > *': {
          fontFamily: typography.h6.fontFamily,
          fontSize: typography.h5.fontSize,
          fontWeight: typography.fontWeightMedium,
          padding: spacing(2.5, 2),
          lineHeight: 1.35,
          color: palette.text.secondary,
          [breakpoints.up('sm')]: {
            fontSize: typography.h5.fontSize,
            padding: spacing(2.5, 3),
          },
        },

        [`&.${classes.active}`]: {
          '& > *': {
            color: palette.primary.dark,
          },
          // fontWeight: typography.fontWeightBold,
        },
        [`&.${classes.normalItemDivider}`]: {
          border: 'none',
          '&:not(:last-child)': {
            '&:after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: 0,
              left: spacing(2),
              right: spacing(2),
              height: 1,
              backgroundColor: palette.action.selected,
              [breakpoints.up('sm')]: {
                left: spacing(3),
                right: spacing(3),
              },
            },
          },
        },
      },
    },
  };
};
