import * as React from 'react';
import { Theme } from '@mui/material/styles';

import {
  RadioInputBlank as RadioIcon,
  RadioInput as RadioCheckedIcon,
} from 'components/CustomIcon';

// ----------------------------------------------------------------------

export default function Radio({ palette, spacing }: Theme) {
  return {
    MuiRadio: {
      defaultProps: {
        color: 'primary',
        icon: <RadioIcon />,
        checkedIcon: <RadioCheckedIcon />,
      },

      styleOverrides: {
        root: {
          padding: spacing(0.4),
          color: palette.grey[300],
          '&:not(.Mui-checked):hover': {
            color: palette.grey[400],
            '@media (hover: none)': {
              color: palette.grey[300],
            },
          },
        },
      },
    },
  };
}
