import { Theme } from '@mui/material/styles';
import { CSSInterpolation } from '@mui/styled-engine';
import { avatarWithTextClasses, AvatarWithTextProps } from '.';

export const rootStyles: CSSInterpolation = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const contentStyles = (theme: Theme): CSSInterpolation => {
  const { spacing } = theme;
  return {
    '&': {
      paddingLeft: spacing(1.25),
    },
  };
};

export const textStyles = (
  theme: Theme,
  ownerState: Partial<AvatarWithTextProps>,
): CSSInterpolation => {
  const { typography, spacing } = theme;
  const { title } = ownerState;
  return {
    display: 'block',
    lineHeight: 1.3,
    fontFamily: typography.h1.fontFamily,

    ...(title && {
      [`&.${avatarWithTextClasses.title}`]: {
        fontWeight: typography.fontWeightMedium,
      },
      [`&.${avatarWithTextClasses.textGutter}`]: {
        marginBottom: spacing(0.25),
      },
    }),
  };
};
