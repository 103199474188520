import * as React from 'react';
import clsx from 'clsx';
// gatsby
import { Link } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
import { Button, ListItemIcon, ListItemText, ListItem } from '@mui/material';
// types
import { ExtendedMainMenuItem } from 'types/main-menu-item';
//
import { stylesRoot, stylesListItem } from './styles';

export interface HeaderMobileMenuItemAllProps extends ExtendedMainMenuItem {
  className?: string;
}
type ExtraProps = {
  component: React.ElementType;
  to?: string;
  button?: boolean | undefined;
};

const PREFIX = 'HeaderDesktopMenuItem';
const classes = {
  text: `${PREFIX}-text`,
  featured: `${PREFIX}-featured`,
  startIcon: `${PREFIX}-startIcon`,
  endIcon: `${PREFIX}-endIcon`,
  normalItem: `${PREFIX}-normalItem`,
  active: `${PREFIX}-active`,
  normalItemDivider: `${PREFIX}-normalItemDivider`,
};

const StyledRoot = styled(Button, { name: PREFIX, slot: 'root' })<ExtraProps>(({ theme }) =>
  stylesRoot(theme, classes),
);

const StyledListItem = styled(ListItem, { name: PREFIX, slot: 'listItem' })(({ theme }) =>
  stylesListItem(theme, classes),
);

const HeaderMobileMenuItem = (props: HeaderMobileMenuItemAllProps) => {
  const {
    className: classNameProp,
    label,
    isLink,
    to,
    onClick,
    variant,
    active,
    startIcon,
    endIcon,
  } = props;

  if (variant === 'text') {
    return (
      <StyledListItem
        divider
        classes={{
          root: clsx(classes.normalItem, active && classes.active),
          divider: classes.normalItemDivider,
        }}
      >
        <ListItem
          button
          component={isLink ? Link : 'button'}
          onClick={onClick}
          to={isLink ? to : undefined}
        >
          {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
          <ListItemText disableTypography>{label}</ListItemText>
          {endIcon && endIcon}
        </ListItem>
      </StyledListItem>
    );
  }

  return (
    <StyledRoot
      fullWidth
      component={isLink ? Link : 'button'}
      onClick={onClick}
      to={isLink ? to : undefined}
      size="large"
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      classes={{
        root: clsx(classNameProp),
        contained: classes.featured,
        outlined: classes.featured,
        startIcon: classes.startIcon,
        endIcon: classes.endIcon,
      }}
    >
      {label}
    </StyledRoot>
  );
};

HeaderMobileMenuItem.defaultProps = {
  variant: 'text',
  isLink: true,
  active: false,
} as Partial<HeaderMobileMenuItemAllProps>;

export default HeaderMobileMenuItem;
