import React from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Typography, TypographyProps } from '@mui/material';
// types
import { GatsbyImage } from 'gatsby-plugin-image';
import { SimpleChildImageSharpType } from 'types/image';
//
import { stylesRoot, stylesContent, stylesTitle, stylesImage } from './styles';

export interface FeaturedBoxProps {
  className?: string;
  titleTypographyProps?: TypographyProps;
  descriptionTypographyProps?: TypographyProps;
  textAlign?: TypographyProps['align'];
  title?: string;
  description?: string;
  image?: SimpleChildImageSharpType;
}

const PREFIX = 'FeaturedBox';
const classes = {
  image: `${PREFIX}-image`,
};
const StyledRoot = styled(Card, { name: PREFIX, slot: 'root' })(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      image: FeaturedBoxProps['image'];
    };
  }) => stylesRoot(theme, ownerState, classes),
);
const StyledContent = styled(CardContent, { name: PREFIX, slot: 'content' })(() => stylesContent());
const StyledTitle = styled(Typography, { name: PREFIX, slot: 'title' })(({ theme }) =>
  stylesTitle(theme),
);
const StyledImage = styled('div', { name: PREFIX, slot: 'image' })(({ theme }) =>
  stylesImage(theme),
);

const FeaturedBox = (props: FeaturedBoxProps) => {
  const {
    className: classNameProp,
    title,
    titleTypographyProps,
    description,
    descriptionTypographyProps,
    image,
    textAlign,
  } = props;
  const theme = useTheme();

  return (
    <StyledRoot
      theme={theme}
      ownerState={{ image }}
      variant="outlined"
      sx={{ textAlign }}
      className={clsx(classNameProp)}
    >
      {image && (
        <CardHeader
          title={
            <StyledImage className={classes.image}>
              <GatsbyImage
                image={image?.childImageSharp.gatsbyImageData}
                alt={title || ''}
                title={title}
              />
            </StyledImage>
          }
        />
      )}

      <StyledContent>
        {title && (
          <StyledTitle {...titleTypographyProps} gutterBottom={!!description}>
            {title}
          </StyledTitle>
        )}
        {description && <Typography {...descriptionTypographyProps}>{description}</Typography>}
      </StyledContent>
    </StyledRoot>
  );
};

FeaturedBox.defaultProps = {
  titleTypographyProps: {
    variant: 'h5',
    component: 'h3',
  },
  descriptionTypographyProps: {
    variant: 'body1',
    color: 'textSecondary',
  },
  textAlign: 'center',
} as Partial<FeaturedBoxProps>;

export default FeaturedBox;
