import { Theme } from '@mui/material/styles';
import { MixinsOptions } from '@mui/material/styles/createMixins';
import custom from './custom';

export const mixins = (theme: Theme) => ({
  toolbar: {
    minHeight: custom.headerMobileNavHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: custom.headerMobileNavHeight,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: custom.headerNavHeight,
    },
  },
});

export default mixins as MixinsOptions;
