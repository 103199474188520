import * as React from 'react';
// material
import { alpha, Theme } from '@mui/system';
import { ColorSchema } from 'types/theme';
import {
  CheckCircleRounded as SuccessIcon,
  ErrorRounded as ErrorIcon,
  InfoRounded as InfoIcon,
  WarningRounded as WarningIcon,
} from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function Alert(theme: Theme) {
  const standardStyle = (color: ColorSchema) => ({
    color: theme.palette[color].main,
    backgroundColor: alpha(theme.palette[color].main, 0.2),
  });

  const filledStyle = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
  });

  const outlinedStyle = (color: ColorSchema) => ({
    color: theme.palette[color].main,
    border: `solid 1px ${theme.palette[color].main}`,
    backgroundColor: 'transparent',
  });

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          warning: <WarningIcon />,
          info: <InfoIcon />,
        },
      },

      styleOverrides: {
        root: {
          '& .MuiAlert-icon': {
            color: 'inherit',
          },
        },
        message: {
          '& .MuiAlertTitle-root': {
            marginBottom: theme.spacing(0.5),
          },
        },
        action: {
          '& button:not(:first-of-type)': {
            marginLeft: theme.spacing(1),
          },
        },

        standardInfo: standardStyle('info'),
        standardSuccess: standardStyle('success'),
        standardWarning: standardStyle('warning'),
        standardError: standardStyle('error'),

        filledInfo: filledStyle('info'),
        filledSuccess: filledStyle('success'),
        filledWarning: filledStyle('warning'),
        filledError: filledStyle('error'),

        outlinedInfo: outlinedStyle('info'),
        outlinedSuccess: outlinedStyle('success'),
        outlinedWarning: outlinedStyle('warning'),
        outlinedError: outlinedStyle('error'),
      },
    },
  };
}
