import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="M10.75 4.64L6.31997 2.45C3.92997 1.28 1.96997 2.47 1.96997 5.09V19.93C1.96997 21.07 2.91997 22 4.07997 22H11.5C12.05 22 12.5 21.55 12.5 21V7.41C12.5 6.36 11.71 5.11 10.75 4.64ZM8.96997 13.75H5.49997C5.08997 13.75 4.74997 13.41 4.74997 13C4.74997 12.59 5.08997 12.25 5.49997 12.25H8.96997C9.37997 12.25 9.71997 12.59 9.71997 13C9.71997 13.41 9.38997 13.75 8.96997 13.75ZM8.96997 9.75H5.49997C5.08997 9.75 4.74997 9.41 4.74997 9C4.74997 8.59 5.08997 8.25 5.49997 8.25H8.96997C9.37997 8.25 9.71997 8.59 9.71997 9C9.71997 9.41 9.38997 9.75 8.96997 9.75Z" />
    <path d="M22 18.04V19.5C22 20.88 20.88 22 19.5 22H14.97C14.43 22 14 21.57 14 21.03V18.87C15.07 19 16.2 18.69 17.01 18.04C17.69 18.59 18.56 18.92 19.51 18.92C20.44 18.92 21.31 18.59 22 18.04Z" />
    <path d="M22 15.05V15.06C21.92 16.37 20.85 17.42 19.51 17.42C18.12 17.42 17.01 16.29 17.01 14.92C17.01 16.45 15.6 17.68 14 17.37V12C14 11.36 14.59 10.88 15.22 11.02L17.01 11.42L17.49 11.53L19.53 11.99C20.02 12.09 20.47 12.26 20.86 12.51C20.86 12.52 20.87 12.52 20.87 12.52C20.97 12.59 21.07 12.67 21.16 12.76C21.62 13.22 21.92 13.89 21.99 14.87C21.99 14.93 22 14.99 22 15.05Z" />
  </>,
  'Building',
);
