import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';
// hooks
import { useCookies } from 'react-cookie';
// languages config
import LANGS from '../../config/locales';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { pathname } = useLocation();
  const [cookies, setCookie] = useCookies(['language']);

  const defaultLang = Object.values(LANGS).find((val) => val.default);
  const pathLang = Object.keys(LANGS).includes(pathname.split('/')[1].toLowerCase())
    ? pathname.split('/')[1].toLowerCase()
    : defaultLang?.value || 'en';
  const langStorage =
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem('i18nextLng')
      : undefined;
  const langFromStorage = langStorage ? langStorage.split('-')[0].toLowerCase() : undefined;
  const cookieLang = cookies.language ? cookies.language.toLowerCase() : undefined;
  const initialLangFromPath =
    pathname.split('/')[1].toLowerCase() || defaultLang?.value || undefined;
  const langFromPath =
    initialLangFromPath !== undefined && Object.keys(LANGS).includes(initialLangFromPath)
      ? initialLangFromPath
      : defaultLang?.value || undefined;

  const initialCurrentLang = Object.values(LANGS).find((val) => val.value === langFromStorage);
  const currentLang = initialCurrentLang !== undefined ? initialCurrentLang : LANGS.en;

  const handleChangeLanguageAndSetCookie = useCallback(
    (newLang: string) => {
      const dateNow = new Date();
      const year = dateNow.getFullYear();
      const month = dateNow.getMonth();
      const day = dateNow.getDate();
      const expDate = new Date(year + 1, month, day);

      i18n.changeLanguage(newLang).then(() => {
        setCookie('language', newLang, {
          path: '/',
          sameSite: 'strict',
          expires: expDate,
        });
      });
    },
    [i18n, setCookie],
  );

  useMemo(() => {
    if (currentLang.value !== langFromStorage || i18n.language.length > 2) {
      i18n.changeLanguage(currentLang.value).then(() => true);
    }

    if (cookieLang !== undefined && langFromPath === undefined) {
      if (cookieLang !== langFromStorage) {
        handleChangeLanguageAndSetCookie(cookieLang);
      }
    } else if (langFromPath !== undefined) {
      if (langFromPath !== langFromStorage) {
        handleChangeLanguageAndSetCookie(langFromPath);
      }
    }
  }, [
    cookieLang,
    currentLang.value,
    handleChangeLanguageAndSetCookie,
    i18n,
    langFromPath,
    langFromStorage,
  ]);

  return {
    setLanguage: handleChangeLanguageAndSetCookie,
    translate,
    currentLang,
    defaultLang,
    pathLang,
    allLang: LANGS,
  };
}
