import { Theme } from '@mui/material';

export const stylesFooter = (theme: Theme) => {
  const { spacing } = theme;
  return {
    '&': {
      paddingTop: spacing(8),
      paddingBottom: spacing(8),
    },
  };
};

export const stylesFooterTop = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      paddingBottom: spacing(1),
      [breakpoints.up('md')]: {
        paddingBottom: spacing(2),
      },
    },
  };
};

export const stylesFooterBottom = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      paddingTop: spacing(2),
      [breakpoints.up('md')]: {
        paddingTop: spacing(3),
      },
    },
  };
};

export const stylesItemBox = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      marginBottom: spacing(3),
      [breakpoints.up('md')]: {
        marginBottom: spacing(4),
      },
    },
  };
};

export const stylesHeading = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      marginBottom: spacing(1),
      [breakpoints.up('md')]: {
        marginBottom: spacing(3),
      },
    },
  };
};

export const stylesShowText = (theme: Theme) => {
  const { palette } = theme;
  return {
    '&': {
      color: palette.grey[500],
      cursor: 'pointer',
    },
  };
};

export const stylesBox = () => ({
  '&': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const stylesEmailBox = (theme: Theme) => {
  const { palette, typography, spacing } = theme;
  return {
    '&': {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      padding: spacing(0, 0, 1, 0),
      '& a': {
        fontWeight: typography.fontWeightBold,
        fontSize: typography.body2.fontSize,
        color: palette.grey[700],
        '&:hover': {
          color: palette.primary.lighter,
        },
      },
      '& svg': {
        display: 'flex',
        color: palette.grey[400],
        margin: spacing(1),
        cursor: 'pointer',
      },
    },
  };
};

export const stylesUl = (theme: Theme) => {
  const { palette, spacing } = theme;
  return {
    '&': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      '& a': {
        display: 'block',
        margin: spacing(0.5, 0),
        padding: spacing(0.5, 0),
        color: palette.grey[700],
        '&:hover': {
          color: palette.primary.lighter,
        },
      },
    },
  };
};

export const stylesCopy = (theme: Theme) => {
  const { palette, typography, breakpoints } = theme;
  return {
    '&': {
      color: palette.grey[500],
      fontWeight: typography.fontWeightMedium,

      [breakpoints.up('md')]: {
        textAlign: 'center',
      },
      '& strong': {
        color: palette.grey[800],
      },
    },
  };
};

export const stylesGridCopy = (theme: Theme) => {
  const { breakpoints } = theme;
  return {
    '&': {
      order: 3,
      textAlign: 'center',
      [breakpoints.up('md')]: {
        order: 2,
        textAlign: 'left',
      },
    },
  };
};

export const stylesGridLang = (theme: Theme) => {
  const { breakpoints } = theme;
  return {
    '&': {
      order: 2,
      [breakpoints.up('md')]: {
        order: 3,
      },
    },
  };
};
