import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export const componentName = `IceoAvatarWithText`;

export interface AvatarWithTextClasses {
  root: string;
  content: string;
  title: string;
  textGutter: string;
  subtitle: string;
}

export type AvatarWithTextClassKey = keyof AvatarWithTextClasses;

export function getItemUtilityClass(slot: string): string {
  return generateUtilityClass(componentName, slot);
}

const avatarWithTextClasses: AvatarWithTextClasses = generateUtilityClasses(componentName, [
  'root',
  'content',
  'title',
  'textGutter',
  'subtitle',
]);

export default avatarWithTextClasses;
