import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="M6 2H18C19.1 2 20 2.9 20 4V7.32C20 7.87 19.55 8.32 19 8.32H5C4.45 8.32 4 7.87 4 7.32V4C4 2.9 4.9 2 6 2Z" />
    <path d="M4 10.32V11.88C4 12.96 4.58 13.96 5.53 14.49L8.49 16.16C9.12 16.51 9.51 17.18 9.51 17.9V20C9.51 21.1 10.41 22 11.51 22H12.51C13.61 22 14.51 21.1 14.51 20V17.9C14.51 17.18 14.9 16.51 15.53 16.16L18.49 14.49C19.43 13.96 20.02 12.96 20.02 11.88V10.32C20.02 9.77001 19.57 9.32001 19.02 9.32001H5C4.45 9.32001 4 9.76001 4 10.32Z" />
  </>,
  'Brush',
);
