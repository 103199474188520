import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';
import { ColorSchema } from 'types/theme';
import { LabelProps } from './Label';

export const stylesRoot = (theme: Theme, ownerState: Partial<LabelProps>) => {
  const { spacing, typography, palette } = theme;
  const { color, variant } = ownerState;

  const styleFilled = (color: ColorSchema) => ({
    color: palette[color].contrastText,
    backgroundColor: palette[color].main,
  });

  const styleOutlined = (color: ColorSchema) => ({
    color: palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${palette[color].main}`,
  });

  const styleGhost = (color: ColorSchema) => ({
    color: palette[color],
    backgroundColor: alpha(palette[color].main, 0.16),
  });

  return {
    '&': {
      height: 22,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: spacing(1),
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: spacing(0, 1),
      fontSize: typography.caption.fontSize,
      fontWeight: typography.fontWeightBold,
      ...(color === 'default' && {}),
      ...(color === 'primary' && {}),
      ...(color === 'secondary' && {}),
      ...(color === 'info' && {}),
      ...(color === 'success' && {}),
      ...(color === 'warning' && {}),
      ...(color === 'warning' && {}),
      ...(color === 'error' && {}),
      ...(variant === 'filled' && {
        ...(color === 'default' && {
          color: palette.text.secondary,
          backgroundColor: palette.grey[300],
        }),
        ...(color === 'primary' && {
          ...styleFilled('primary'),
        }),
        ...(color === 'secondary' && {
          ...styleFilled('secondary'),
        }),
        ...(color === 'info' && {
          ...styleFilled('info'),
        }),
        ...(color === 'success' && {
          ...styleFilled('success'),
        }),
        ...(color === 'warning' && {
          ...styleFilled('warning'),
        }),
        ...(color === 'warning' && {
          ...styleFilled('warning'),
        }),
        ...(color === 'error' && {
          ...styleFilled('error'),
        }),
      }),
      ...(variant === 'outlined' && {
        ...(color === 'default' && {
          backgroundColor: 'transparent',
          color: palette.text.primary,
          border: `1px solid ${palette.grey[500_32]}`,
        }),
        ...(color === 'primary' && {
          ...styleOutlined('primary'),
        }),
        ...(color === 'secondary' && {
          ...styleOutlined('secondary'),
        }),
        ...(color === 'info' && {
          ...styleOutlined('info'),
        }),
        ...(color === 'success' && {
          ...styleOutlined('success'),
        }),
        ...(color === 'warning' && {
          ...styleOutlined('warning'),
        }),
        ...(color === 'warning' && {
          ...styleOutlined('warning'),
        }),
        ...(color === 'error' && {
          ...styleOutlined('error'),
        }),
      }),
      ...(variant === 'ghost' && {
        ...(color === 'default' && {
          color: palette.text.secondary,
          backgroundColor: palette.grey[500_16],
        }),
        ...(color === 'primary' && {
          ...styleGhost('primary'),
        }),
        ...(color === 'secondary' && {
          ...styleGhost('secondary'),
        }),
        ...(color === 'info' && {
          ...styleGhost('info'),
        }),
        ...(color === 'success' && {
          ...styleGhost('success'),
        }),
        ...(color === 'warning' && {
          ...styleGhost('warning'),
        }),
        ...(color === 'warning' && {
          ...styleGhost('warning'),
        }),
        ...(color === 'error' && {
          ...styleGhost('error'),
        }),
      }),
    },
  };
};
