import { alpha } from '@mui/system';

// ----------------------------------------------------------------------

type gradientColor = {
  color?: string;
  stop?: string | number;
};

function createGradient(
  color1: gradientColor,
  color2: gradientColor,
  color3?: gradientColor,
  color4?: gradientColor,
) {
  if (color3 && color4) {
    return `linear-gradient(to right, ${color1.color} ${color1.stop}, ${color2.color} ${color2.stop}, ${color3.color} ${color3.stop}, ${color4.color} ${color4.stop})`;
  }
  if (color3) {
    return `linear-gradient(to right, ${color1.color} ${color1.stop}, ${color2.color} ${color2.stop}, ${color3.color} ${color3.stop})`;
  }
  return `linear-gradient(to right, ${color1.color} ${color1.stop}, ${color2.color} ${color2.stop})`;
}

interface GradientsPaletteOptions {
  primary: string;
  button: string;
  buttonSecondary: string;
  text: string;
  textSecondary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    primary: string;
    secondary: string;
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
  }
  interface PaletteOptions {
    gradients?: GradientsPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const INFO = {
  lighter: '#ADE1FF',
  light: '#82cbff',
  main: '#2CB2FF',
  dark: '#1D98E0',
  darker: '#0077BB',
};
const SUCCESS = {
  lighter: '#CEFFF3',
  light: '#97EED2',
  main: '#00DCA7',
  dark: '#00B488',
  darker: '#007A5C',
};
const WARNING = {
  lighter: '#EAC799',
  light: '#EDAC5F',
  main: '#F18B38',
  dark: '#DA7628',
  darker: '#C05C09',
};
const ERROR = {
  lighter: '#FFA7D1',
  light: '#FF7AAD',
  main: '#FF007A',
  dark: '#CB0063',
  darker: '#7A0043',
};

const PRIMARY = {
  lighter: '#7C3AED',
  light: '#6D28D9',
  main: '#5B21B6',
  dark: '#4B1A96',
  darker: '#34116A',
};
const SECONDARY = ERROR;

const GREY = {
  100: '#F1F5F9',
  200: '#E2E8F0',
  300: '#CBD5E1',
  400: '#94A3B8',
  500: '#64748B',
  600: '#475569',
  700: '#334155',
  800: '#1E293B',
  900: '#0F172A',
  500_8: alpha('#64748B', 0.08),
  500_12: alpha('#64748B', 0.12),
  500_16: alpha('#64748B', 0.16),
  500_24: alpha('#64748B', 0.24),
  500_32: alpha('#64748B', 0.32),
  500_48: alpha('#64748B', 0.48),
  500_56: alpha('#64748B', 0.56),
  500_80: alpha('#64748B', 0.8),
};
const GRADIENTS = {
  primary: createGradient(
    { color: '#FB878A', stop: 0 },
    { color: '#7630E8', stop: '50%' },
    { color: '#0CEDD9', stop: '100%' },
  ),
  secondary: createGradient(
    { color: '#D5EAA8', stop: 0 },
    { color: '#FFBCBE', stop: '33.3%' },
    { color: '#6A7EEF', stop: '66.6%' },
    { color: '#00FDE7', stop: '100%' },
  ),
  button: createGradient(
    { color: '#FB878A', stop: '-50%' },
    { color: '#7630E8', stop: '47.65%' },
    { color: '#0CEDD9', stop: '150%' },
  ),
  buttonSecondary: createGradient(
    { color: '#0CEDD9', stop: '-50%' },
    { color: '#7630E8', stop: '47.65%' },
    { color: '#FB878A', stop: '150%' },
  ),
  text: createGradient(
    { color: '#6666cc', stop: '-50%' },
    { color: '#3366cc', stop: '47.65%' },
    { color: '#3399cc', stop: '150%' },
  ),
  textSecondary: createGradient(
    { color: '#FB878A', stop: '-150%' },
    { color: '#7630E8', stop: '150%' },
    { color: '#0CEDD9', stop: '150.1%' },
  ),
  info: createGradient({ color: INFO.light, stop: 0 }, { color: INFO.main, stop: '100%' }),
  success: createGradient({ color: SUCCESS.light, stop: 0 }, { color: SUCCESS.main, stop: '100%' }),
  warning: createGradient({ color: WARNING.light, stop: 0 }, { color: WARNING.main, stop: '100%' }),
  error: createGradient({ color: ERROR.light, stop: 0 }, { color: ERROR.main, stop: '100%' }),
};
const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: '#fff' },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[200],
  link: PRIMARY.main,
  icon: GREY[500],
  tonalOffset: 0.175,
  action: {
    active: GREY[400],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_48],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  ...COMMON,
  text: { primary: GREY[900], secondary: GREY[500], disabled: GREY[300] },
  background: {
    paper: '#FFF',
    default: '#F3F5F7',
    primary: alpha(PRIMARY.main, 0.05),
    secondary: alpha(SECONDARY.main, 0.05),
    neutral: 'transparent',
  },
};

export default palette;
