import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Card({ breakpoints, palette, shape, spacing }: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          borderRadius: shape.borderRadiusMd,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
      styleOverrides: {
        root: {
          paddingTop: spacing(3),
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
          paddingBottom: 0,
          [breakpoints.up('lg')]: {
            paddingTop: spacing(4),
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            paddingBottom: 0,
            '& + [class^="MuiCardContent-root"], & + [class*="MuiCardContent-root"]': {
              marginTop: spacing(-1),
            },
          },
        },
        action: {
          marginTop: 0,
          marginRight: 0,
          alignSelf: 'center',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: spacing(3),
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
          paddingBottom: spacing(3),
          '&:last-child': {
            paddingBottom: spacing(3),
          },
          [breakpoints.up('lg')]: {
            paddingTop: spacing(4),
            paddingLeft: spacing(4),
            paddingRight: spacing(4),
            paddingBottom: spacing(4),
            '&:last-child': {
              paddingBottom: spacing(4),
            },
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          position: 'relative',
          padding: spacing(3),
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: spacing(3),
            right: spacing(3),
            top: 0,
            height: 1,
            background: palette.divider,
          },
          [breakpoints.up('lg')]: {
            padding: spacing(4),
            '&:before': {
              left: spacing(4),
              right: spacing(4),
            },
          },
        },
      },
    },
  };
}
