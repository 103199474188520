import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function IconButton({ palette }: Theme) {
  return {
    MuiIconButton: {
      variants: [],

      styleOverrides: {
        root: {
          fontSize: 24,
          padding: 12,
          '&:hover': {
            backgroundColor: palette.action.hover,
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
            },
          },
        },
        sizeSmall: {
          padding: 8,
          fontSize: 20,
        },
      },
    },
  };
}
