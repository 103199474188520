import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export const componentName = `IceoTextMDXRenderer`;

export interface TextMDXRendererClasses {
  root: string;
  anchors: string;
  noAnchors: string;
}

export type TextMDXRendererClassKey = keyof TextMDXRendererClasses;

export function getItemUtilityClass(slot: string): string {
  return generateUtilityClass(componentName, slot);
}

const textMDXRendererClasses: TextMDXRendererClasses = generateUtilityClasses(componentName, [
  'root',
  'anchors',
  'noAnchors',
]);

export default textMDXRendererClasses;
