import { Theme } from '@mui/material/styles';
import { ContentBoxProps } from './ContentBox';

export const stylesRoot = (
  theme: Theme,
  classes: any,
  ownerState: {
    background: ContentBoxProps['background'];
    showBefore: ContentBoxProps['showBefore'];
  },
) => {
  const { palette, breakpoints, custom } = theme;
  const { background, showBefore } = ownerState;

  return {
    [`&.${classes.root}`]: {
      '&:first-of-type:before': {
        content: '""',
        display: 'block',
        visibility: 'hidden',
        width: '100%',
        height: custom.headerMobileNavHeight,
        [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
          height: custom.headerMobileNavHeight,
        },
        [breakpoints.up('md')]: {
          height: custom.headerNavHeight,
        },
        ...(!showBefore && { display: 'none' }),
      },
      ...(background === 'default' && { background: palette.background.default }),
      ...(background === 'paper' && { background: palette.background.paper }),
      ...(background === 'primary' && { background: palette.background.primary }),
      ...(background === 'secondary' && { background: palette.background.secondary }),
      ...(background === 'transparent' && { background: 'transparent' }),
    },
  };
};

export const stylesContent = (
  theme: Theme,
  classes: any,
  ownerState: {
    spaceSize: ContentBoxProps['spaceSize'];
    spaceTop: ContentBoxProps['spaceTop'];
    spaceBottom: ContentBoxProps['spaceBottom'];
    horizontalPadding: ContentBoxProps['horizontalPadding'];
  },
) => {
  const { spacing, breakpoints } = theme;
  const { spaceSize, spaceTop, spaceBottom, horizontalPadding } = ownerState;

  return {
    [`&.${classes.content}`]: {
      [`& > .${classes.root}:before`]: {
        display: 'none !important',
      },
      ...(spaceSize === 'small' && {
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
        },
      }),
      ...(spaceSize === 'medium' && {
        paddingTop: spacing(3),
        paddingBottom: spacing(3),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(4.5),
          paddingBottom: spacing(4.5),
        },
        [breakpoints.up('lg')]: {
          paddingTop: spacing(6),
          paddingBottom: spacing(6),
        },
      }),
      ...(spaceSize === 'large' && {
        paddingTop: spacing(5),
        paddingBottom: spacing(5),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(7.5),
          paddingBottom: spacing(7.5),
        },
        [breakpoints.up('lg')]: {
          paddingTop: spacing(10),
          paddingBottom: spacing(10),
        },
      }),
      ...(spaceSize === 'extraLarge' && {
        paddingTop: spacing(7.5),
        paddingBottom: spacing(7.5),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(11.25),
          paddingBottom: spacing(11.25),
        },
        [breakpoints.up('lg')]: {
          paddingTop: spacing(15),
          paddingBottom: spacing(15),
        },
      }),
      ...(spaceSize === 'huge' && {
        paddingTop: spacing(10),
        paddingBottom: spacing(10),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(15),
          paddingBottom: spacing(15),
        },
        [breakpoints.up('lg')]: {
          paddingTop: spacing(20),
          paddingBottom: spacing(20),
        },
      }),
      ...(spaceSize === 'extraHuge' && {
        paddingTop: spacing(22),
        paddingBottom: spacing(22),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(26),
          paddingBottom: spacing(26),
        },
        [breakpoints.up('lg')]: {
          paddingTop: spacing(30),
          paddingBottom: spacing(30),
        },
      }),
      ...(!spaceTop && {
        paddingTop: '0px !important',
        '&:first-of-type:before': {
          display: 'none !important',
        },
      }),
      ...(!spaceBottom && {
        paddingBottom: '0px !important',
      }),
      ...(!horizontalPadding && {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      }),
    },
  };
};
