import React from 'react';
import clsx from 'clsx';
// gatsby
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled, Theme } from '@mui/material/styles';
import {
  Avatar,
  AvatarProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { LinkedIn as LinkedInIcon } from '@mui/icons-material';
// types
import { SxProps } from '@mui/system';
import { GatsbyLinkProps } from 'gatsby-link';
import { ChildImageSharpType } from 'types/image';
//
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------
export type SocialLink = {
  title: string;
  href: GatsbyLinkProps<any>['to'];
};

export interface UserCardProps {
  avatarProps?: AvatarProps;
  sx?: SxProps<Theme>;
  title?: string;
  subtitle?: string;
  description?: string;
  image?: ChildImageSharpType;
  socials?: SocialLink[];
}

export interface UserCardAllProps extends UserCardProps {
  className?: string;
}

const PREFIX = 'UserCard';
const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  headerAvatar: `${PREFIX}-headerAvatar`,
  headerContent: `${PREFIX}-headerContent`,
  headerSubheader: `${PREFIX}-headerSubheader`,
  avatar: `${PREFIX}-avatar`,
  cardContent: `${PREFIX}-cardContent`,
};

const StyledRoot = styled(Card, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const UserCard = (props: UserCardAllProps) => {
  const {
    className: classNameProp,
    avatarProps,
    sx,
    title,
    subtitle,
    description,
    image,
    socials,
  } = props;

  return (
    <StyledRoot variant="outlined" classes={{ root: clsx(classes.root, classNameProp) }} sx={sx}>
      <CardHeader
        title={title}
        subheader={subtitle}
        avatar={
          <Avatar {...avatarProps} className={classes.avatar}>
            {image && (
              <GatsbyImage
                image={image.src.childImageSharp.gatsbyImageData}
                alt={image.alt}
                title={image?.title}
              />
            )}
          </Avatar>
        }
        titleTypographyProps={{
          variant: 'h4',
        }}
        subheaderTypographyProps={{
          variant: 'h6',
          component: 'span',
          color: 'textSecondary',
        }}
        classes={{
          root: classes.header,
          avatar: classes.headerAvatar,
          content: classes.headerContent,
          subheader: classes.headerSubheader,
        }}
      />
      {description && (
        <CardContent classes={{ root: classes.cardContent }}>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      )}
      {socials && (
        <CardActions>
          <List sx={{ width: '100%' }} disablePadding>
            {socials.map((item: SocialLink, index: number) => (
              <ListItem
                key={index}
                disableGutters
                secondaryAction={
                  <IconButton
                    size="small"
                    edge="end"
                    component={GatsbyLink}
                    to={item.href}
                    target="_blank"
                  >
                    {item.title === 'LinkedIn' && <LinkedInIcon />}
                  </IconButton>
                }
              >
                <ListItemText primaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}>
                  {item.title}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardActions>
      )}
    </StyledRoot>
  );
};

UserCard.defaultProps = {} as Partial<UserCardAllProps>;

export default UserCard;
