import * as React from 'react';
import { Theme } from '@mui/material/styles';

import {
  CheckBoxBlank as CheckboxIcon,
  CheckBox as CheckboxCheckedIcon,
  CheckBoxIndeterminate as CheckboxIndeterminateIcon,
} from 'components/CustomIcon';

export default function Checkbox({ palette, spacing }: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          marginRight: spacing(1),
          padding: spacing(0.4),
          color: palette.grey[300],
          '&:not(.Mui-checked):hover': {
            color: palette.grey[400],
            '@media (hover: none)': {
              color: palette.grey[300],
            },
          },
        },
      },
    },
  };
}
