import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Box, IconButton, Tooltip, Typography, TypographyProps } from '@mui/material';
// hooks
import { useLocales } from 'hooks';
// helpers && types
import SocialLinks, { SocialLink } from 'helpers/socialLinks';
//
import { stylesRoot } from './styles';

interface StayInContactProps {
  headerVariant?: TypographyProps['variant'];
  headerColor?: TypographyProps['color'];
  textAlign?: TypographyProps['align'];
  showHeader?: boolean;
}

export interface StayInContactAllProps extends StayInContactProps {
  className?: string;
}

const PREFIX = 'StayInContact';
const classes = {
  root: `${PREFIX}-root`,
  socials: `${PREFIX}-socials`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const StayInContact = (props: StayInContactAllProps) => {
  const { className: classNameProp, headerVariant, headerColor, textAlign, showHeader } = props;
  const { translate: t } = useLocales();

  return (
    <StyledRoot classes={{ root: clsx(classes.root, classNameProp) }} sx={{ textAlign }}>
      {showHeader && (
        <Typography variant={headerVariant} color={headerColor}>
          {t('footer.titleContact')}
        </Typography>
      )}

      <div className={classes.socials}>
        {SocialLinks.map((item: SocialLink, index: number) => (
          <Tooltip title={item.label} key={index}>
            <IconButton size="small" href={item.url} rel="noreferrer" target="_blank">
              {item.icon}
            </IconButton>
          </Tooltip>
        ))}
      </div>
    </StyledRoot>
  );
};

StayInContact.defaultProps = {
  headerVariant: 'h6',
  headerColor: 'textPrimary',
  textAlign: 'left',
  showHeader: true,
} as Partial<StayInContactAllProps>;

export default StayInContact;
