import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="M21.81 3.93997C20.5 7.20997 17.51 11.48 14.66 14.27C14.25 11.69 12.19 9.66997 9.59003 9.30997C12.39 6.44997 16.69 3.41997 19.97 2.09997C20.55 1.87997 21.13 2.04997 21.49 2.40997C21.87 2.78997 22.05 3.35997 21.81 3.93997Z" />
    <path d="M13.78 15.09C13.58 15.26 13.38 15.43 13.18 15.59L11.39 17.02C11.39 16.99 11.38 16.95 11.38 16.91C11.24 15.84 10.74 14.85 9.93 14.04C9.11 13.22 8.09 12.72 6.97 12.58C6.94 12.58 6.9 12.57 6.87 12.57L8.31999 10.74C8.45999 10.56 8.61 10.39 8.77 10.21L9.45 10.3C11.6 10.6 13.33 12.29 13.67 14.43L13.78 15.09Z" />
    <path d="M10.43 17.6199C10.43 18.7199 10.01 19.7699 9.21001 20.5599C8.60001 21.1799 7.78001 21.5999 6.78001 21.7199L4.33001 21.9899C2.99001 22.1399 1.84001 20.9899 1.99001 19.6399L2.26001 17.1799C2.50001 14.9899 4.33001 13.5899 6.27001 13.5499C6.46001 13.5399 6.67001 13.5499 6.87001 13.5699C7.72001 13.6799 8.54001 14.0699 9.23001 14.7499C9.90001 15.4199 10.28 16.2099 10.39 17.0399C10.41 17.2399 10.43 17.4299 10.43 17.6199Z" />
  </>,
  'Brush2',
);
