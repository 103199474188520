import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Typography({ palette, spacing }: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {},
        paragraph: {
          marginBottom: spacing(2),
        },
        gutterBottom: {
          marginBottom: spacing(1),
        },
        subtitle1: {
          color: palette.text.secondary,
        },
        subtitle2: {
          color: palette.text.secondary,
        },
      },
    },
  };
}
