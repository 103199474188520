module.exports = {
  en: {
    value: 'en',
    label: 'English',
    localeLabel: 'en_EN',
    icon: '/static/icons/ic_flag_en.svg',
    default: true, // the default language must be in the first place in the object
  },
  pl: {
    value: 'pl',
    label: 'Polski',
    localeLabel: 'pl_PL',
    icon: '/static/icons/ic_flag_pl.svg',
  },
};
