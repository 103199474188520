import { alpha } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme) => {
  const { spacing, transitions, direction, palette, breakpoints, shape } = theme;
  return {
    '&': {
      display: 'block',
      borderRadius: shape.borderRadiusLg,
      padding: spacing(1),
      margin: spacing(-1),
      width: `calc(100% + ${spacing(2)})`,
      height: `calc(100% + ${spacing(2)})`,
      textAlign: direction === 'rtl' ? 'right' : 'left',
      color: alpha(palette.primary.main, (palette.tonalOffset as number) * 2),
      transition: transitions.create(['background'], {
        duration: transitions.duration.shorter,
      }),
      '&:hover, &:focus': {
        background: palette.action.hover,
        '& $title': {
          color: palette.primary.main,
        },
      },
      [breakpoints.up('sm')]: {
        padding: spacing(1.5),
        margin: spacing(-1.5),
        width: `calc(100% + ${spacing(3)})`,
        height: `calc(100% + ${spacing(3)})`,
      },
    },
  };
};

export const styleRootInner = () => ({
  '&': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const stylesImage = (theme: Theme) => {
  const { palette, breakpoints, shape } = theme;
  return {
    '&': {
      pointerEvents: 'none',
      width: '100%',
      height: 190,
      minHeight: 190,
      position: 'relative',
      overflow: 'hidden',
      borderRadius: shape.borderRadiusMd,
      backgroundColor: palette.background.paper,
      [breakpoints.up('sm')]: {
        height: 212,
        minHeight: 212,
      },
    },
  };
};

export const stylesTitle = (theme: Theme) => {
  const { palette, transitions, spacing } = theme;
  return {
    '&': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingTop: spacing(3),
      color: palette.text.primary,
      transition: transitions.create(['color'], {
        duration: transitions.duration.shorter,
      }),
    },
  };
};

export const stylesFooter = (theme: Theme) => {
  const { spacing } = theme;
  return {
    '&': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      paddingTop: spacing(2),
      '& > *:first-of-type': {
        flex: 1,
      },
    },
  };
};

export const stylesTime = (theme: Theme) => {
  const { spacing, typography, palette } = theme;
  return {
    '&': {
      paddingLeft: spacing(2),
      display: 'flex',
      alignItems: 'center',
      '& span': {
        fontFamily: typography.h1.fontFamily,
      },
      '& svg': {
        fontSize: 16,
        marginRight: spacing(1),
        color: palette.text.disabled,
      },
    },
  };
};
