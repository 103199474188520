import React, { useEffect } from 'react';
import { useDencrypt } from 'use-dencrypt-effect';
// material
import { styled } from '@mui/material/styles';
// components
import { TextGradient } from 'components';
//
import { stylesRoot } from './styles';

interface Options {
  chars?: string[];
  interval: number;
}
interface ChangeWordProps {
  words?: string[];
  timer: number;
  smallScreen?: boolean;
  options: Options;
}

const PREFIX = 'ChangeWord';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled('div', { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const ChangeWord = (props: ChangeWordProps) => {
  const { words, timer, options, smallScreen } = props;
  const { result, dencrypt } = useDencrypt(options);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (words?.length) {
      let i = 0;
      dencrypt(words[i]);

      const action = setInterval(() => {
        dencrypt(words[i]);

        i = i === words.length - 1 ? 0 : i + 1;
      }, timer);

      return () => clearInterval(action);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRoot className={classes.root}>
      <TextGradient
        gradientLine="buttonSecondary"
        gradientText="textSecondary"
        strokeWidth={smallScreen ? 2.4 : 3.5}
      >
        {result}
      </TextGradient>
    </StyledRoot>
  );
};

export default ChangeWord;
