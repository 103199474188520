import React from 'react';
import clsx from 'clsx';
// gatsby
import { Link as GatsbyLink } from 'gatsby';
// material
import { styled, Theme } from '@mui/material/styles';
import { ListItemText, Typography, ButtonBase } from '@mui/material';
// components
import { AvatarWithText } from 'components';
// types
import { SxProps } from '@mui/system';
import { GatsbyLinkProps } from 'gatsby-link';
import { ChildImageSharpType } from 'types/image';
//
import { stylesRoot, stylesContent, stylesDate } from './styles';

// ----------------------------------------------------------------------

export interface BlogPreviewListItemProps {
  className?: string;
  sx?: SxProps<Theme>;
  title?: string;
  author?: {
    name?: string;
    image?: ChildImageSharpType;
  };
  date?: string;
  to: GatsbyLinkProps<any>['to'];
}

const PREFIX = 'BlogPreviewListItem';
const classesItem = {
  authorImage: `${PREFIX}-authorImage`,
  title: `${PREFIX}-title`,
  footer: `${PREFIX}-footer`,
};
type ExtraProps = {
  component: React.ElementType;
  to: string;
};
const StyledRoot = styled(ButtonBase, { name: PREFIX, slot: 'root' })<ExtraProps>(({ theme }) =>
  stylesRoot(theme, classesItem),
);
const StyledContent = styled(ListItemText, { name: PREFIX, slot: 'content' })(({ theme }) =>
  stylesContent(theme, classesItem),
);
const StyledDate = styled(Typography, { name: PREFIX, slot: 'date' })(({ theme }) =>
  stylesDate(theme),
);

const BlogPreviewListItem = (props: BlogPreviewListItemProps) => {
  const { className: classNameProp, sx, title, author, date, to } = props;

  return (
    <StyledRoot className={clsx(classNameProp)} sx={sx} component={GatsbyLink} to={to}>
      <StyledContent
        primary={title}
        secondary={
          <>
            {author && (
              <AvatarWithText
                title={author?.name}
                image={author?.image}
                className={classesItem.authorImage}
              />
            )}

            {date && (
              <StyledDate variant="caption" color="textSecondary" component="div">
                {date}
              </StyledDate>
            )}
          </>
        }
        primaryTypographyProps={{
          variant: 'subtitle2',
          component: 'h5',
          className: classesItem.title,
        }}
        secondaryTypographyProps={{
          component: 'div',
          className: classesItem.footer,
        }}
      />
    </StyledRoot>
  );
};

BlogPreviewListItem.defaultProps = {} as Partial<BlogPreviewListItemProps>;

export default BlogPreviewListItem;
