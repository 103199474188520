import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="M 12.000001,4.0000002 A 8,8 0 0 0 4.0000004,12.000001 8,8 0 0 0 12.000001,20 8,8 0 0 0 20,12.000001 8,8 0 0 0 12.000001,4.0000002 Z m 0,1 A 7,7 0 0 1 19,12.000001 7,7 0 0 1 12.000001,19 7,7 0 0 1 5.0000004,12.000001 7,7 0 0 1 12.000001,5.0000002 Z" />
    <path d="M 17,12 A 5,5 0 0 1 12,17 5,5 0 0 1 6.9999998,12 5,5 0 0 1 12,7 a 5,5 0 0 1 5,5 z" />
  </>,
  'RadioInput',
);
