import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Drawer(theme: Theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        modal: {
          zIndex: theme.zIndex.modal,
        },
      },
    },
  };
}
