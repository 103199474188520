import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  // eslint-disable-next-line no-empty-pattern
  const {} = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {},
    },
  };
};
