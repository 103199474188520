import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
// components
import { NativeFormsComponent } from 'components';
// types
import { NativeFormsComponentProps } from 'components/NativeFormsComponent/NativeFormsComponent';
//
import { stylesRoot } from './styles';

interface NewsletterProps extends NativeFormsComponentProps {}

interface NewsletterAllProps extends NewsletterProps {
  className?: string;
}

const PREFIX = 'Newsletter';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(NativeFormsComponent, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Newsletter = (props: NewsletterAllProps) => {
  const {
    className: classNameProp,
    form,
    formId,
    formIdAddon,
    formIdInputSelector,
    onLoad,
    onSend,
  } = props;

  return (
    <StyledRoot
      form={form}
      formId={formId}
      formIdAddon={formIdAddon}
      formIdInputSelector={formIdInputSelector}
      className={clsx(classes.root, classNameProp)}
      onLoad={onLoad}
      onSend={onSend}
    />
  );
};

Newsletter.defaultProps = {
  formIdInputSelector: '#formId',
} as Partial<NewsletterAllProps>;

export default Newsletter;
