import { Theme } from '@mui/material/styles';

export const stylesCookiesWrap = (theme: Theme, classes: any) => {
  const { spacing, palette, transitions, breakpoints } = theme;
  return {
    '&': {
      boxShadow: `0 0 0 1px ${palette.grey[100]}`,
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      maxHeight: 600,
      position: 'relative',
      transition: transitions.create(['max-height', 'opacity'], {
        duration: transitions.duration.complex,
      }),
      transitionDelay: `${transitions.duration.complex * 0.5}ms, ${
        transitions.duration.complex * 0.35
      }ms`,
      [breakpoints.up('sm')]: {
        paddingTop: spacing(3),
        paddingBottom: spacing(3),
      },
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '50%',
        height: '100%',
        zIndex: 1,
        backgroundColor: palette.grey[100],
        opacity: 0,
        transition: transitions.create(['all'], {
          duration: transitions.duration.shorter,
        }),
      },
      '&:before': {
        left: '-50%',
      },
      '&:after': {
        right: '-50%',
      },
      [`&.${classes.disabled}`]: {
        maxHeight: 0,
        opacity: 0,
        '&:before': {
          left: 0,
          opacity: 1,
        },
        '&:after': {
          right: 0,
          opacity: 1,
        },
      },
    },
  };
};

export const stylesIcon = (theme: Theme) => {
  const { spacing, palette, breakpoints } = theme;
  return {
    '&': {
      fontSize: spacing(4),
      color: palette.text.disabled,
      display: 'block',
      [breakpoints.up('sm')]: {
        fontSize: spacing(5),
      },
    },
  };
};

export const stylesAcceptButton = (theme: Theme) => {
  const { spacing, breakpoints } = theme;
  return {
    '&': {
      [breakpoints.up('sm')]: {
        marginLeft: spacing(2),
      },
    },
  };
};
