import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      [`& .${classes.socials}`]: {
        marginTop: spacing(1),
        '& > *': {
          margin: spacing(0, 0.5),
          '&:first-of-type': {
            marginLeft: spacing(-0.5),
          },
          '&:last-child': {
            marginRight: spacing(-0.5),
          },
        },
      },
    },
  };
};
