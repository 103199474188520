import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        overflow: 'hidden',
        position: 'relative',
        '&:before, &:after': {
          top: 0,
          left: 0,
          zIndex: 8,
          width: 48,
          content: "''",
          height: '100%',
          display: 'none',
          position: 'absolute',
          backgroundImage:
            'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
          [breakpoints.up(480)]: {
            display: 'block',
          },
        },
        '&:after': {
          right: 0,
          left: 'auto',
          transform: 'scaleX(-1)',
        },
        '& .slick-slider': {
          '& .slick-arrow': {
            display: 'none',
          },
          '& .slick-list': {
            display: 'flex',
            '& .slick-track': {
              display: 'flex',
              [breakpoints.up(1200)]: {
                justifyContent: 'center',
                transform: 'none !important',
              },
              '& .slick-slide': {
                padding: 32,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',

                '&:first-of-type': {
                  '&:before': {
                    display: 'none',
                  },
                  '& button': {
                    '&:before': {
                      display: 'none',
                    },
                  },
                },
                '&:last-child': {
                  '&:after': {
                    display: 'none',
                  },
                  '& button': {
                    '&:after': {
                      display: 'none',
                    },
                  },
                },
                '&:before, &:after': {
                  left: 0,
                  zIndex: 8,
                  width: 16,
                  content: "''",
                  height: 2,
                  position: 'absolute',
                  background: palette.background.default,
                },
                '&:before': {
                  left: 0,
                  right: 'auto',
                },
                '&:after': {
                  right: 0,
                  left: 'auto',
                },
                '& button, & a': {
                  display: 'flex !important',
                  background: palette.background.default,
                  color: palette.text.primary,
                  cursor: 'default',
                  position: 'relative',
                  whiteSpace: 'nowrap',

                  '&:before, &:after': {
                    left: 0,
                    zIndex: 8,
                    width: 6,
                    content: "''",
                    height: 6,
                    position: 'absolute',
                    background: palette.background.default,
                    transform: 'rotate(45deg)',
                  },
                  '&:before': {
                    left: -20,
                    right: 'auto',
                  },
                  '&:after': {
                    right: -20,
                    left: 'auto',
                  },

                  '& svg': {
                    fill: palette.primary.main,
                    width: 18,
                    height: 18,
                  },

                  '& img': {
                    marginRight: 10,
                    width: 18,
                    height: 18,
                  },
                },
                '& a': {
                  cursor: 'pointer',
                },
              },
            },
          },
        },
      },
    },
  };
};
