import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
// components
import { ContentBox } from 'components';
// types
import { ContentBoxProps } from 'components/ContentBox/ContentBox';
import { CarouselProps } from 'types/carousel';
//
import { ContainerProps } from '@mui/material';
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------

export interface HeroSectionProps {
  classesChildren?: any;
  children?: ReactNode;
  background?: ContentBoxProps['background'];
  spaceTop?: ContentBoxProps['spaceTop'];
  spaceBottom?: ContentBoxProps['spaceBottom'];
  maxWidth?: ContainerProps['maxWidth'];
}

export interface HeroSectionAllProps extends HeroSectionProps {
  className?: string;
}

export interface HeroCarouselProps {
  className?: string;
  items: CarouselProps['items'];
}

const PREFIX = 'HeroSection';
const classes = {
  content: `${PREFIX}-content`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const HeroSection = (props: HeroSectionAllProps) => {
  const {
    className: classNameProp,
    classesChildren,
    children,
    background,
    spaceTop,
    spaceBottom,
    maxWidth,
  } = props;

  return (
    <StyledRoot
      className={clsx(classNameProp, classesChildren?.root)}
      classesChildren={{
        root: classesChildren?.root,
        content: clsx(classes.content, classesChildren?.content),
      }}
      spaceTop={spaceTop}
      spaceBottom={spaceBottom}
      background={background}
      maxWidth={maxWidth}
    >
      {children}
    </StyledRoot>
  );
};

HeroSection.defaultProps = {
  background: 'paper',
  spaceTop: false,
  spaceBottom: true,
  maxWidth: 'lg',
} as Partial<HeroSectionAllProps>;

export default HeroSection;
