import { alpha, lighten } from '@mui/system';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button({ palette, spacing, transitions, typography }: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        // disableFocusRipple: true,
        // disableRipple: true,
        variant: 'contained',
        color: 'primary',
      },

      styleOverrides: {
        root: {
          borderRadius: spacing(3),
          fontSize: typography.button.fontSize,
          minWidth: 'auto',
          minHeight: 48,
          padding: '10px 24px',
          textTransform: typography.button.textTransform,
          lineHeight: '1.3',
          fontWeight: typography.fontWeightBold,
          transition: transitions.create(
            ['background', 'border', 'box-shadow', 'color', 'opacity'],
            {
              duration: transitions.duration.short,
            },
          ),
        },
        text: {},
        textPrimary: {},
        contained: {},
        containedInherit: {
          backgroundColor: palette.grey[300],
          '&:hover': {
            backgroundColor: palette.grey[200],
          },
          '&:focus, &:active': {
            backgroundColor: palette.grey[300],
            boxShadow: `0 0 0 2px ${alpha(palette.grey[300], palette.tonalOffset as number)}`,
          },
        },
        containedPrimary: {
          backgroundColor: palette.primary.dark,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
          '&:focus, &:active': {
            backgroundColor: palette.primary.dark,
            boxShadow: `0 0 0 2px ${alpha(palette.primary.main, palette.tonalOffset as number)}`,
          },
        },
        containedSecondary: {
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: lighten(palette.secondary.main, palette.tonalOffset as number),
          },
          '&:focus, &:active': {
            backgroundColor: palette.secondary.main,
            boxShadow: `0 0 0 2px ${alpha(palette.secondary.main, palette.tonalOffset as number)}`,
          },
        },
        containedSuccess: {
          backgroundColor: palette.success.main,
          '&:hover': {
            backgroundColor: lighten(palette.success.main, palette.tonalOffset as number),
          },
          '&:focus, &:active': {
            backgroundColor: palette.success.main,
            boxShadow: `0 0 0 2px ${alpha(palette.success.main, palette.tonalOffset as number)}`,
          },
        },
        containedError: {
          backgroundColor: palette.error.main,
          '&:hover': {
            backgroundColor: lighten(palette.error.main, palette.tonalOffset as number),
          },
          '&:focus, &:active': {
            backgroundColor: palette.error.main,
            boxShadow: `0 0 0 2px ${alpha(palette.error.main, palette.tonalOffset as number)}`,
          },
        },
        containedInfo: {
          backgroundColor: palette.info.main,
          '&:hover': {
            backgroundColor: lighten(palette.info.main, palette.tonalOffset as number),
          },
          '&:focus, &:active': {
            backgroundColor: palette.info.main,
            boxShadow: `0 0 0 2px ${alpha(palette.info.main, palette.tonalOffset as number)}`,
          },
        },
        containedWarning: {
          backgroundColor: palette.warning.main,
          '&:hover': {
            backgroundColor: lighten(palette.warning.main, palette.tonalOffset as number),
          },
          '&:focus, &:active': {
            backgroundColor: palette.warning.main,
            boxShadow: `0 0 0 2px ${alpha(palette.warning.main, palette.tonalOffset as number)}`,
          },
        },
        outlined: {
          border: '2px solid transparent',
          backgroundImage: `linear-gradient(${palette.common.white}, ${palette.common.white}), ${palette.gradients.button}`,
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          padding: '8px 22px',
          transition: transitions.create(['box-shadow', 'opacity'], {
            duration: transitions.duration.short,
          }),
          '&:hover': {
            borderWidth: 2,
            backgroundImage: 'none',
          },
          '&.Mui-disabled': {
            borderWidth: 2,
            background: 'transparent',
          },
          '&.MuiButton-outlinedPrimary, &.MuiButton-outlinedInherit': {
            '& svg': {
              fill: 'url(#button-gradient-fill)',
            },
            '&:hover, &.Mui-focusVisible': {
              border: '2px solid transparent',
              backgroundImage: `${palette.gradients.button}, ${palette.gradients.button}`,
              color: palette.primary.contrastText,
              '& svg': {
                fill: palette.primary.contrastText,
              },
            },
          },
          '&.MuiButton-sizeSmall': {
            padding: '6px 16px',
          },
          '&.MuiButton-sizeLarge': {
            padding: '10px 28px',
          },
        },
        sizeSmall: {
          padding: '8px 18px',
          minHeight: 44,
          fontSize: typography.subtitle2.fontSize,
          minWidth: 'auto',
        },
        sizeLarge: {
          borderRadius: spacing(3.5),
          padding: '12px 30px',
          minHeight: 56,
          minWidth: 'auto',
        },
        startIcon: {
          marginRight: 12,
          marginTop: -1,
          marginBottom: -1,
          marginLeft: -2,
          // '&$iconSizeSmall': {
          //   marginLeft: 0,
          // },
        },
        endIcon: {
          display: 'inherit',
          marginTop: -1,
          marginBottom: -1,
          marginRight: -2,
          marginLeft: 12,
          // '&$iconSizeSmall': {
          //   marginRight: 0,
          // },
        },
        iconSizeSmall: {
          // '&$startIcon': {
          //   marginRight: 8,
          //   '& > *:nth-of-type(1)': {
          //     fontSize: 20,
          //   },
          // },
          // '&$endIcon': {
          //   marginLeft: 8,
          // },
        },
        iconSizeMedium: {
          // '&$startIcon': {
          //   '& > *:nth-of-type(1)': {
          //     fontSize: 24,
          //   },
          // },
        },
        iconSizeLarge: {
          // '&$startIcon': {
          //   '& > *:nth-of-type(1)': {
          //     fontSize: 24,
          //   },
          // },
        },
      },
    },
  };
}
