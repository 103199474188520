import React from 'react';
import clsx from 'clsx';
import slugify from 'slugify';
import { useSnackbar } from 'notistack';
// gatsby
import { navigate } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, NoSsr, TypographyProps } from '@mui/material';
// hooks
import { useLocales, usePath } from 'hooks';
import { useLocation } from '@reach/router';
//
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------

interface LangSwitcherProps {
  textAlign?: TypographyProps['align'];
}

interface LangSwitcherAllProps extends LangSwitcherProps {
  className?: string;
}

const PREFIX = 'LangSwitcher';
const classes = {
  button: `${PREFIX}-button`,
  buttonActive: `${PREFIX}-buttonActive`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const LangSwitcher = (props: LangSwitcherAllProps) => {
  const { allLang, currentLang, translate: t } = useLocales();
  const { allMarkdown, allSitePage } = usePath();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { className: classNameProp, textAlign } = props;

  const showInfo = () => {
    enqueueSnackbar(t('common.pageNotFound'), {
      variant: 'info',
    });
  };

  const changeLink = (e: any, lng: any) => {
    e.preventDefault();
    const url = pathname.replace(/\/$/, ``).split('/').pop();

    const activeSite = allMarkdown.edges.filter(
      (item: any) =>
        item.node.frontmatter.slug === url ||
        slugify(item.node.frontmatter.title, {
          replacement: '-',
          lower: true,
        }) === url,
    );
    const activeLang = currentLang.value;
    const targetLangObj = Object.values(allLang).find((val) => val.value === lng);
    const targetLang = targetLangObj?.value || lng;

    if (!activeSite.length) {
      if (targetLangObj?.default) {
        navigate(`/`);
      } else {
        navigate(`/${lng}/`);
      }

      return;
    }
    const matchSiteArray = allMarkdown.edges.filter(
      (item: any) =>
        item.node.parent.relativeDirectory === activeSite[0].node.parent.relativeDirectory,
    );
    const findSlug =
      matchSiteArray.filter((site: any) => site.node.parent.name.split('.').pop() === targetLang)[0]
        ?.node.frontmatter.slug ||
      matchSiteArray.filter((site: any) => site.node.parent.name.split('.').pop() === activeLang)[0]
        ?.node.frontmatter.slug;

    if (findSlug?.length) {
      const findSite = allSitePage.edges.filter(
        (site: any) =>
          site.node.pageContext.slug === findSlug && site.node.pageContext.locale === targetLang,
      )[0];
      if (findSite?.node.path) {
        if (findSite.node.path === pathname && activeLang !== targetLang) {
          showInfo();
          return;
        }
        navigate(findSite.node.path);
      } else {
        showInfo();
      }
    }
  };

  return (
    <NoSsr>
      <StyledRoot classes={{ root: clsx(classNameProp) }} sx={{ textAlign }}>
        {Object.values(allLang).map((lng, index) => {
          const active = lng.value === currentLang.value;
          return (
            <Button
              key={index}
              color={active ? 'primary' : 'inherit'}
              size="small"
              variant="text"
              onClick={(e: any) => {
                changeLink(e, lng.value);
              }}
              classes={{
                root: clsx(classes.button, active && classes.buttonActive),
              }}
            >
              {lng.label}
            </Button>
          );
        })}
      </StyledRoot>
    </NoSsr>
  );
};

LangSwitcher.defaultProps = {
  textAlign: 'left',
} as Partial<LangSwitcherProps>;

export default LangSwitcher;
