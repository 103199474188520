import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, customShadows, palette, shape, spacing, transitions, typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        '& .nf-form-wrapper': {
          '& *': {
            fontFamily: typography.fontFamily,
            color: palette.text.primary,
          },
          '& .nf-inputs-wrapper, & .nf-submit-button-container, & .nf-recaptcha-container': {
            padding: 0,
            maxWidth: 'none',
          },
          '& .nf-recaptcha-container': {
            margin: spacing(0, 0, 3, 0),
          },
          '& .nf-recaptcha-error': {
            color: palette.error.main,
          },
          '& .nf-input-container': {
            marginBottom: `${spacing(1.5)} !important`,
            '& label': {
              fontSize: typography.caption.fontSize,
              paddingLeft: spacing(2.5),
              paddingRight: spacing(2.5),
              fontWeight: typography.fontWeightRegular,
              textAlign: 'left',
            },
            '& .nf-input, & .nf-textarea, & .nf-select-field-input': {
              marginTop: 0,
              marginBottom: 0,
              borderRadius: 24,
              borderColor: palette.grey[300],
              padding: spacing(1.5, 2.5),
              fontSize: typography.body2.fontSize,
              '&:focus-visible': {
                outlineOffset: 0,
                outline: 'none',
                borderColor: `${palette.primary.main} !important`,
                borderWidth: 1,
                boxShadow: `0 0 0 2px ${alpha(
                  palette.primary.main,
                  palette.tonalOffset as number,
                )}`,
              },
              '&:not(:focus-visible):hover': {
                borderColor: `${palette.grey[400]} !important`,
              },
              '&:focus': {},
            },
            '& .nf-input': {
              height: 48,
            },
            '& .nf-textarea': {
              resize: 'none',
            },
            '& .nf-checkbox-container': {
              padding: spacing(1, 0, 0, 0),
              alignItems: 'center',
              '& .nf-checkbox-label': {
                lineHeight: 1.3,
                fontWeight: typography.fontWeightMedium,
                fontSize: '1rem',
                paddingLeft: 16,
                '& spam:not(.nf-input-required)': {
                  color: `${palette.text.primary} !important`,
                },
              },
            },
            '& .nf-select-field-input + div[alt="arrows"]': {
              top: '0 !important',
              bottom: '0 !important',
              right: '10px !important',
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                width: 18,
                '& g': {
                  fill: palette.text.secondary,
                },
              },
            },
            '& .nf-select-field-items-container': {
              boxShadow: customShadows.z12,
              borderRadius: shape.borderRadius,
              paddingTop: spacing(1),
              paddingBottom: spacing(1),
              marginTop: 0,
              '& .nf-select-field-item': {
                lineHeight: 1.75,
                fontWeight: typography.fontWeightRegular,
                fontSize: typography.body1.fontSize,
                padding: spacing(0.75, 2),
                minHeight: spacing(5),
                '&:hover': {
                  opacity: 1,
                  backgroundColor: palette.action.hover,
                },
              },
            },
            '& .nf-checkbox': {
              width: 16,
              height: 16,
              borderRadius: 4,
              borderColor: palette.grey[300],
              '& svg': {
                '& path:first-of-type': {
                  fill: palette.primary.main,
                },
              },
            },
          },
          '& .nf-question': {
            '&:last-child': {
              marginTop: spacing(2),
            },
          },
          '& .nf-submit-button-container': {
            display: 'block',
          },
          '& .nf-submit-button': {
            borderRadius: spacing(3),
            fontSize: typography.button.fontSize,
            fontFamily: typography.button.fontFamily,
            minWidth: 'auto',
            minHeight: '48px !important',
            height: 'auto !important',
            maxHeight: 'none !important',
            padding: '10px 24px',
            textTransform: typography.button.textTransform,
            lineHeight: '1.3',
            fontWeight: typography.fontWeightBold,
            backgroundColor: `${palette.primary.dark} !important`,
            color: `${palette.primary.contrastText} !important`,
            transition: transitions.create(
              ['background', 'border', 'box-shadow', 'color', 'opacity'],
              {
                duration: transitions.duration.short,
              },
            ),
            '&:hover': {
              backgroundColor: `${palette.primary.main} !important`,
              boxShadow: 'none',
            },
            '&:focus, &:active': {
              backgroundColor: `${palette.primary.dark} !important`,
              boxShadow: `0 0 0 2px ${alpha(palette.primary.main, palette.tonalOffset as number)}`,
            },
          },
          '& .nf-input-required': {
            color: palette.error.main,
          },
          '& .nf-input-error': {
            textAlign: 'left',
            padding: spacing(0.25, 2.5),
            fontSize: typography.caption.fontSize,
            color: `${palette.error.main} !important`,
          },
          '& .nf-powered-by-container': {
            backgroundColor: 'transparent !important',
            [breakpoints.up('sm')]: {
              marginTop: spacing(1.5),
            },
          },
        },
        '& .nf-form-container, & .nf-questions-container, & .nf-status-container': {
          backgroundColor: 'transparent !important',
        },
        '& .nf-status-container': {
          '& .nf-status-wrapper': {
            padding: '0 !important',
          },
          '& .nf-status-header': {
            fontSize: typography.h3.fontSize,
            fontFamily: typography.h3.fontFamily,
            color: `${palette.text.primary} !important`,
          },
          '& .nf-status-description': {
            fontSize: typography.body1.fontSize,
            color: `${palette.text.secondary} !important`,
          },
          '& .nf-status-icon-wrapper': {
            '& svg': {
              width: 120,
              height: 120,
            },
          },
        },
        '& .nf-toasts-wrapper': {
          display: 'none !important',
        },
      },
    },
  };
};
