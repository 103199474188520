export default function TouchRipple() {
  return {
    MuiTouchRipple: {
      defaultProps: {},

      styleOverrides: {
        ripple: {
          opacity: 0,
        },
        rippleVisible: {
          opacity: 0.02,
        },
        child: {
          opacity: 1,
        },
        childLeaving: {
          opacity: 0,
        },
        '@keyframes enter': {
          '0%': {
            transform: 'scale(0)',
            opacity: 0.01,
          },
          '100%': {
            transform: 'scale(1)',
            opacity: 0.02,
          },
        },
        '@keyframes exit': {
          '0%': {
            opacity: 0.02,
          },
          '100%': {
            opacity: 0,
          },
        },
      },
    },
  };
}
