import * as React from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import { useRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
// material
import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';
// components
import { Hero } from 'components';
// hooks
import { usePath } from 'hooks';
// types
import { CarouselButtonItemProps } from 'types/carousel';
//
import { stylesRoot } from './styles';

// ----------------------------------------------------------------------

interface ButtonsSlickProps {
  items: CarouselButtonItemProps[];
}

interface ButtonsSlickAllProps extends ButtonsSlickProps {
  className?: string;
}
const PREFIX = 'ButtonsSlick';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

// ----------------------------------------------------------------------

const ButtonsSlick = (props: ButtonsSlickAllProps) => {
  const { className: classNameProp, items } = props;
  const carouselRef = useRef<Slider | null>(null);
  const { findPagePath } = usePath();

  const settings = {
    slidesToShow: 1,
    initialSlide: 3,
    centerMode: true,
    variableWidth: true,
    infinite: false,
    centerPadding: '10px',
    arrow: false,
    dots: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          initialSlide: 1,
          centerMode: false,
          draggable: true,
          infinite: true,
          autoplay: true,
          speed: 1000,
        },
      },
    ],
  };

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      <Slider ref={carouselRef} {...settings}>
        {items &&
          items.map((item: CarouselButtonItemProps, index: number) => (
            <div key={index}>
              {item.link?.length ? (
                <Button component={GatsbyLink} variant="text" to={findPagePath(item.link)}>
                  <Hero
                    heroImg={item?.icon?.src.publicURL}
                    imageName={item?.icon?.src.name}
                    alt={item?.icon?.alt}
                    title={item?.icon?.title}
                  />
                  {item.text}
                </Button>
              ) : (
                <Button variant="outlined">
                  <Hero
                    heroImg={item.icon.src.publicURL}
                    imageName={item.icon.src.name}
                    alt={item.icon.alt}
                    title={item?.icon.title}
                  />
                  {item.text}
                </Button>
              )}
            </div>
          ))}
      </Slider>
    </StyledRoot>
  );
};

export default ButtonsSlick;
