import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, spacing, typography } = theme;
  return {
    '&': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& span': {
        display: 'block',
        marginLeft: spacing(2.5),
        height: '100%',
        paddingTop: 4,
        cursor: 'pointer',
      },

      [`& .${classes.button}`]: {
        minHeight: 24,
        fontSize: typography.htmlFontSize,
        fontWeight: typography.fontWeightRegular,
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
        [breakpoints.down('sm')]: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
        },
      },
      [`& .${classes.buttonActive}`]: {
        fontWeight: typography.fontWeightMedium,
        pointerEvents: 'none',
      },
    },
  };
};
