import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
// components
import { BoxWithHeader, FeaturedBox } from 'components';
// types
import { FeaturedBoxProps } from 'components/FeaturedBox/FeaturedBox';
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
//
import { stylesRoot, stylesItemWrapper, stylesDescription } from './styles';

interface FeaturedBoxListProps {
  className?: string;
  header?: string;
  headerProps?: BoxWithHeaderProps['headerProps'];
  subheader?: string;
  subheaderProps?: BoxWithHeaderProps['subheaderProps'];
  smallScreen?: boolean;
  description?: string;
  items?: Array<FeaturedBoxProps>;
  textAlign?: BoxWithHeaderProps['textAlign'];
  invertHeader?: BoxWithHeaderProps['invertHeader'];
}

const PREFIX = 'FeaturedBoxList';

const StyledRoot = styled(BoxWithHeader, { name: PREFIX, slot: 'root' })(() => stylesRoot());
const StyledItemWrapper = styled(Grid, { name: PREFIX, slot: 'itemWrapper' })(() =>
  stylesItemWrapper(),
);
const StyledDescription = styled(Typography, { name: PREFIX, slot: 'description' })(({ theme }) =>
  stylesDescription(theme),
);

const FeaturedBoxList = (props: FeaturedBoxListProps) => {
  const {
    className: classNameProp,
    items,
    description,
    header,
    headerProps,
    subheader,
    subheaderProps,
    smallScreen,
    textAlign,
    invertHeader,
  } = props;

  const renderItem = (item: FeaturedBoxProps, val: number) => (
    <StyledItemWrapper key={val} item xs={12} sm={6} md={4}>
      <FeaturedBox title={item?.title} description={item?.description} image={item?.image} />
    </StyledItemWrapper>
  );

  const itemSpacing = smallScreen ? 3 : 5;

  return (
    <StyledRoot
      invertHeader={invertHeader}
      header={header}
      headerProps={headerProps}
      subheader={subheader}
      subheaderProps={subheaderProps}
      textAlign={textAlign}
      className={clsx(classNameProp)}
    >
      {items && (
        <Grid container spacing={itemSpacing} justifyContent="center">
          {items.map((item, val) => renderItem(item, val))}
        </Grid>
      )}

      {description && (
        <StyledDescription color="textSecondary" align={textAlign} sx={{ marginTop: itemSpacing }}>
          {description}
        </StyledDescription>
      )}
    </StyledRoot>
  );
};

FeaturedBoxList.defaultProps = {
  textAlign: 'center',
  invertHeader: false,
} as Partial<FeaturedBoxListProps>;

export default FeaturedBoxList;
