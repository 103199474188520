import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        display: 'inline-block',
        color: 'transparent',
        position: 'relative',
        fontWeight: typography.fontWeightBold,
        '& svg': {
          position: 'absolute',
          pointerEvents: 'none',
          bottom: -3,
          left: 0,
          right: 0,
          margin: 'auto',
          width: `100%`,
          height: 5.5,
        },
      },
    },
  };
};
