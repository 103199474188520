import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, spacing } = theme;
  return {
    '&': {
      maxWidth: '100%',
      [`& .${classes.drawerPaper}`]: {
        top: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        maxWidth: breakpoints.values.sm,
        overflow: 'hidden',
      },
      [`& .${classes.closeIcon}`]: {
        borderRadius: spacing(1.5),
        color: palette.text.primary,
        padding: 10,
        fontSize: 14,
        backgroundColor: palette.action.hover,
        '&:hover': {
          backgroundColor: alpha(palette.primary.main, (palette.tonalOffset as number) * 0.75),
          color: palette.primary.main,
          '@media (hover: none)': {
            backgroundColor: palette.action.hover,
            color: palette.text.primary,
          },
        },
      },
      [`& .${classes.box}`]: {
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        [breakpoints.up('sm')]: {
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
        },
      },
      [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        overflowY: 'auto',
      },
      [`& .${classes.normalItemsList}`]: {
        marginLeft: spacing(-2),
        marginRight: spacing(-2),
        width: `calc(100% + ${spacing(4)})`,
        [breakpoints.up('sm')]: {
          marginLeft: spacing(-3),
          marginRight: spacing(-3),
          width: `calc(100% + ${spacing(6)})`,
        },
      },
      [`& .${classes.featuredItemsList}`]: {
        margin: spacing(1, 0),
        '& > *': {
          margin: spacing(1, 0),
        },
        [breakpoints.up('sm')]: {
          margin: spacing(2, 0),
          '& > *': {
            margin: spacing(1.25, 0),
          },
        },
      },
      [`& .${classes.bottom}`]: {
        textAlign: 'center',

        '& > div': {
          justifyContent: 'center !important',
        },
      },
    },
  };
};

export const stylesDesktopMenuItem = (theme: Theme, classes: any) => {
  const { palette, spacing, typography } = theme;
  return {
    '&': {
      textTransform: 'uppercase',
      fontFamily: typography.fontFamily,
      marginRight: spacing(-1),
      [`& .${classes.mobileButtonEndIcon}`]: {
        fontSize: 30,
        marginLeft: -4,
        color: palette.text.primary,
      },
    },
  };
};
