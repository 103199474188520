import { Theme } from '@mui/material/styles';

export default function Rating(theme: Theme) {
  return {
    MuiRating: {
      defaultProps: {},

      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.48,
          },
        },
        iconEmpty: { color: theme.palette.grey[500_48] },
      },
    },
  };
}
