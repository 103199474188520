import React, { useState } from 'react';
import clsx from 'clsx';
// gatsby
import { Link } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
import { Container, Drawer, IconButton, List, Toolbar, Grid } from '@mui/material';
import { MenuRounded as MenuIcon } from '@mui/icons-material';
// components
import {
  HeaderMobileMenuItem,
  LangSwitcher,
  Logo,
  HeaderDesktopMenuItem,
  StayInContact,
} from 'components';
import { Close as CloseIcon } from 'components/CustomIcon';
// types
import { ExtendedMainMenuItem } from 'types/main-menu-item';
// hooks
import useLocales from 'hooks/useLocales';
import { useLocation } from '@reach/router';
//
import { stylesRoot, stylesDesktopMenuItem } from './styles';

interface HeaderMobileMenuProps {
  className?: string;
  items: ExtendedMainMenuItem[];
  additionalItems?: ExtendedMainMenuItem[];
}

const PREFIX = 'HeaderDesktopMenuItem';
const classes = {
  drawerPaper: `${PREFIX}-drawerPaper`,
  closeIcon: `${PREFIX}-closeIcon`,
  box: `${PREFIX}-box`,
  content: `${PREFIX}-content`,
  normalItemsList: `${PREFIX}-normalItemsList`,
  featuredItemsList: `${PREFIX}-featuredItemsList`,
  bottom: `${PREFIX}-bottom`,
};

const classesDesktopMenuItem = {
  mobileButtonEndIcon: `${PREFIX}-mobileButtonEndIcon`,
};

const StyledRoot = styled(Drawer, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);
const StyledDesktopMenuItem = styled(HeaderDesktopMenuItem, {
  name: PREFIX,
  slot: 'desktopMenuItem',
})(({ theme }) => stylesDesktopMenuItem(theme, classesDesktopMenuItem));

const HeaderMobileMenu = (props: HeaderMobileMenuProps) => {
  const { className: classNameProp, items, additionalItems } = props;
  const { pathname } = useLocation();
  const { defaultLang, pathLang } = useLocales();

  const [opened, setOpened] = useState(false);

  const handleHeaderUserDrawerToggle = (event: { preventDefault: () => void }) => {
    setOpened(!opened);
    if (event) event.preventDefault();
  };

  return (
    <>
      <StyledDesktopMenuItem
        isLink={false}
        endIcon={<MenuIcon className={classesDesktopMenuItem.mobileButtonEndIcon} />}
        label="Menu"
        onClick={handleHeaderUserDrawerToggle}
      />

      <StyledRoot
        anchor="right"
        open={opened}
        variant="temporary"
        classes={{
          root: clsx(classNameProp),
          paper: clsx(classes.drawerPaper),
        }}
        onClose={handleHeaderUserDrawerToggle}
      >
        <Toolbar disableGutters>
          <Container>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <Link
                  to={
                    pathname === `/`
                      ? `/`
                      : `/${defaultLang?.value === pathLang ? '' : `${pathLang}/`}`
                  }
                >
                  <Logo />
                </Link>
              </Grid>
              <Grid item xs textAlign="right">
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleHeaderUserDrawerToggle}
                  classes={{ root: classes.closeIcon }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>

        <Container classes={{ root: clsx(classes.box, classes.content) }}>
          <List disablePadding classes={{ root: classes.normalItemsList }}>
            {items.map((item: ExtendedMainMenuItem, index) => (
              <HeaderMobileMenuItem
                key={index}
                to={item.to || undefined}
                onClick={item.onClick}
                isLink={item.isLink}
                active={item.active}
                label={item.label}
                variant="text"
              />
            ))}
          </List>

          {additionalItems && (
            <div className={classes.featuredItemsList}>
              {additionalItems.map((item: ExtendedMainMenuItem, index) => (
                <HeaderMobileMenuItem
                  key={index}
                  to={item.to || undefined}
                  onClick={item.onClick}
                  isLink={item.isLink}
                  active={item.active}
                  label={item.label}
                  variant={item.variant}
                />
              ))}
            </div>
          )}

          <StayInContact textAlign="center" />
        </Container>

        <Container classes={{ root: clsx(classes.box, classes.bottom) }}>
          <LangSwitcher textAlign="center" />
        </Container>
      </StyledRoot>
    </>
  );
};

export default HeaderMobileMenu;
