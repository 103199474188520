import { useStaticQuery, graphql } from 'gatsby';
import slugify from 'slugify';
// hooks
import useLocales from 'hooks/useLocales';

const usePath = () => {
  const { pathLang, defaultLang } = useLocales();
  const { allMarkdown, allSitePage } = useStaticQuery(graphql`
    query {
      allMarkdown: allMdx(
        filter: { slug: { ne: "root" }, frontmatter: { published: { in: true } } }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              published
              navigation
              emptySlug
              footerEmail
            }
            fileAbsolutePath
            slug
            parent {
              id
              ... on File {
                name
                relativeDirectory
              }
            }
          }
        }
      }
      allSitePage {
        edges {
          node {
            path
            pageContext
          }
        }
      }
    }
  `);

  const handleGetPage = (slug: string, title: string, lang?: string) => {
    const pageSlug =
      slug !== null
        ? slug
        : slugify(title, {
            replacement: '-',
            lower: true,
          });

    const pages = allSitePage.edges.filter(
      (site: any) =>
        site.node.pageContext.slug === pageSlug &&
        site.node.pageContext.locale === (lang || pathLang),
    );

    if (!pages.length) {
      return '';
    }
    return pages[0];
  };

  const handleFindPagePath = (slug: string, title: string, lang?: string) => {
    const { path } = handleGetPage(slug, title, lang)
      ? handleGetPage(slug, title, lang).node
      : { path: '/' };

    if (path.length && path !== '/') {
      return `${path !== '/' ? path : ''}`;
    }
    return `${path && path !== '/' ? `/${path}` : ''}`;
  };

  const handleFindPagePathWithFolderName = (folderName: string, lang?: string) => {
    if (!handleGetFilemdWithFolderName(folderName, lang)) {
      const language = lang || pathLang;
      if (defaultLang?.value !== language) {
        return `/${language}`;
      }
      return ``;
    }
    const { slug, title } = handleGetFilemdWithFolderName(folderName, lang).node.frontmatter;
    const findPath = handleFindPagePath(slug, title, lang);

    return findPath;
  };

  const handleGetFilemdWithFolderName = (folderName: string, lang?: string) => {
    const pages = allMarkdown.edges.filter(
      (fileMd: any) =>
        fileMd.node.slug.split('/')[fileMd.node.slug.split('/').length - 2].split('.').pop() ===
          folderName.toLowerCase() && fileMd.node.parent.name.split('.')[1] === (lang || pathLang),
    );
    if (!pages.length) {
      return null;
    }

    return pages[0];
  };

  const handleGetPageWithFolderName = (folderName: string, lang?: string) => {
    if (!handleGetFilemdWithFolderName(folderName, lang)) {
      return null;
    }
    const { slug, title } = handleGetFilemdWithFolderName(folderName, lang)?.node.frontmatter;
    const findPage = handleGetPage(slug, title, lang);
    return findPage;
  };

  return {
    findPagePathWithSlug: handleFindPagePath,
    findPagePath: handleFindPagePathWithFolderName,
    findPage: handleGetPageWithFolderName,
    findFileMd: handleGetFilemdWithFolderName,
    allMarkdown,
    allSitePage,
  };
};

export default usePath;
