import React, { lazy, useRef, useState } from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
// components
import { Loadable } from 'components';
// hooks
import { useLocales } from 'hooks';
//
import { stylesRoot } from './styles';

export interface NativeFormsComponentProps {
  form: string;
  formId: string;
  formIdAddon?: string;
  formIdInputSelector?: string;
  onLoad?: VoidFunction;
  onSend?: VoidFunction;
}

interface NativeFormsComponentAllProps extends NativeFormsComponentProps {
  className?: string;
}

const PREFIX = 'NativeFormsComponent';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

// native forms
// @ts-ignore
const NativeForms = Loadable(lazy(() => import('native-forms-react')));

const NativeFormsComponent = (props: NativeFormsComponentAllProps) => {
  const {
    className: classNameProp,
    form,
    formId,
    formIdAddon,
    formIdInputSelector,
    onLoad,
    onSend,
  } = props;
  const contactFormWrapperRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { currentLang } = useLocales();
  const windowGlobal = typeof window !== 'undefined' && window;

  const onLoadHandle = () => {
    handleSendEventGtm('load');
    if (onLoad) {
      onLoad();
    }
  };

  const onSendHandle = () => {
    handleSendEventGtm('send');
    if (onSend) {
      onSend();
    }
  };

  const handleSendEventGtm = (type: string) => {
    if (windowGlobal) {
      if (window.dataLayer) {
        if (type === 'load') {
          window.dataLayer.push({
            event: 'gatsby-load-form',
            data: `${formId}-${currentLang.value}`,
          });
        } else if (type === 'send') {
          window.dataLayer.push({
            event: 'gatsby-send-form',
            data: `${formId}-${currentLang.value}`,
          });
        }
      }
    }
  };

  const setNativeValue = (element: any, value: string) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      if (prototypeValueSetter !== undefined) {
        prototypeValueSetter.call(element, value);
      }
    } else if (valueSetter !== undefined) {
      valueSetter.call(element, value);
    }
  };

  const showForm = () => {
    setTimeout(() => {
      if (!loaded && contactFormWrapperRef?.current !== null && formIdInputSelector) {
        setLoaded(true);

        const formIdElement =
          contactFormWrapperRef.current.querySelector<HTMLInputElement>(formIdInputSelector);
        const formScrollerDiv =
          contactFormWrapperRef.current.querySelector<HTMLDivElement>('#top-scroll');

        if (formScrollerDiv) {
          formScrollerDiv.parentNode!.removeChild(formScrollerDiv);
        }

        if (formIdElement) {
          const e = new Event('input', { bubbles: true });
          setNativeValue(formIdElement, `${formId}${formIdAddon ? `__${formIdAddon}` : ''}`);
          formIdElement.dispatchEvent(e);

          if (formIdElement.parentElement !== null) {
            formIdElement.parentElement.style.display = 'none';
          }

          if (typeof onLoad === 'function') {
            onLoadHandle();
          }
        } else {
          showForm();
        }
      }
    }, 100);
  };

  showForm();

  return (
    <StyledRoot
      className={clsx(classes.root, classNameProp)}
      sx={{ visibility: loaded ? 'visible' : 'hidden' }}
      ref={contactFormWrapperRef}
    >
      {!loaded ? <CircularProgress /> : <NativeForms form={form} onSend={onSendHandle} />}
    </StyledRoot>
  );
};

NativeFormsComponent.defaultProps = {
  formIdInputSelector: '#formId',
} as Partial<NativeFormsComponentAllProps>;

export default NativeFormsComponent;
