import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export const componentName = `IceoBoxWithHeader`;

export interface BoxWithHeaderClasses {
  root: string;
  headingSpace: string;
  miniSpace: string;
  smallSpace: string;
  mediumSpace: string;
  largeSpace: string;
  header: string;
  subheader: string;
  content: string;
}

export type BoxWithHeaderClassKey = keyof BoxWithHeaderClasses;

export function getItemUtilityClass(slot: string): string {
  return generateUtilityClass(componentName, slot);
}

const boxWithHeaderClasses: BoxWithHeaderClasses = generateUtilityClasses(componentName, [
  'root',
  'headingSpace',
  'miniSpace',
  'smallSpace',
  'mediumSpace',
  'largeSpace',
  'header',
  'subheader',
  'content',
]);

export default boxWithHeaderClasses;
