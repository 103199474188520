import React from 'react';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path d="M16.65 5.71997H7.35C4.4 5.71997 2 8.11997 2 11.07V16.65C2 19.6 4.4 22 7.35 22H16.65C19.6 22 22 19.6 22 16.65V11.07C22 8.11997 19.6 5.71997 16.65 5.71997ZM14.5 12.02C14.5 11.47 14.95 11.02 15.5 11.02C16.05 11.02 16.5 11.47 16.5 12.02C16.5 12.57 16.05 13.03 15.5 13.03C14.95 13.03 14.5 12.59 14.5 12.04V12.02ZM10.13 16.07C9.98 16.22 9.79 16.29 9.6 16.29C9.41 16.29 9.22 16.22 9.07 16.07L8.04 15.04L7.05 16.03C6.9 16.18 6.71 16.25 6.52 16.25C6.33 16.25 6.14 16.18 5.99 16.03C5.7 15.74 5.7 15.26 5.99 14.97L6.98 13.98L6.02 13.02C5.73 12.73 5.73 12.25 6.02 11.96C6.31 11.67 6.79 11.67 7.08 11.96L8.04 12.92L9.03 11.93C9.32 11.64 9.8 11.64 10.09 11.93C10.38 12.22 10.38 12.7 10.09 12.99L9.1 13.98L10.13 15.01C10.42 15.3 10.42 15.78 10.13 16.07ZM13.54 15C12.99 15 12.53 14.55 12.53 14C12.53 13.45 12.97 13 13.52 13H13.54C14.09 13 14.54 13.45 14.54 14C14.54 14.55 14.1 15 13.54 15ZM15.5 16.97C14.95 16.97 14.5 16.53 14.5 15.98V15.96C14.5 15.41 14.95 14.96 15.5 14.96C16.05 14.96 16.5 15.41 16.5 15.96C16.5 16.51 16.06 16.97 15.5 16.97ZM17.48 15C16.93 15 16.47 14.55 16.47 14C16.47 13.45 16.91 13 17.46 13H17.48C18.03 13 18.48 13.45 18.48 14C18.48 14.55 18.04 15 17.48 15Z" />
    <path d="M13.64 2.71L13.63 3.65C13.62 4.53 12.89 5.26 12 5.26C11.85 5.26 11.76 5.36 11.76 5.49C11.76 5.62 11.86 5.72 11.99 5.72H10.38C10.37 5.65 10.36 5.57 10.36 5.49C10.36 4.59 11.09 3.86 11.98 3.86C12.13 3.86 12.23 3.76 12.23 3.63L12.24 2.69C12.25 2.31 12.56 2 12.94 2H12.95C13.34 2 13.64 2.32 13.64 2.71Z" />
  </>,
  'Game',
);
