import React, { forwardRef } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Typography,
  TypographyProps,
  unstable_composeClasses as composeClasses,
  useThemeProps,
} from '@mui/material';
// gatsby
import { GatsbyImage } from 'gatsby-plugin-image';
// types & utils
import { SxProps } from '@mui/system';
import { ChildImageSharpType } from 'types/image';
import { AvatarWithTextClasses, getItemUtilityClass, componentName } from './avatarWithTextClasses';
import { rootStyles, contentStyles, textStyles } from './styles';

// ----------------------------------------------------------------------

export interface AvatarWithTextProps {
  /**
   * Class added to main element of component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component.
   *
   * Possible keys: `root` `content` `title` `textGutter` `subtitle`
   */
  classes?: Partial<AvatarWithTextClasses>;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   *
   * See the `sx` MUI's documentation for more details.
   */
  sx?: SxProps<Theme>;
  /**
   * Image of the component.
   */
  image?: ChildImageSharpType;
  /**
   * Heading title of the component.
   */
  title?: string;
  /**
   * Typography props for the `title` component.

   * @default { variant: 'caption', color: 'textPrimary' }
   */
  titleProps?: TypographyProps;
  /**
   * Subtitle of the component.
   */
  subtitle?: string;
  /**
   * Typography props for the `subtitle` component.

   * @default { variant: 'caption', color: 'textSecondary' }
   */
  subtitleProps?: TypographyProps;
}

const useUtilityClasses = (ownerState: AvatarWithTextProps) => {
  const { classes, subtitle } = ownerState;
  const slots = {
    root: ['root'],
    content: ['content'],
    title: ['title', subtitle && `textGutter`],
    subtitle: ['subtitle'],
  };
  return composeClasses(slots, getItemUtilityClass, classes);
};

const StyledRoot = styled(Box, {
  name: componentName,
  slot: 'root',
  overridesResolver: (props, styles) => styles.root,
})(() => rootStyles);

const StyledContent = styled(Box, {
  name: componentName,
  slot: 'content',
  overridesResolver: (props, styles) => styles.content,
})(({ theme }) => contentStyles(theme));

const StyledTitle = styled(Typography, {
  name: componentName,
  slot: 'title',
  overridesResolver: (props, styles) => {
    const { subtitle } = props.ownerState;
    return [styles.title, subtitle && styles.textGutter];
  },
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<AvatarWithTextProps> }) =>
  textStyles(theme, ownerState),
);

const StyledSubtitle = styled(Typography, {
  name: componentName,
  slot: 'subtitle',
  overridesResolver: (props, styles) => styles.subtitle,
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<AvatarWithTextProps> }) =>
  textStyles(theme, ownerState),
);

const AvatarWithText = forwardRef<HTMLDivElement, AvatarWithTextProps>(
  (inProps: AvatarWithTextProps, ref) => {
    const props = useThemeProps({ props: inProps, name: componentName });
    const {
      className: classNameProp,
      sx,
      image,
      title,
      titleProps = {
        variant: 'caption',
        color: 'textPrimary',
      },
      subtitle,
      subtitleProps = {
        variant: 'caption',
        color: 'textSecondary',
      },
      ...other
    } = props;
    const ownerState = { ...props };
    const componentClasses = useUtilityClasses(ownerState);
    const theme = useTheme();

    return (
      <StyledRoot
        ref={ref}
        className={clsx(componentClasses.root, classNameProp)}
        sx={sx}
        {...other}
      >
        <Avatar>
          {image && image.src && (
            <GatsbyImage
              image={image.src.childImageSharp.gatsbyImageData}
              alt={image.alt}
              title={image?.title}
            />
          )}
        </Avatar>

        {(title || subtitle) && (
          <StyledContent className={componentClasses.content}>
            {title && (
              <StyledTitle
                className={componentClasses.title}
                ownerState={ownerState}
                theme={theme}
                {...titleProps}
              >
                {title}
              </StyledTitle>
            )}
            {subtitle && (
              <StyledSubtitle
                className={componentClasses.subtitle}
                ownerState={ownerState}
                theme={theme}
                {...subtitleProps}
              >
                {subtitle}
              </StyledSubtitle>
            )}
          </StyledContent>
        )}
      </StyledRoot>
    );
  },
);

export default AvatarWithText;
