import { Theme } from '@mui/material/styles';
import { LogotypesProps } from './Logotypes';

export const stylesRoot = (
  theme: Theme,
  ownerState: {
    greyScale: LogotypesProps['greyScale'];
  },
  classes: any,
) => {
  const { breakpoints, spacing, transitions } = theme;
  const { greyScale } = ownerState;

  return {
    '&': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [`& .${classes.imagesContainer}`]: {
        flex: 1,
      },
      [`& .${classes.imageListItem}`]: {
        position: 'relative',
        textAlign: 'center',
        ...(greyScale === true && {}),
        ...((greyScale === true || greyScale === 'colorOnHover') && {
          filter: 'grayscale(100%)',
          opacity: 0.35,
        }),
        ...(greyScale === 'colorOnHover' && {
          transition: transitions.create(['filter', 'opacity'], {
            duration: transitions.duration.short,
          }),
          '&:hover': {
            filter: 'grayscale(0%)',
            opacity: 1,
          },
        }),
        '& img': {
          display: 'block',
          width: '100%',
          maxHeight: spacing(6),
          maxWidth: spacing(21),
          margin: 'auto !important',
          [breakpoints.up('sm')]: {
            maxHeight: spacing(8),
          },
        },
        '& .MuiImageListItem-img-uniswap': {
          top: -3,
        },
      },
      [`& .${classes.image}`]: {},
      [`& .${classes.additionalContentAsCol}`]: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        [`& .${classes.additionalContent}`]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
        },
      },
    },
  };
};
