import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export const componentName = `IceoLabel`;

export interface LabelClasses {
  root: string;
  defaultColor: string;
  primaryColor: string;
  secondaryColor: string;
  infoColor: string;
  successColor: string;
  warningColor: string;
  errorColor: string;
  filledVariant: string;
  outlinedVariant: string;
  ghostVariant: string;
}

export type LabelClassKey = keyof LabelClasses;

export function getItemUtilityClass(slot: string): string {
  return generateUtilityClass(componentName, slot);
}

const labelClasses: LabelClasses = generateUtilityClasses(componentName, [
  'root',
  'defaultColor',
  'primaryColor',
  'secondaryColor',
  'infoColor',
  'successColor',
  'warningColor',
  'errorColor',
  'filledVariant',
  'outlinedVariant',
  'ghostVariant',
]);

export default labelClasses;
