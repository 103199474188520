import { Theme } from '@mui/material/styles';

export const stylesRoot = () => ({
  '&': {
    position: 'relative',
  },
});

export const stylesItemWrapper = () => ({
  '&': {
    display: 'flex',
  },
});

export const stylesDescription = (theme: Theme) => {
  const { typography } = theme;
  return {
    '&': {
      fontWeight: typography.fontWeightMedium,
    },
  };
};
