import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme) => {
  const { breakpoints, palette, spacing } = theme;
  return {
    '&': {
      display: 'flex',
      alignItems: 'center',
      '&:after': {
        content: '""',
        display: 'block',
        height: 1,
        backgroundColor: palette.divider,
        flex: 1,
        marginLeft: spacing(4),
        [breakpoints.up('sm')]: {
          marginLeft: spacing(5),
        },
        [breakpoints.up('lg')]: {
          marginLeft: spacing(6),
        },
      },
    },
  };
};
