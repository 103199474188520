import React, { ReactNode } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Box, Container, ContainerProps, TypographyProps } from '@mui/material';
// types
import { SxProps } from '@mui/system';
//
import { stylesRoot, stylesContent } from './styles';

// ----------------------------------------------------------------------

export interface ContentBoxProps {
  classesChildren?: any;
  className?: string;
  children?: ReactNode;
  background?: 'default' | 'paper' | 'primary' | 'secondary' | 'transparent';
  sx?: SxProps<Theme>;
  textAlign?: TypographyProps['align'];
  maxWidth?: ContainerProps['maxWidth'];
  spaceSize?: 'small' | 'medium' | 'large' | 'extraLarge' | 'huge' | 'extraHuge';
  spaceTop?: boolean;
  spaceBottom?: boolean;
  showBefore?: boolean;
  horizontalPadding?: boolean;
}

const PREFIX = 'ContentBox';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
};

const StyledRoot = styled(Box, { name: PREFIX, slot: 'root' })(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      background: ContentBoxProps['background'];
      showBefore: ContentBoxProps['showBefore'];
    };
  }) => stylesRoot(theme, classes, ownerState),
);

const StyledContent = styled(Container, { name: PREFIX, slot: 'content' })(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      spaceSize: ContentBoxProps['spaceSize'];
      spaceTop: ContentBoxProps['spaceTop'];
      spaceBottom: ContentBoxProps['spaceBottom'];
      horizontalPadding: ContentBoxProps['horizontalPadding'];
    };
  }) => stylesContent(theme, classes, ownerState),
);

const ContentBox = (props: ContentBoxProps) => {
  const {
    classesChildren,
    className: classNameProp,
    children,
    background,
    sx,
    textAlign,
    maxWidth,
    spaceSize,
    spaceTop,
    spaceBottom,
    showBefore,
    horizontalPadding,
  } = props;
  const theme = useTheme();

  return (
    <StyledRoot
      theme={theme}
      ownerState={{ background, showBefore }}
      sx={{ textAlign, ...sx }}
      className={clsx(classes.root, classesChildren?.root, classNameProp)}
    >
      <StyledContent
        theme={theme}
        ownerState={{ spaceSize, spaceTop, spaceBottom, horizontalPadding }}
        maxWidth={maxWidth}
        className={clsx(classes.content, classesChildren?.content)}
      >
        {children}
      </StyledContent>
    </StyledRoot>
  );
};

ContentBox.defaultProps = {
  background: 'paper',
  maxWidth: 'lg',
  spaceSize: 'large',
  spaceTop: true,
  spaceBottom: true,
  showBefore: true,
  horizontalPadding: true,
} as Partial<ContentBoxProps>;

export default ContentBox;
