import React, { forwardRef } from 'react';
import clsx from 'clsx';
// gatsby
import { MDXRenderer, MDXRendererProps } from 'gatsby-plugin-mdx';
// material
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Box, useThemeProps, unstable_composeClasses as composeClasses } from '@mui/material';
// utils
import {
  componentName,
  getItemUtilityClass,
  TextMDXRendererClasses,
} from './textMDXRendererClasses';
//
import { rootStyles } from './styles';

// ----------------------------------------------------------------------

export interface TextMDXRendererProps {
  /**
   * Class added to main element of component.
   *
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component.
   *
   * Possible values: `root` `anchors` `noAnchors`
   */
  classes?: Partial<TextMDXRendererClasses>;
  /**
   * Content of the component.
   */
  children: MDXRendererProps['children'];
  /**
   * Anchor inside the content
   *
   * @default false
   */
  anchors?: boolean;
}

const useUtilityClasses = (ownerState: Partial<TextMDXRendererProps>) => {
  const { classes, anchors } = ownerState;

  const slots = {
    root: ['root', anchors ? `anchors` : `noAnchors`],
  };

  return composeClasses(slots, getItemUtilityClass, classes);
};

const StyledRoot = styled(Box, {
  name: componentName,
  slot: 'root',
  overridesResolver: (props, styles) => {
    const { anchors, noAnchors } = props.ownerState;

    return [styles.root, anchors && styles.anchors, noAnchors && styles.noAnchors];
  },
})(({ theme, ownerState }: { theme: Theme; ownerState: Partial<TextMDXRendererProps> }) =>
  rootStyles(theme, ownerState),
);

const TextMDXRenderer = forwardRef<HTMLDivElement, TextMDXRendererProps>((inProps, ref) => {
  const props = useThemeProps({
    props: inProps,
    name: componentName,
  });

  const { className: classNameProp, classes: classesProp, children, anchors = false } = props;

  const theme = useTheme();

  const ownerState: Partial<TextMDXRendererProps> = {
    classes: classesProp,
    anchors,
  };

  const classes = useUtilityClasses(ownerState);

  return (
    <StyledRoot
      ref={ref}
      theme={theme}
      ownerState={ownerState}
      className={clsx(classes.root, classNameProp)}
    >
      <MDXRenderer>{children}</MDXRenderer>
    </StyledRoot>
  );
});

export default TextMDXRenderer;
