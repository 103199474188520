import * as React from 'react';
import { useState } from 'react';
import clsx from 'clsx';
// material
import { styled, Theme } from '@mui/material/styles';
import { Typography, Button, useMediaQuery, Container, Box, Grid } from '@mui/material';
// components
import { Cookies as CookiesIcon } from 'components/CustomIcon';
// hooks
import { useCookies } from 'react-cookie';
import { useLocales } from 'hooks';
//
import { stylesCookiesWrap, stylesIcon, stylesAcceptButton } from './styles';

const PREFIX = 'Cookies';
const classesCokiesWrap = {
  cookiesWrap: `${PREFIX}-cookiesWrap`,
  disabled: `${PREFIX}-disabled`,
};

const StyledGridOverlay = styled(Box)(({ theme }) => stylesCookiesWrap(theme, classesCokiesWrap));
const StyledIcon = styled(CookiesIcon, { name: PREFIX, slot: 'icon' })(({ theme }) =>
  stylesIcon(theme),
);
const StyledAcceptButton = styled(Button, { name: PREFIX, slot: 'acceptButton' })(({ theme }) =>
  stylesAcceptButton(theme),
);

const Cookies = () => {
  const [cookies, setCookie] = useCookies(['cookies-agreed']);
  const [removeStatus, setRemoveStatus] = useState<boolean>(false);
  const { translate: t } = useLocales();
  const smallScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('md'));

  const acceptCookies = () => {
    setRemoveStatus(true);

    setTimeout(() => {
      const $cookieFooterBar = document.querySelector('#footer_cookies');
      $cookieFooterBar?.parentNode?.removeChild($cookieFooterBar);
    }, 600);
    setTimeout(() => {
      setCookie('cookies-agreed', 1, { path: '/', maxAge: 60 * 60 * 24 * 365, sameSite: true });
    }, 800);
  };

  return (
    <div id="footer_cookies">
      {!cookies['cookies-agreed'] && (
        <StyledGridOverlay
          className={clsx(
            classesCokiesWrap.cookiesWrap,
            removeStatus && classesCokiesWrap.disabled,
          )}
        >
          <Container>
            <Grid container spacing={smallScreen ? 2 : 3} alignItems="center">
              <Grid item xs="auto">
                <StyledIcon />
              </Grid>

              <Grid item xs>
                <Typography variant="caption">{t('cookies.text')}</Typography>
              </Grid>

              <Grid item xs={12} sm="auto" sx={{ textAlign: 'center' }}>
                <StyledAcceptButton size="small" onClick={acceptCookies}>
                  {t('cookies.button')}
                </StyledAcceptButton>
              </Grid>
            </Grid>
          </Container>
        </StyledGridOverlay>
      )}
    </div>
  );
};

export default Cookies;
