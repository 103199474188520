import React, { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// material
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
// theme
import ThemeConfig from '../../src/theme';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// components
import { NotistackProvider } from '../../src/components';

interface TopLayoutProps {
  element?: ReactNode;
}

declare global {
  interface Window {
    HubSpotConversations: any;
    dataLayer: any;
    gtag: any;
  }
}

const TopLayout = (props: TopLayoutProps) => {
  const { element } = props;

  const windowGlobal = typeof window !== 'undefined' && window;
  const script = windowGlobal && document.createElement('script');
  if (script && process.env.GATSBY_USERBACK === 'true') {
    script.innerHTML = `window.Userback = window.Userback || {};
    Userback.access_token = '5740|59261|JWRkEDGiSYrcIRmMNaFrrIrW5RzvZXHz7AOOzt1r5F99jMacIi';
    (function(d) {
        var s = d.createElement('script');s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
    })(document);`;
    document.head.appendChild(script);
  }

  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={ThemeConfig()}>
          <CssBaseline />
          <NotistackProvider>{element}</NotistackProvider>
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
};

export default TopLayout;
